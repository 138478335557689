import { React, useState, useRef } from 'react'
import './dropdown.scss'
import { useOutsideAlerter } from '../../hooks/useOutsideAlerter'

const Dropdown = ({ title, children, currentyear, hasColor }) => {
    const [isOpen, setOpen] = useState(false)
    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef, () => setOpen(false))

    const toggleDropdown = () => setOpen(!isOpen)

    return (
        <button
            ref={wrapperRef}
            className="dropdown"
            style={
                hasColor
                    ? { backgroundColor: currentyear ? '#00a8ff' : '#e5e5e5' }
                    : null
            }
        >
            <div
                className="dropdown-header"
                onClick={toggleDropdown}
                style={
                    hasColor
                        ? { color: currentyear ? '#ffffff' : '#2f2f2f' }
                        : null
                }
            >
                {title}
                <div className="dropdown-icon icon-chevron-down" />
            </div>
            <div className={`dropdown-body card ${isOpen && 'open'}`}>
                {children}
            </div>
        </button>
    )
}

export default Dropdown
