import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import PageWrap from '../components/pageWrap'
import Table from '../components/table'
import { Container } from 'react-grid-system'
import { getGroupsDashboard, getAccountsDashboard } from '../network/dashboard'
import { useAuth } from '../hooks/useAuth'
import './Dashboard.scss'

const Dashboard = ({ history }) => {
    const { user, isImpersonated, impersonatedUser } = useAuth()

    useEffect(() => {
        const isAdmin =
            user.role === 'super-admin' ||
            user.role === 'admin' ||
            user.role === 'manager'

        if (
            (isAdmin && isImpersonated && impersonatedUser.role !== 'group') ||
            (!isAdmin && user.role !== 'group')
        )
            history.push('/dashboard/group')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const isAdmin =
            user.role === 'super-admin' ||
            user.role === 'admin' ||
            user.role === 'manager'

        if (isAdmin && !impersonatedUser) {
            history.push('/')
        }
    })

    return (
        <PageWrap title="TABLEAU DE BORD">
            <Container>
                <div className="card full-width no-padding">
                    <Table
                        title="Groupe"
                        hasPagination={false}
                        request={getGroupsDashboard}
                        columns={columnsGroup}
                        size="small"
                        onLineSelected={(e) => history.push(`/dashboard/group`)}
                    />
                    <Table
                        title="Comptes"
                        hasPagination={false}
                        request={getAccountsDashboard}
                        columns={columnsCompte}
                        size="small"
                        onLineSelected={(e) =>
                            history.push(`/dashboard/${e.Codage}`)
                        }
                    />
                </div>
            </Container>
        </PageWrap>
    )
}

const columnsGroup = [
    {
        title: 'Code',
        key: 'Codcpt',
        render: (e) => <p className="code-td">{e.Codcpt}</p>,
        sorter: true,
    },
    {
        title: 'Compte',
        key: 'Raicpt',
        render: (e) => <p className="code-td-2">{e.Raicpt}</p>,
        sorter: true,
    },
    // {
    //     render: (e, history) => <IconRedirection url={`/dashboard/${e.id}`} />,
    //     width: 100,
    // },
]

const columnsCompte = [
    {
        title: 'Code',
        key: 'Codage',
        render: (e) => <p className="code-td">{e.Codage}</p>,
        sorter: true,
    },
    {
        title: 'Compte',
        key: 'Raiage',
        render: (e) => <p className="code-td-2">{e.Raiage}</p>,
        sorter: true,
    },
    // {
    //     key: 'action',
    //     render: (e, history) => <IconRedirection url={`/dashboard/${e.id}`} />,
    //     width: 100,
    // },
]

export default withRouter(Dashboard)
