import image from './download.png'
import './iconDownload.scss'

const IconRedirection = ({ request }) => {
    return (
        <img
            className="icon-redirection"
            src={image}
            alt="redirection"
            onClick={request}
        />
    )
}

export default IconRedirection
