import {
    BarChart as ReBarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend,
    Bar,
    ResponsiveContainer,
    Tooltip,
} from 'recharts'

const BarChart = ({
    data,
    valueKey,
    comparedValueKey,
    legend,
    comparedLegend,
    height = 250,
}) => {
    return (
        <ResponsiveContainer width="100%" height={height}>
            <ReBarChart data={data}>
                <CartesianGrid
                    vertical={false}
                    tick={{
                        fontSize: 10,
                        color: '#2f2f2f',
                    }}
                />
                <XAxis
                    dataKey="name"
                    tick={{
                        fontSize: 10,
                        color: '#2f2f2f',
                    }}
                />
                <YAxis
                    tick={{
                        fontSize: 8,
                        color: '#2f2f2f',
                    }}
                    tickFormatter={(e) =>
                        new Intl.NumberFormat('fr-FR').format(e) + ' €'
                    }
                    width={80}
                />
                <Legend iconSize="10" />
                <Bar
                    isAnimationActive={false}
                    barSize={comparedValueKey ? 20 : 40}
                    name={legend}
                    dataKey={valueKey}
                    fill="#001F5E"
                />
                {comparedValueKey && (
                    <Bar
                        isAnimationActive={false}
                        barSize={20}
                        name={comparedLegend}
                        dataKey={comparedValueKey}
                        fill="#0345CC"
                    />
                )}

                <Tooltip
                    isAnimationActive={false}
                    content={({ active, payload, label }) => {
                        if (!active || !payload || !payload.length) return null

                        console.log('payload', payload[0])
                        let name

                        switch (payload[0].payload.name) {
                            case 'Janv':
                                name = 'Janvier'
                                break
                            case 'Fevr':
                                name = 'Fevrier'
                                break
                            case 'Mars':
                                name = 'Mars'
                                break
                            case 'Avril':
                                name = 'Avril'
                                break
                            case 'Mai':
                                name = 'Mai'
                                break
                            case 'Juin':
                                name = 'Juin'
                                break
                            case 'Juil':
                                name = 'Juillet'
                                break
                            case 'Aout':
                                name = 'Aout'
                                break
                            case 'Sept':
                                name = 'Septembre'
                                break
                            case 'Oct':
                                name = 'Octobre'
                                break
                            case 'Nov':
                                name = 'Novembre'
                                break
                            case 'Dec':
                                name = 'Decembre'
                                break

                            default:
                                break
                        }

                        return (
                            <div
                                style={{
                                    backgroundColor: '#fff',
                                    padding: 8,
                                    border: '1px solid #ccc',
                                    borderRadius: 3,
                                }}
                            >
                                <strong>{name}:</strong>
                                {` ${new Intl.NumberFormat('fr-FR').format(
                                    payload[0].value
                                )} €`}
                                {comparedValueKey &&
                                (!!payload[0].payload.value2 ||
                                    payload[0].payload.value2 === 0)
                                    ? ` / ${new Intl.NumberFormat(
                                          'fr-FR'
                                      ).format(payload[0].payload.value2)} €`
                                    : ''}
                            </div>
                        )
                    }}
                />

                {/* <Tooltip
                    isAnimationActive={false}
                    formatter={(value, nm, props) => {}}
                /> */}
            </ReBarChart>
        </ResponsiveContainer>
    )
}

export default BarChart
