import image from './redirect.png'
import './iconRedirection.scss'

const IconRedirection = ({ onClick }) => {
    return (
        <img
            className="icon-redirection"
            src={image}
            alt="redirection"
            onClick={onClick}
        />
    )
}

export default IconRedirection
