import { React } from 'react'

const DoughnutChart = () => {
    return (
        <div className="chart">
            <div className="panel">
                <div className="panel-wrap">
                    <div className="panel-label">Consommation (factures uniquement)</div>
                </div>
                <div className="panel-line"></div>
            </div>
            <div className="chart-wrap">
                <div className="chart-content"></div>
                <ul className="chart-list">
                    <li className="chart-item">
                        <div
                            className="chart-circle"
                            style={{ backgroundColor: '#001338' }}
                        ></div>
                        <div className="chart-value">Pneus</div>
                    </li>
                    <li className="chart-item">
                        <div
                            className="chart-circle"
                            style={{ backgroundColor: '#8AB1FF' }}
                        ></div>
                        <div className="chart-value">Révision / entretien</div>
                    </li>
                    <li className="chart-item">
                        <div
                            className="chart-circle"
                            style={{ backgroundColor: '#C1D6FF' }}
                        ></div>
                        <div className="chart-value">Freinage</div>
                    </li>
                    <li className="chart-item">
                        <div
                            className="chart-circle"
                            style={{ backgroundColor: '#00339C' }}
                        ></div>
                        <div className="chart-value">Vitrage</div>
                    </li>
                    <li className="chart-item">
                        <div
                            className="chart-circle"
                            style={{ backgroundColor: '#558DFF' }}
                        ></div>
                        <div className="chart-value">Pièces techniques</div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default DoughnutChart
