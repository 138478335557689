import React, { useState, useEffect, useContext, createContext } from 'react'
import { useTimer } from 'react-timer-hook'
import { useQueryClient, QueryCache } from 'react-query'

import { signIn, refresh, impersonate } from '../network/auth'

const authContext = createContext()

export const useAuth = () => {
    return useContext(authContext)
}

export const useProvideAuth = () => {
    const [roles, setRoles] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isImpersonateLoading, setIsImpersonateLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [doRemember, setDoRemember] = useState(false)
    const [user, setUser] = useState(null)
    const [impersonatedUser, setImpersonatedUser] = useState(null)
    const [token, setToken] = useState(null)
    const [impersonatedToken, setImpersonatedToken] = useState(null)
    const [expireAt, setExpireAt] = useState(null)
    const [refreshToken, setRefreshToken] = useState(null)
    const [refreshExpireAt, setRefreshExpireAt] = useState(null)
    const isAuth = !!user
    const queryClient = useQueryClient()

    const handleImpersonate = (userId, params) => {
        setIsImpersonateLoading(true)

        impersonate(userId, params)
            .then((res) => {
                // console.log('kkkk', res)
                localStorage.setItem('impersonatedToken', res.token)
                localStorage.setItem(
                    'impersonatedUser',
                    JSON.stringify({ ...res.user, ...res.role })
                )
                setImpersonatedToken(res.token)
                setImpersonatedUser({ ...res.user, ...res.role })
                setIsImpersonateLoading(false)
                // token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiI2MjIwOGI3ZTZlYmNhNzZmZDY2NDI0ODEiLCJ0eXBlIjoidXNlciIsInBlcm1pc3Npb25zIjoiZ3JvdXAiLCJmcm9tIjoiNWVjNGQ3NjAxNDM3Y2I2YmM0ZDY4MTNiIiwiaWF0IjoxNjUwNjE5NzY0LCJleHAiOjE2NTA4Nzg5NjR9.KhzS_3Y1GHnVx9mZlPeSuZMQErYzsUnBHyrXmYxolbE"
                // user: {
                // Codage: null
                // Codcpt: "0000001978"
                // createdAt: "2022-03-03T09:33:50.880Z"
                // email: "pkientz@col.leitz.org"
                // firstName: "Philippe"
                // id: "62208b7e6ebca76fd6642481"
                // lastName: "KIENTZ"
                // password: "7ee8b29fa73076de593fa35c9623344f"
                // phone: "03.89.21.08.00"
                // role: "group"
                // updatedAt: "2022-03-03T09:33:50.880Z"}
            })
            .catch(() => setIsImpersonateLoading(false))
    }

    const handleUnimpersonate = () => {
        localStorage.removeItem('impersonatedToken')
        localStorage.removeItem('impersonatedUser')
        setImpersonatedToken(null)
        setImpersonatedUser(null)
        queryClient.invalidateQueries()
        const queryCache = new QueryCache()
        queryCache.clear()
    }

    const handleSingIn = (data, remember) => {
        console.log('handleSignin', data, doRemember)
        let MS_PER_MINUTE = 60000

        const user = { ...data.user, ...data.role }
        setUser(user)
        setToken(data.token)
        let expDate = new Date(new Date(data.expireAt) - 10 * MS_PER_MINUTE)
        setExpireAt(expDate)

        setRefreshToken(data.refreshToken)
        let refreshExpDate = new Date(
            new Date(data.refreshExpireAt).valueOf() - 10 * MS_PER_MINUTE
        )
        setRefreshExpireAt(refreshExpDate)
        setIsLoading(false)

        localStorage.setItem('token', data.token)
        localStorage.setItem('expireAt', expDate)
        if (remember) {
            localStorage.setItem('user', JSON.stringify(user))

            localStorage.setItem('refreshToken', data.refreshToken)
            localStorage.setItem('refreshExpireAt', refreshExpDate)
        }
    }

    const onExpire = async () => {
        if (!doRemember) signout()

        if (refreshToken && refreshExpireAt) {
            refresh(refreshToken)
                .then((res) => handleSingIn(res, doRemember))
                .catch((error) => {
                    setIsLoading(false)
                    setIsError(error)

                    localStorage.removeItem('impersonatedToken')
                    localStorage.removeItem('impersonatedUser')
                    localStorage.removeItem('token')
                    localStorage.removeItem('expireAt')
                    localStorage.removeItem('user')
                    localStorage.removeItem('refreshToken')
                    localStorage.removeItem('refreshExpireAt')
                })
        } else {
            signout()
        }
    }

    const { days, seconds, minutes, hours } = useTimer({
        expiryTimestamp: new Date(expireAt),
        autoStart: true,
        onExpire,
    })

    const signin = (email, password, remember = true) => {
        setIsLoading(true)
        setIsError(false)
        setDoRemember(remember)
        signIn(email, password)
            .then((data) => {
                console.log('data', data, data.length === 1)
                if (data.length === 1) {
                    handleSingIn(data[0], remember)
                } else if (data.length > 1) {
                    setRoles(data)
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                // console.log('kkkk', error?.response?.data)
                setIsLoading(false)
                setIsError(error)

                localStorage.removeItem('impersonatedToken')
                localStorage.removeItem('impersonatedUser')
                localStorage.removeItem('token')
                localStorage.removeItem('expireAt')
                localStorage.removeItem('user')
                localStorage.removeItem('refreshToken')
                localStorage.removeItem('refreshExpireAt')
            })
    }

    // const signup = (email, password) => {}

    const signout = () => {
        queryClient.invalidateQueries()
        setUser(null)
        setToken(null)
        setIsError(false)
        setDoRemember(false)

        setImpersonatedToken(null)
        setImpersonatedUser(null)

        localStorage.removeItem('impersonatedToken')
        localStorage.removeItem('impersonatedUser')
        localStorage.removeItem('token')
        localStorage.removeItem('expireAt')
        localStorage.removeItem('user')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('refreshExpireAt')
    }

    // const sendPasswordResetEmail = (email) => {
    //     setIsLoading(true)
    //     resetEmail(email)
    //         .then(() => {})
    //         .catch(() => {
    //             setIsLoading(false)
    //             setIsError(true)
    //         })
    // }
    // const confirmPasswordReset = (code, password) => {}

    useEffect(() => {
        const savedUser = localStorage.getItem('user')
        const savedToken = localStorage.getItem('token')
        const savedExpireAt = localStorage.getItem('expireAt')

        const savedRefreshToken = localStorage.getItem('refreshToken')
        const savedRefreshExpireAt = localStorage.getItem('refreshExpireAt')

        const savedImpersonatedToken = localStorage.getItem('impersonatedToken')
        const savedImpersonatedUser = localStorage.getItem('impersonatedUser')

        if (savedImpersonatedToken && savedImpersonatedUser) {
            setImpersonatedToken(savedImpersonatedToken)
            setImpersonatedUser(JSON.parse(savedImpersonatedUser))
        }

        if (
            savedUser &&
            savedToken &&
            savedExpireAt &&
            new Date() < new Date(savedExpireAt)
        ) {
            setUser(JSON.parse(savedUser))
            setToken(savedToken)
            setExpireAt(new Date(savedExpireAt))

            if (savedRefreshToken && savedRefreshExpireAt) {
                setRefreshToken(savedRefreshToken)
                setRefreshExpireAt(savedRefreshExpireAt)
            }
        } else if (
            savedUser &&
            savedRefreshToken &&
            savedRefreshExpireAt &&
            new Date() < new Date(savedRefreshExpireAt)
        ) {
            setUser(JSON.parse(savedUser))
            onExpire()
        } else {
            setUser(null)
            setToken(null)
            setExpireAt(null)

            localStorage.removeItem('impersonatedToken')
            localStorage.removeItem('impersonatedUser')
            localStorage.removeItem('token')
            localStorage.removeItem('expireAt')
            localStorage.removeItem('user')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('refreshExpireAt')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        activeToken: impersonatedToken || token,
        user,
        token,
        isLoading,
        isImpersonateLoading,
        isError,
        isAuth,
        signIn: signin,
        // signup,
        signOut: signout,

        impersonate: handleImpersonate,
        unimpersonate: handleUnimpersonate,
        isImpersonated:
            user?.role === 'super-admin' ||
            user?.role === 'admin' ||
            user?.role === 'manager'
                ? impersonatedToken && impersonatedUser
                    ? true
                    : false
                : true,
        impersonatedUser: impersonatedUser || user,
        // sendPasswordResetEmail,
        // confirmPasswordReset,
        timeBeforeExpire: `${days * 24 + hours}:${
            minutes < 10 ? '0' : ''
        }${minutes}:${seconds < 10 ? '0' : ''}${seconds}`,
        roles,
        resetRoles: () => setRoles(),
        chooseRole: (index, remember) => {
            if (!!roles && !!roles[index]) {
                handleSingIn(roles[index], remember)
            }
            setRoles()
        },
    }
}

export function ProvideAuth({ children }) {
    const auth = useProvideAuth()
    return <authContext.Provider value={auth}>{children}</authContext.Provider>
}
