import React from 'react'
import PageWrap from '../components/pageWrap'
import Panel from '../components/panel'
import StatsCard from '../components/statsCard'
import Chart from '../components/chart'
import DoughnutChart from '../components/doughnutChart'
import QuickAccess from '../components/quickAccess'
import { Row, Col } from 'react-grid-system'

const AccountDetail = () => {
    return (
        <PageWrap>
            <Row>
                <Col xl={8} xxl={9}>
                    <Panel title="Activité" />
                    <div className="chart">
                        <Chart />
                    </div>
                </Col>
                <Col xl={4} xxl={3}>
                    <StatsCard
                        color="secondary"
                        size="xs"
                        title="Exporter les données"
                    />
                    <StatsCard
                        title="Consommation période comparée (ht)"
                        number="79 654€"
                    />
                </Col>
            </Row>

            <Row>
                <Col xl={8} xxl={9}>
                    <DoughnutChart />
                </Col>
                <Col xl={4} xxl={3}>
                    <QuickAccess />
                </Col>
            </Row>
        </PageWrap>
    )
}

export default AccountDetail
