import get from 'lodash.get'
import { React, useState, useRef } from 'react'
import cn from 'classnames'
import { useQuery } from 'react-query'
import { useOutsideAlerter } from '../../hooks/useOutsideAlerter'
import './select.scss'
import { useAuth } from '../../hooks/useAuth'

const Select = ({
    disabled,
    options,
    option,
    request,
    keyData,
    label,
    onChange,
    error,
    errorMessage,
    value,
    canSearch,
    filter = {},
    sort,
    display,
    allowClear,
}) => {
    const [q, setQ] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const { impersonatedUser, isImpersonated, activeToken, user } = useAuth()
    const [selectedOption, setSelectedOption] = useState(
        options ? options[0] : null
    )

    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef, () => {
        if (labelValue) {
            setQ(labelValue[label])
        } else {
            setIsOpen('')
        }

        setIsOpen(false)
    })

    const data = useQuery(
        [
            request?.name || `select`,
            {
                activeToken,
                // unlimited: true,
                sort: null,
                user: isImpersonated ? impersonatedUser.id : user.id,
                filter: { q: q ? q : undefined, ...filter },
            },
        ],
        request,
        {
            disabled: !request,
        }
    )

    const toggling = () => setIsOpen(!isOpen)

    const onOptionClicked = (value, label) => {
        onChange(value)
        setSelectedOption(label)
        setQ(label)
        setIsOpen(false)
    }

    const labelValue = !!request
        ? get(data, 'data.list', [])?.find((e) => e[keyData] === value)
        : option?.find((e) => e[keyData] === value)

    const types = {
        group: 'Compte',
        agency: 'Agence',
        admin: 'Administrateur',
        'super-admin': 'Administrateur',
        manager: 'Commercial',
    }

    return (
        <div
            ref={wrapperRef}
            className="select"
            style={{ position: 'relative' }}
        >
            {error ? <div className="input-error-icon icon-warning" /> : null}
            {disabled ? (
                <input
                    className={cn('select-input input', {
                        disabled: disabled,
                    })}
                    value={types[value] || value}
                    type="text"
                    disabled={disabled}
                />
            ) : isOpen && canSearch ? (
                <input
                    className={cn('select-input input')}
                    value={q}
                    onChange={(e) => setQ(e.target.value)}
                    type={'text'}
                />
            ) : (
                <button
                    className={cn('select-input input', {
                        error: error,
                    })}
                    type="button"
                    onClick={toggling}
                >
                    {!value
                        ? !selectedOption
                            ? 'Selectionnez'
                            : selectedOption
                        : labelValue
                        ? labelValue[label]
                        : value}
                </button>
            )}

            {allowClear && value && (
                <span
                    onClick={() => {
                        setSelectedOption(null)
                        onChange(undefined)
                    }}
                    style={{
                        position: 'absolute',
                        top: 11,
                        right: 10,
                        cursor: 'pointer',
                    }}
                >
                    x
                </span>
            )}

            {isOpen && (
                <div
                    className="select-list"
                    style={{
                        maxHeight: '24vh',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                >
                    {!!request && data.isLoading && 'Chargements...'}
                    {get(data, 'data.list', [])
                        .sort(sort)
                        .map((e) => (
                            <button
                                key={e[keyData]}
                                type="button"
                                onClick={() =>
                                    onOptionClicked(e[keyData], e[label])
                                }
                            >
                                {display ? display(e) : e[label]}
                            </button>
                        ))}
                    {option &&
                        option.map((opt) => (
                            <button
                                key={opt[keyData]}
                                type="button"
                                onClick={() =>
                                    onOptionClicked(opt[keyData], opt[label])
                                }
                            >
                                {opt[label]}
                            </button>
                        ))}
                    {options &&
                        options.map((opt) => (
                            <button
                                key={opt}
                                type="button"
                                onClick={onOptionClicked(opt)}
                            >
                                {opt}
                            </button>
                        ))}
                </div>
            )}

            {error ? (
                <div className="input-error-message">{errorMessage}</div>
            ) : null}
        </div>
    )
}

export default Select
