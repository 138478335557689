const axios = require('axios')

const SERVER = { baseURL: process.env.REACT_APP_API_URL || 'http://localhost' }
const INSTANCE = axios.create(SERVER)
const INSTANCELIST = axios.create(SERVER)

INSTANCE.interceptors.response.use(async (response) => {
    console.log('Response:', response)

    return response.data
})

INSTANCELIST.interceptors.response.use(async (response) => {
    console.log('Response:', response.data)

    return {
        range: response.headers['content-range']?.split('/')[1],
        list: response.data,
    }
})
export { INSTANCE, INSTANCELIST }
export default INSTANCE
