import { React } from 'react'
import Calendar from '../calendar'
import Dropdown from '../dropdown'
import './panel.scss'

const displayDate = (date) => {
    if (!date) return 'XX/XX/XXXX'

    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    return `${day < 10 ? '0' : ''}${day}/${
        month < 10 ? '0' : ''
    }${month}/${year}`
}

const Panel = ({
    title,
    noCompare,
    dates,
    setDates,
    onModify,
    onCancel,
    dateCompared,
    hasCalendar,
    canNext,
    onPrevYear,
    onNextYear,
    hasMonthSelection,
    onMonthSelected,
    isAdmin,
    currentyear,
}) => {
    return (
        <div
            className="panel"
            style={{
                width: isAdmin ? 'var(--75width)' : 'var(--100width)',
                flexWrap: !noCompare ? 'var(--flexWrap)' : 'nowrap',
            }}
        >
            <div className="panel-wrap">
                <div className="panel-label">{title}</div>
                <input className="panel-input" type="text" />
                <span className="panel-lable-icon icon-pencil" />
            </div>
            <div className="panel-line"></div>

            {hasCalendar ? (
                <Calendar onChange={setDates} defaultDates={dates}>
                    <div className="panel-calendar">
                        {`${displayDate(dates[0])} - ${displayDate(dates[1])}`}
                        {/* {dates} */}
                    </div>
                </Calendar>
            ) : hasMonthSelection ? (
                <Dropdown title={dates} currentyear={currentyear} hasColor>
                    <div
                        className="dropdown-title"
                        onClick={() => onMonthSelected(3)}
                    >
                        {`${
                            hasMonthSelection === 3 ? '•' : ''
                        } 3 derniers mois`}
                    </div>
                    <div
                        className="dropdown-title"
                        onClick={() => onMonthSelected(6)}
                    >
                        {`${
                            hasMonthSelection === 6 ? '•' : ''
                        } 6 derniers mois`}
                    </div>
                    <div
                        className="dropdown-title"
                        onClick={() => onMonthSelected('current')}
                    >
                        {`${
                            hasMonthSelection === 'current' ? '•' : ''
                        } Annee en cours`}
                    </div>
                    <div
                        className="dropdown-title"
                        onClick={() => onMonthSelected(12)}
                    >
                        {`${
                            hasMonthSelection === 12 ? '•' : ''
                        } Annee glissante`}
                    </div>
                </Dropdown>
            ) : (
                <div className="panel-calendar">
                    {/* {`${displayDate(dates[0])} - ${displayDate(dates[1])}`} */}
                    {dates}
                </div>
            )}

            {!noCompare && (
                <Dropdown title="Comparer à" currentyear={currentyear} hasColor>
                    {dateCompared && (
                        <div className="dropdown-wrap">
                            <button
                                onClick={onPrevYear}
                                className="dropdown-button"
                            >
                                Precedent
                            </button>
                            {canNext && (
                                <button
                                    onClick={onNextYear}
                                    className="dropdown-button"
                                >
                                    Suivant
                                </button>
                            )}
                        </div>
                    )}
                    <div className="dropdown-title" onClick={onModify}>
                        {dateCompared ? (
                            <>
                                Période personnalisée
                                <span>{dateCompared}</span>
                            </>
                        ) : (
                            'Année précédente'
                        )}
                    </div>
                    {dateCompared && (
                        <div className="dropdown-wrap">
                            {/* <button onClick={onModify} className="dropdown-button">
                            Modifier
                        </button> */}
                            <button
                                onClick={onCancel}
                                className="dropdown-button"
                            >
                                Annuler
                            </button>
                        </div>
                    )}
                    {/* <div className="dropdown-title dropdown-title--input">
                        Période personnalisée
                        <span>
                            Du <input className="dropdown-input" /> au
                            <input className="dropdown-input" />
                            <button className="dropdown-calendar icon-calendar"></button>
                        </span>
                    </div> */}
                </Dropdown>
            )}
        </div>
    )
}

export default Panel
