import React from 'react'
import cn from 'classnames'
import './button.scss'

export const Button = ({
    children,
    color,
    disabled,
    icon,
    onClick,
    rounded,
    size,
    skewed,
    skewedLg,
    type,
    fullwidth,
    isLoading,
    ...otherProps
}) => {
    return (
        <button
            type={type}
            className={cn(
                'button',
                { [`button-${disabled ? 'disabled' : color}`]: !!color },
                { [`button-${size}`]: !!size },
                { [`button-skewed`]: !!skewed },
                { [`button-skewed-lg`]: !!skewedLg },
                { [`button-rounded`]: !!rounded },
                { [`fullwidth`]: !!fullwidth }
            )}
            disabled={disabled}
            onClick={onClick}
            {...otherProps}
        >
            {icon ? <span className={`icon-${icon}`}></span> : null}
            <span>{children}</span>
            {isLoading && <div className="icon-loading button-loading" />}
        </button>
    )
}

export default Button
