import React, { useState } from 'react'
import PageWrap from '../components/pageWrap'
import Button from '../components/button'
import Input from '../components/input'
import Tabs from '../components/tabs'
import Tab from '../components/tab'
import TabPanel from '../components/tabPanel'
import Table from '../components/table'
import UserInfo from '../components/userInfo'
import { Row, Col } from 'react-grid-system'
import { useAuth } from '../hooks/useAuth'
import { getUserHistory, getAdminHistory } from '../network/home'
import { useFormik } from 'formik'
import { useMutation } from 'react-query'
import { updatePassword } from '../network/auth'
import Modal from '../components/modal'
import { getAgencies } from '../network/dashboard'

const displayDate = (dateStr) => {
    if (!dateStr) return ''

    const date = new Date(dateStr)

    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    const hour = date.getHours()
    const min = date.getMinutes()

    return {
        d: `${day}/${month}/${year}`,
        h: `${hour < 10 ? '0' : ''}${hour}:${min < 10 ? '0' : ''}${min}`,
    }
}

const getType = (type) => {
    switch (type) {
        case 'immat-create':
            return 'Immatriculation(s) crée(s)'
        case 'profile-update':
            return 'Modification du profile '
        case 'immat-import':
            return 'Immatriculation(s) importée(s)'
        case 'user-create':
            return 'Utilisateur crée'
        case 'immat-transfer':
            return 'Immatriculation(s) transferée(s)'
        case 'immat-remove':
            return 'Immatriculation(s) supprimée(s)'
        case 'user-update':
            return 'Utilisateur modifié'

        default:
            return ''
    }
}

const Account = () => {
    const { signOut, user } = useAuth()
    const isAdmin =
        user.role === 'super-admin' ||
        user.role === 'admin' ||
        user.role === 'manager'
    const tempUser = user
    const [isModalOpen, setIsModalOpen] = useState(null)
    const selectedAccounts = null

    const formikPass = useFormik({
        initialValues: {
            password: '',
            confirm: '',
        },
        validate: (values) => {
            const errors = {}

            if (
                !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(
                    values.password
                )
            ) {
                errors.password =
                    'Au minimum 8 caractères alphanumériques avec majuscules et minuscules. Votre mot de passe est strictement personnel et confidentiel.'
            }

            if (values.password !== values.confirm) {
                errors.confirm = 'Les mots de passes ne correspondent pas'
            }

            return errors
        },
        onSubmit: (values) => {
            passMutation.mutate(values.password)
            // if (mode === 'ask') {
            //     askID.mutate()
            // } else if (mode === 'forgot') {
            //     askPass.mutate()
            // } else {
            //     signIn(values.email, values.password, values.remember)
            // }
        },
    })

    const passMutation = useMutation((pwd) => updatePassword(pwd), {
        onSuccess: () => {
            formikPass.resetForm()
            setIsModalOpen('success')
        },
        onError: () => {
            setIsModalOpen('error')
        },
    })

    return (
        <>
            <Modal
                icon={isModalOpen === 'error' ? 'warning' : null}
                isShowing={isModalOpen}
                onClose={() => setIsModalOpen(null)}
                title={
                    isModalOpen === 'error'
                        ? 'Une erreur est survenue en voulant modifier votre mot de passe'
                        : 'Votre mot de passe à bien été modifié'
                }
            >
                <Button
                    onClick={() => setIsModalOpen(null)}
                    color={'danger'}
                    skewed
                    style={{ marginRight: 24 }}
                >
                    Fermer
                </Button>
            </Modal>
            <PageWrap title="Mon Compte">
                <Tabs
                    initialValue="profile"
                    className="account-page tabs-vertical"
                >
                    <Row className="full-height">
                        <Col xl={4} className="profile-card-wrap">
                            <div className="card profile-card no-padding">
                                <div className="profile-card-user-info">
                                    <UserInfo photoUpload vertical />
                                </div>
                                <div className="show-on-tablet">
                                    <div className="tabs-wrap">
                                        <Tab name="profile">Profil</Tab>
                                        <Tab name="history">
                                            historique de modifications
                                        </Tab>
                                    </div>
                                </div>

                                <button
                                    className="profile-card-logout"
                                    onClick={signOut}
                                >
                                    Déconnexion
                                </button>
                            </div>
                        </Col>
                        <Col xl={8}>
                            <div className="hide-on-tablet">
                                <div className="profile-card-tabs tabs-wrap">
                                    <Tab name="profile">Profil</Tab>
                                    <Tab name="history">
                                        historique de modifications
                                    </Tab>
                                </div>
                            </div>
                            <TabPanel name="profile">
                                <div className="card profile-card-content">
                                    <div className="profile-card-content-form">
                                        <h2>Utilisateur</h2>
                                        <form
                                            onSubmit={formikPass.handleSubmit}
                                        >
                                            <Row gutterWidth={40}>
                                                <Col xl={6}>
                                                    <Input
                                                        type="text"
                                                        // placeholder="Emilie"
                                                        value={
                                                            tempUser?.firstName
                                                        }
                                                        label="Prénom"
                                                        disabled
                                                        fullwidth="true"
                                                    />
                                                </Col>
                                                <Col xl={6}>
                                                    <Input
                                                        type="text"
                                                        // placeholder="Unuit"
                                                        value={
                                                            tempUser?.lastName
                                                        }
                                                        label="Nom"
                                                        disabled
                                                        fullwidth="true"
                                                    />
                                                </Col>
                                                <Col xl={6}>
                                                    <Input
                                                        type="email"
                                                        // placeholder="example@example.com"
                                                        value={tempUser?.email}
                                                        label="E-mail"
                                                        disabled
                                                        fullwidth="true"
                                                    />
                                                </Col>
                                                <Col xl={6}>
                                                    <Input
                                                        type="tel"
                                                        // placeholder="XXXXXXXXXX"
                                                        value={tempUser?.phone}
                                                        label="Téléphone"
                                                        disabled
                                                        fullwidth="true"
                                                    />
                                                </Col>
                                                {!isAdmin && (
                                                    <Col xl={6}>
                                                        <Input
                                                            type="text"
                                                            // placeholder="XXXXXXXXXX"
                                                            value={
                                                                tempUser?.Codage ||
                                                                tempUser?.Codcpt
                                                            }
                                                            label="Compte groupe"
                                                            disabled
                                                            fullwidth="true"
                                                        />
                                                    </Col>
                                                )}
                                            </Row>

                                            <div className="divider divider-middle" />

                                            <h2>Mot de passe</h2>
                                            <p>
                                                Au minimum 8 caractères
                                                alphanumériques avec majuscules
                                                et minuscules. Votre mot de
                                                passe est strictement personnel
                                                et confidentiel.
                                            </p>

                                            <Input
                                                type="password"
                                                placeholder="Saisissez votre nouveau mot de passe"
                                                value={
                                                    formikPass.values.password
                                                }
                                                name="password"
                                                onChange={
                                                    formikPass.handleChange
                                                }
                                                icon="lock"
                                                error={
                                                    !!formikPass.errors.password
                                                }
                                                errorMessage={
                                                    formikPass.errors.password
                                                }
                                                errorLong="true"
                                                fullwidth="true"
                                                password="true"
                                            />
                                            <Input
                                                type="password"
                                                placeholder="Resaisissez votre nouveau mot de passe"
                                                value={
                                                    formikPass.values.confirm
                                                }
                                                name="confirm"
                                                onChange={
                                                    formikPass.handleChange
                                                }
                                                error={
                                                    !!formikPass.errors.confirm
                                                }
                                                errorMessage={
                                                    formikPass.errors.confirm
                                                }
                                                icon="lock"
                                                fullwidth="true"
                                                password="true"
                                            />
                                            <Button
                                                color="primary"
                                                type="submit"
                                                disabled={
                                                    !!Object.keys(
                                                        formikPass.errors
                                                    ).length
                                                }
                                            >
                                                changer mon mot de passe
                                            </Button>
                                            <div className="divider divider-top" />
                                        </form>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel name="history" className="full-height">
                                <div className="card profile-card-content no-padding-horizontal no-padding-bottom">
                                    <Table
                                        filter={
                                            isAdmin
                                                ? {
                                                      key: 'q',
                                                      placeholder:
                                                          "Rechercher par nom d'utilisateurs",
                                                  }
                                                : null
                                        }
                                        pageSize={15}
                                        request={
                                            isAdmin
                                                ? getAdminHistory
                                                : getUserHistory
                                        }
                                        columns={
                                            isAdmin ? columnsAdmin : columns
                                        }
                                        defaultFilters={
                                            selectedAccounts
                                                ? {
                                                      CODECLI: {
                                                          $in: [
                                                              selectedAccounts,
                                                          ],
                                                      },
                                                  }
                                                : null
                                        }
                                        // onLineSelected={handleLineSelected}
                                        size="small"
                                    />
                                </div>
                            </TabPanel>
                        </Col>
                    </Row>
                </Tabs>
            </PageWrap>
        </>
    )
}

const columnsAdmin = [
    // {
    //     title: 'Date',
    //     key: 'date',
    //     render: (e) => displayDate(e.date).d,
    //     sorter: true,
    // },

    {
        title: 'Date',
        key: 'date',
        render: (e) => <nobr>{displayDate(e.date).d}</nobr>,
        // sorter: true,
        sorter: false,
        date: 'date',
    },
    {
        title: 'Heure',
        key: 'date',
        sorter: false,
        render: (e) => <nobr>{displayDate(e.date).h}</nobr>,
    },
    {
        title: 'Client',
        key: '',
        render: (e) => `${e?.user?.firstName} ${e?.user?.lastName}`,
    },
    // {
    //     title: 'Compte',
    //     key: 'accounts',
    //     render: (e) => e?.user?.Codage || e?.user?.Codcpt,
    //     // sorter: true,
    //     dropdown: {
    //         title: 'Compte',
    //         request: getAgencies,
    //         labelKey: 'Raiage',
    //         valueKey: 'Codage',
    //         noFilter: true,
    //     },
    // },
    {
        title: 'Compte',
        key: 'user',
        render: (e) => e?.user?.Codage || e?.user?.Codcpt,
        // sorter: true,
        dropdown: {
            title: 'Compte',
            request: getAgencies,
            labelKey: 'Raiage',
            valueKey: 'Codage',
        },
    },
    {
        title: 'Action',
        key: 'action',
        render: (e) => getType(e.action),
        sorter: true,
        dropdown: {
            title: 'Action',
            options: [
                {
                    action: 'immat-create',
                    label: 'Immatriculation(s) crée(s)',
                },
                {
                    action: 'profile-update',
                    label: 'Modification du profile',
                },
                {
                    action: 'immat-import',
                    label: 'Immatriculation(s) importée(s)',
                },
                { action: 'user-create', label: 'Utilisateur crée' },
                {
                    action: 'immat-transfer',
                    label: 'Immatriculation(s) transferée(s)',
                },
                {
                    action: 'immat-remove',
                    label: 'Immatriculation(s) supprimée(s)',
                },
                {
                    action: 'user-update',
                    label: 'Utilisateur modifié',
                },
            ],
            labelKey: 'label',
            valueKey: 'action',
        },
    },
]

const columns = [
    // {
    //     title: 'Date',
    //     key: 'date',
    //     render: (e) => displayDate(e.date).d,
    //     sorter: true,
    // },

    {
        title: 'Date',
        key: 'date',
        render: (e) => displayDate(e.date).d,
        // sorter: true,
        date: 'dates',
        sorter: false,
    },
    {
        title: 'Heure',
        key: 'hour',
        render: (e) => displayDate(e.date).h,
    },
    {
        title: 'Action',
        key: 'action',
        render: (e) => getType(e.action),
        sorter: true,
        dropdown: {
            title: 'Action',
            options: [
                {
                    action: 'immat-create',
                    label: 'Immatriculation(s) crée(s)',
                },
                {
                    action: 'profile-update',
                    label: 'Modification du profile',
                },
                {
                    action: 'immat-import',
                    label: 'Immatriculation(s) importée(s)',
                },
                { action: 'user-create', label: 'Utilisateur crée' },
                {
                    action: 'immat-transfer',
                    label: 'Immatriculation(s) transferée(s)',
                },
                {
                    action: 'immat-remove',
                    label: 'Immatriculation(s) supprimée(s)',
                },
                {
                    action: 'user-update',
                    label: 'Utilisateur modifié',
                },
            ],
            labelKey: 'label',
            valueKey: 'action',
        },
    },
]

export default Account
