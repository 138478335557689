import { React, useContext } from 'react'
import { TabContext } from '../tabs'
import './tab.scss'
import Arrow from '../../assets/icons/LeftArrow'

const Tab = (props) => {
    const {
        name,
        className = '',
        onClick = () => {},
        children,
        closable,
        onClose,
        ...restProps
    } = props

    const tabContext = useContext(TabContext)

    const classNames = `
    tab
    ${tabContext.activeTab === name ? 'active' : ''}
    ${className}
  `

    const handleClick = (event) => {
        tabContext.changeTab(name)
        onClick(event)
    }

    return (
        <button className={classNames} onClick={handleClick} {...restProps}>
            {closable && tabContext.activeTab === name && (
                <button onClick={onClose}>
                    <Arrow />
                </button>
            )}
            {children}
        </button>
    )
}

export default Tab
