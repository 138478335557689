import React, { useState, useRef } from 'react'
import cn from 'classnames'
import { useMutation, useQueryClient } from 'react-query'
import { useModal } from '../../hooks/useModal'
import Modal from '../modal'
import Button from '../button'
import '../button/button.scss'
import './button.scss'
import moment from 'moment'
import { testCanImport } from '../../network/parkManagement'
import 'moment/locale/fr'

export const ButtonInputFile = ({
    children,
    color,
    disabled,
    name,
    icon = true,
    fileSize = 100,
    onSuccess,
    rounded,
    size,
    skewed,
    skewedLg,
    type,
    fullwidth,
    isLoading,
    request,
    onBeforeRequest,
    ...otherProps
}) => {
    const inputFile = useRef(null)
    const { isShowing, toggleModal } = useModal()
    const queryClient = useQueryClient()
    const [errors, setErrors] = useState()
    const [showSuccess, setShowSuccess] = useState()
    const onUploadFile = useMutation((values) => request(values), {
        onSuccess: (data) => {
            toggleModal(false)
            if (data.available === false) {
                setShowSuccess(data)
            } else {
                setShowSuccess(true)
            }

            queryClient.invalidateQueries(name)
        },
        onError: (error) => {
            setErrors(
                error?.response?.data?.message?.errors || [
                    'Ce fichier contient des erreurs',
                ]
            )
            toggleModal()
        },
    })

    const canImport = useMutation(() => testCanImport(), {
        onSuccess: (data) => {
            if (data.available === true) {
                inputFile.current?.click()
            } else {
                toggleModal(false)
                setShowSuccess(data)
            }
        },
    })

    const handleFiles = (event) => {
        const file = event.target.files[0]
        if (!file) return
        // if (file.size >= fileSize * 1024 * 1024) {
        //     return
        // }
        const formData = new FormData()
        formData.append('file', file)

        onUploadFile.mutate(formData)
    }

    return (
        <>
            <input
                ref={inputFile}
                style={{ display: 'none' }}
                type="file"
                id="inputFileContact"
                onChange={handleFiles}
                accept=".xls,.xlsx"
                onClick={(event) => {
                    event.target.value = null
                }}
            />

            <button
                type={type}
                className={cn(
                    'button button-input',
                    { [`button-${color}`]: !!color },
                    { [`button-${size}`]: !!size },
                    { [`button-skewed`]: !!skewed },
                    { [`button-skewed-lg`]: !!skewedLg },
                    { [`button-rounded`]: !!rounded },
                    { [`fullwidth`]: !!fullwidth }
                )}
                disabled={disabled}
                onClick={() => {
                    canImport.mutate()
                }}
                {...otherProps}
            >
                {icon ? <span className="icon-import"></span> : null}
                <span>{children}</span>
                {isLoading || onUploadFile.isLoading || canImport.isLoading ? (
                    <div className="icon-loading button-loading" />
                ) : null}
            </button>

            <Modal
                isShowing={isShowing}
                onClose={() => {
                    toggleModal()
                    setShowSuccess()
                    setErrors()
                }}
                icon="info-circle"
            >
                {showSuccess === true ? (
                    <p>
                        <b>
                            Votre parc a bien été importé et est en cours
                            d'intégration dans nos centres. Vous pouvez fermer
                            cette fenêtre.
                        </b>
                    </p>
                ) : !!showSuccess ? (
                    <CurrentImport importation={showSuccess} />
                ) : null}

                {!showSuccess && errors && (
                    <>
                        <p>
                            <b className="color-error">
                                Une erreur est survenue lors de l’import de vos
                                immatriculations
                            </b>
                        </p>
                        <p>
                            <b>Rapport d’import :</b>
                        </p>
                        <ul className="error-list">
                            {errors?.map((e, i) => (
                                <li key={e}>{e}</li>
                            ))}
                        </ul>
                        <br />
                        <br />
                        {/* <Button color="danger" size="large" skewed>
                    importer un nouveau fichier
                </Button> */}
                        <button
                            className={cn(
                                'button button-input',
                                { [`button-danger`]: true },
                                { [`button-large`]: true },
                                { [`button-skewed`]: true }
                            )}
                        >
                            <input
                                type="file"
                                id="inputFileContact"
                                onChange={(e) => {
                                    toggleModal()
                                    handleFiles(e)
                                }}
                                accept=".xls,.xlsx"
                                onClick={(event) => {
                                    event.target.value = null
                                }}
                            />
                            <span>importer un nouveau fichier</span>
                        </button>
                    </>
                )}
                <Button
                    color="primary"
                    size="large"
                    skewed
                    onClick={() => {
                        toggleModal()
                        setErrors()
                        setShowSuccess()
                    }}
                >
                    Fermer
                </Button>
            </Modal>
        </>
    )
}

export default ButtonInputFile

const CurrentImport = ({ importation }) => {
    moment.locale('fr')
    let m = moment(importation.currentImport.createdAt)

    const detail = importation.currentImport?.detail

    const phaseOneStarted =
        !!detail.totalLinesToUpdate && !!detail.totalLinesUpdated

    const phaseOnePercent =
        ((detail.totalLinesUpdated || 0) * 100) /
        (detail.totalLinesToUpdate || 0)

    const phaseTwoStarted =
        !!detail.totalLinesToDelete && !!detail.totalLinesDeleted

    const phaseTwoPercent =
        ((detail.totalLinesDeleted || 0) * 100) /
        (detail.totalLinesToDelete || 0)

    return (
        <>
            <p>
                <b className="color-error">
                    Un fichier est déjà en cours d'import. Veuillez patienter et
                    recommencer plus tard.
                </b>
            </p>

            <p>
                <b>Import : </b>
                {m.fromNow()}
            </p>

            <p>
                <b>Première phase : </b>
                {!phaseOneStarted
                    ? 'Pas encore commencée'
                    : phaseOnePercent >= 100
                    ? 'Terminée'
                    : `En cours (${phaseOnePercent.toFixed(0)}%)`}
            </p>

            <p>
                <b>Seconde phase : </b>
                {!phaseTwoStarted
                    ? 'Pas encore commencée'
                    : phaseTwoPercent >= 100
                    ? 'Terminée'
                    : `En cours (${phaseTwoPercent.toFixed(0)}%)`}
            </p>
        </>
    )
}
