import React, { useContext, createContext, useState } from 'react'

const dataContext = createContext()

export const useData = () => {
    return useContext(dataContext)
}

export const useProvideData = () => {
    const [menuOpen, setMenuOpen] = useState(false)

    const openMenu = () => {
        setMenuOpen(true)
    }

    const closeMenu = () => {
        setMenuOpen(false)
    }

    return {
        isMenuOpen: menuOpen,
        openMenu,
        closeMenu,
    }
}

export function ProvideData({ children }) {
    const data = useProvideData()

    return <dataContext.Provider value={data}>{children}</dataContext.Provider>
}
