import { useState, useEffect } from 'react'
import PageWrap from '../components/pageWrap'
import PieChart from '../components/pieChart'
import Panel from '../components/panel'
import QuickAccess from '../components/quickAccess'
import StatsCard from '../components/statsCard'
import BarChart from '../components/barChart'
import { Row, Col } from 'react-grid-system'
import { withRouter, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import get from 'lodash.get'
import useStats from '../hooks/useStats'
import { useAuth } from '../hooks/useAuth'

import { exportData } from '../network/home'
import { getGroupsDashboard, getAccountsDashboard } from '../network/dashboard'

import './Dashboard.scss'

// <div className="chart">
//     <div className="card full-width">
//         <PieChart
//             outerData={data}
//             legendBottom
//             height={330}
//         />
//         {/* <PieChart outerData={data} innerData={data} /> */}
//         {/* <BarChart
//             data={data}
//             valueKey="value"
//             legend="Du 01/01/20 au 31/01/2020"
//         /> */}
//         {/* <BarChart
//             data={data}
//             valueKey="value"
//             comparedValueKey="value2"
//             legend="Du 01/01/20 au 31/01/2020"
//             comparedLegend="Du 01/01/21 au 31/01/2021"
//         /> */}
//     </div>
// </div>

const mergeStats = (prev, next, key) => {
    return prev.map((e, i) => ({
        name: e.name,
        value: e[key],
        value2: next[i][key],
    }))
}

const mergeTotals = (data) =>
    [
        {
            name: 'Pneus',
            value: get(data, 'totalTire', 0),
        },
        {
            name: 'Révision/ entretien',
            value: get(data, 'totalRevision', 0),
        },
        {
            name: 'Freinage',
            value: get(data, 'totalBrakes', 0),
        },
        {
            name: 'Vitrage',
            value: get(data, 'totalWindow', 0),
        },
        {
            name: 'Pièces techniques',
            value: get(data, 'totalPieces', 0),
        },
    ].filter((e) => e.value > 0)

const Dashboard = ({ history }) => {
    let { id } = useParams()
    const { impersonatedUser, isImpersonated, activeToken, user } = useAuth()
    const [activeItem, setSelectItem] = useState('')
    const [nbMonth, setNbMonth] = useState('current')
    const [hasCompare, setHasCompare] = useState(null)

    const thisYear = new Date().getFullYear()

    const curYear = useStats(
        thisYear,
        id === 'group' ? null : id,
        nbMonth === 'current' ? 12 : nbMonth,
        nbMonth === 'current'
    )
    const prevYear = useStats(
        hasCompare,
        id === 'group' ? null : id,
        nbMonth === 'current' ? 12 : nbMonth,
        nbMonth === 'current'
    )
    // console.log('curYear');console.log(curYear);
    useEffect(() => {
        const isAdmin =
            user.role === 'super-admin' ||
            user.role === 'admin' ||
            user.role === 'manager'

        if (isAdmin && !impersonatedUser) {
            history.push('/')
        }
    })

    const getTotalItem = (year) => {
        switch (activeItem) {
            case '':
                return Math.round(year?.totalAmount)
            case 'tire':
                return Math.round(year?.totalTire)
            case 'revision':
                return Math.round(year?.totalRevision)
            case 'braking':
                return Math.round(year?.totalBrakes)
            case 'glazing':
                return Math.round(year?.totalWindow)
            case 'technical':
                return Math.round(year?.totalPieces)

            default:
                return Math.round(year?.totalAmount)
        }
    }

    const hasPie =
        !!curYear?.totalTire ||
        !!curYear?.totalRevision ||
        !!curYear?.totalBrakes ||
        !!curYear?.totalWindow ||
        !!curYear?.totalPieces ||
        !!prevYear?.totalTire ||
        !!prevYear?.totalRevision ||
        !!prevYear?.totalBrakes ||
        !!prevYear?.totalWindow ||
        !!prevYear?.totalPieces

    const group = useQuery(
        [
            getGroupsDashboard?.name,
            {
                activeToken,
                user: isImpersonated ? impersonatedUser.id : user.id,
                range: null,
                unlimited: true,
                sort: null,
                filter: null,
            },
        ],
        getGroupsDashboard,
        {
            enabled: id === 'group',
        }
    )

    const account = useQuery(
        [
            getAccountsDashboard?.name,
            {
                activeToken,
                user: isImpersonated ? impersonatedUser.id : user.id,
                range: null,
                unlimited: true,
                sort: null,
                filter: { Codage: id },
            },
        ],
        getAccountsDashboard,
        {
            enabled: id !== 'group',
        }
    )

    // const agency = useQuery(
    //     ['agencyDetail', { id }],
    //     () => getAccountsDetail(id),
    //     { enabled: id !== 'group' }
    // )

    const titlePage =
        id === 'group'
            ? get(group, 'data.list.0.Raicpt', '')
            : get(account, 'data.list', [])?.find((e) => e?.Codage === id)
                  ?.Raiage

    return (
        <PageWrap
            onBack={() => history.push('/dashboard')}
            title={`Tableau de bord - ${
                id === 'group' ? 'agence' : 'compte'
            } ${titlePage}`}
        >
            <Row>
                <Col xl={9}>
                    <Panel
                        canNext={thisYear - 1 > hasCompare}
                        currentyear={hasCompare}
                        onPrevYear={() => setHasCompare((e) => e - 1)}
                        onNextYear={() => setHasCompare((e) => e + 1)}
                        title="Consommation HT par mois"
                        dates={
                            curYear.dates +
                            (hasCompare ? ' | ' + prevYear.dates : '')
                        }
                        hasMonthSelection={nbMonth}
                        onMonthSelected={setNbMonth}
                        dateCompared={hasCompare ? prevYear.dates : null}
                        onModify={() => setHasCompare(thisYear - 1)}
                        onCancel={() => setHasCompare(null)}
                        // setDates={setDates}
                    />
                </Col>
                <Col xl={9}>
                    <div className="chart">
                        <div
                            className="card full-width"
                            style={{ padding: '15px 35px 15px 5px' }}
                        >
                            <BarChart
                                data={mergeStats(
                                    curYear.data,
                                    prevYear.data,
                                    `value${activeItem}`
                                )}
                                valueKey="value"
                                comparedValueKey={hasCompare ? 'value2' : null}
                                legend={curYear.dates}
                                comparedLegend={
                                    hasCompare ? prevYear.dates : null
                                }
                            />
                        </div>
                    </div>
                </Col>
                <Col
                    className={`status-card-container ${
                        activeItem === 'tire' && hasCompare
                            ? 'status-quarter'
                            : activeItem === 'tire' || hasCompare
                            ? null
                            : 'single-item'
                    }`}
                    xl={3}
                >
                    {/* pneumatics compare */}
                    {!hasCompare && (
                        <StatsCard
                            color="secondary"
                            size="xs"
                            title="Exporter les données"
                            order="3"
                            onClick={() =>
                                exportData(
                                    id === 'group' ? null : id,
                                    titlePage
                                )
                            }
                        />
                    )}
                    <div className="stats-group">
                        <StatsCard
                            title="consommation totale (hT)"
                            loading={curYear.isLoading}
                            number={new Intl.NumberFormat('fr-FR', {
                                style: 'currency',
                                currency: 'EUR',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            }).format(getTotalItem(curYear))}
                            size="sm"
                        />
                        {hasCompare && (
                            <StatsCard
                                color="updated"
                                title="Consommation période comparée (ht)"
                                loading={prevYear.isLoading}
                                number={new Intl.NumberFormat('fr-FR', {
                                    style: 'currency',
                                    currency: 'EUR',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                }).format(getTotalItem(prevYear))}
                                increase={
                                    getTotalItem(curYear) >
                                    getTotalItem(prevYear)
                                        ? Math.round(
                                              ((getTotalItem(curYear) -
                                                  getTotalItem(prevYear)) /
                                                  getTotalItem(curYear)) *
                                                  100
                                          )
                                        : null
                                }
                                decrease={
                                    getTotalItem(curYear) <
                                    getTotalItem(prevYear)
                                        ? Math.round(
                                              ((getTotalItem(prevYear) -
                                                  getTotalItem(curYear)) /
                                                  getTotalItem(prevYear)) *
                                                  100
                                          )
                                        : null
                                }
                                size="sm"
                            />
                        )}
                    </div>
                    {activeItem === 'tire' && (
                        <div className="stats-group">
                            <>
                                <StatsCard
                                    title="nombre de pneus en gardiennage"
                                    number={new Intl.NumberFormat('fr-FR', {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    }).format(curYear.totalGuarding)}
                                    size="sm"
                                />
                                {hasCompare && (
                                    <StatsCard
                                        color="updated"
                                        title="période comparée pneus en gardiennage"
                                        number={new Intl.NumberFormat('fr-FR', {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        }).format(prevYear.totalGuarding)}
                                        decrease={
                                            curYear.totalGuarding >
                                            prevYear.totalGuarding
                                                ? Math.round(
                                                      ((curYear.totalGuarding -
                                                          prevYear.totalGuarding) /
                                                          curYear.totalGuarding) *
                                                          100
                                                  )
                                                : null
                                        }
                                        increase={
                                            curYear.totalGuarding <
                                            prevYear.totalGuarding
                                                ? Math.round(
                                                      ((prevYear.totalGuarding -
                                                          curYear.totalGuarding) /
                                                          prevYear.totalGuarding) *
                                                          100
                                                  )
                                                : null
                                        }
                                        size="sm"
                                    />
                                )}
                            </>
                        </div>
                    )}
                    {/* pneumatics compare */}
                </Col>
            </Row>

            <Row className="row-space">
                <Col xl={9} className="tablet-large">
                    {activeItem === '' || activeItem === 'tire' ? (
                        <div className="panel">
                            <div className="panel-wrap">
                                <div className="panel-label">
                                    Consommation (factures uniquement)
                                </div>
                            </div>
                            <div className="panel-line"></div>
                        </div>
                    ) : null}
                </Col>
                <Col xl={3}>
                    <div className="panel">
                        <div className="panel-wrap">
                            <div className="panel-label">Accès rapide</div>
                        </div>
                        <div className="panel-line"></div>
                    </div>
                </Col>
            </Row>

            <Row>
                {activeItem === '' || activeItem === 'tire' ? null : (
                    <Col xl={9}></Col>
                )}

                {activeItem === '' && (
                    <>
                        <Col xl={9} className="mobile">
                            {activeItem === '' || activeItem === 'tire' ? (
                                <div className="panel">
                                    <div className="panel-wrap">
                                        <div className="panel-label">
                                            Consommation (factures uniquement)
                                        </div>
                                    </div>
                                    <div className="panel-line"></div>
                                </div>
                            ) : null}
                        </Col>
                        <Col xl={9}>
                            <div className="chart">
                                <div className="card full-width">
                                    {hasPie ? (
                                        <PieChart
                                            outerData={mergeTotals(curYear)}
                                            innerData={
                                                hasCompare
                                                    ? mergeTotals(prevYear)
                                                    : null
                                            }
                                            legendBottom={true}
                                            // height={300}
                                            height={
                                                Math.ceil(
                                                    mergeTotals(curYear)
                                                        .length / 2
                                                ) *
                                                    30 +
                                                300
                                            }
                                            dataOuter={curYear.dates}
                                            dataInner={
                                                hasCompare
                                                    ? prevYear.dates
                                                    : undefined
                                            }
                                        />
                                    ) : (
                                        <div className="empty-data">
                                            <span>
                                                {
                                                    'Pas de données pour cette période'
                                                }
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </>
                )}

                {activeItem === 'tire' && (
                    <>
                        <Col xl={9} className="mobile">
                            {activeItem === '' || activeItem === 'tire' ? (
                                <div className="panel">
                                    <div className="panel-wrap">
                                        <div className="panel-label">
                                            Consommation (factures uniquement)
                                        </div>
                                    </div>
                                    <div className="panel-line"></div>
                                </div>
                            ) : null}
                        </Col>
                        <Col xl={4.5}>
                            <div className="chart">
                                <div className="card full-width">
                                    {!curYear.totalTireType?.length ? (
                                        <div className="empty-data">
                                            <span>
                                                {
                                                    'Pas de données pour cette période'
                                                }
                                            </span>
                                        </div>
                                    ) : (
                                        <PieChart
                                            outerData={curYear.totalTireType}
                                            innerData={
                                                hasCompare
                                                    ? prevYear.totalTireType
                                                    : null
                                            }
                                            legendBottom={true}
                                            // height={300}
                                            height={
                                                Math.ceil(
                                                    curYear.totalTireType
                                                        .length / 2
                                                ) *
                                                    30 +
                                                300
                                            }
                                            dataOuter={curYear.dates}
                                            dataInner={
                                                hasCompare
                                                    ? prevYear.dates
                                                    : undefined
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col xl={4.5}>
                            <div className="chart">
                                <div className="card full-width">
                                    {!curYear.totalTireBrand?.length ? (
                                        <div className="empty-data">
                                            <span>
                                                {
                                                    'Pas de données pour cette période'
                                                }
                                            </span>
                                        </div>
                                    ) : (
                                        <PieChart
                                            outerData={curYear.totalTireBrand}
                                            innerData={
                                                hasCompare
                                                    ? prevYear.totalTireBrand
                                                    : null
                                            }
                                            legendBottom={true}
                                            // height={300}
                                            height={
                                                Math.ceil(
                                                    curYear.totalTireBrand
                                                        .length / 2
                                                ) *
                                                    30 +
                                                300
                                            }
                                            dataOuter={curYear.dates}
                                            dataInner={
                                                hasCompare
                                                    ? prevYear.dates
                                                    : undefined
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </Col>
                    </>
                )}
                <Col xl={3} className="order-first-mobile">
                    <QuickAccess
                        active={activeItem}
                        onClick={(e) =>
                            setSelectItem((o) => (o === e ? '' : e))
                        }
                    />
                </Col>
            </Row>
        </PageWrap>
    )
}

export default withRouter(Dashboard)
