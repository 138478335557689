import { useState, useMemo, useRef } from 'react'
import PageWrap from '../components/pageWrap'
import Panel from '../components/panel'
import StatsCard from '../components/statsCard'
import Input from '../components/input'
import QuickAccess from '../components/quickAccess'
import { Row, Col } from 'react-grid-system'
import { useQuery, useQueryClient, QueryCache } from 'react-query'
import PieChart from '../components/pieChart'
import { getHomeStats } from '../network/home'
import { getCarCount, getTires } from '../network/guarding'
import { getUsersAdmin } from '../network/users'
import { useAuth } from '../hooks/useAuth'
import get from 'lodash.get'
import { useOutsideAlerter } from '../hooks/useOutsideAlerter'

import './Home.scss'

const monthBegining = () => {
    const date = new Date()

    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    date.setDate(1)

    return date
}

const monthEnding = () => {
    const date = new Date()

    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)

    if (date.getMonth() === 11) {
        date.setMonth(0)
        date.setFullYear(date.getFullYear() + 1)
    } else {
        date.setMonth(date.getMonth() + 1)
    }

    date.setDate(0)

    return date
}

const Home = () => {
    const [activeItem, setSelectItem] = useState('')
    const [openList, setOpenList] = useState(false)
    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef, () => setOpenList(false))
    const queryClient = useQueryClient()
    // const nowDate = new Date()
    // const ddd = new Date()
    // ddd.setHours(0)
    // ddd.setMinutes(0)
    // ddd.setSeconds(0)
    // ddd.setMilliseconds(0)
    // ddd.setDate(1)

    // const fff = new Date(ddd)

    // if (fff.getMonth() === 11) {
    //     fff.setMonth(0)
    //     fff.setFullYear(fff.getFullYear() + 1)
    // } else {
    //     fff.setMonth(fff.getMonth() + 1)
    // }

    // fff.setDate(0)

    const [dates, setDates] = useState([monthBegining(), monthEnding()])

    const {
        user,
        impersonate,
        isImpersonated,
        impersonatedUser,
        unimpersonate,
        isImpersonateLoading,
        activeToken,
    } = useAuth()

    // const conso = useQuery(
    //     [
    //         getHomeStats?.name,
    //         {
    //             filter: {
    //                 dateStart: new Date(`01/01/${new Date().getFullYear()}`),
    //                 dateEnd: new Date(),
    //             },
    //         },
    //     ],
    //     getHomeStats,
    //     { enabled: false }
    // )
    const isAdmin =
        user.role === 'super-admin' ||
        user.role === 'admin' ||
        user.role === 'manager'

    const data = useQuery(
        [
            getHomeStats?.name,
            {
                filter: {
                    activeToken,
                    user: isImpersonated ? impersonatedUser.id : user.id,
                    dateStart: !!dates?.length ? dates[0] : null,
                    dateEnd: !!dates?.length ? dates[1] : null,
                    cache: true,
                },
            },
        ],
        getHomeStats,
        { enabled: isImpersonated }
    )

    const tires = useQuery(
        [
            'tires',
            {
                activeToken,
                id: impersonatedUser?.id || user?.id,
            },
        ],
        getTires,
        {
            enabled: !isAdmin || (!!isAdmin && isImpersonated),
        }
    )

    const cars = useQuery(
        ['cars', { activeToken, id: impersonatedUser?.id || user?.id }],
        getCarCount,
        {
            enabled: !isAdmin || (!!isAdmin && isImpersonated),
        }
    )

    const [q, setQ] = useState('')
    const userForAdmin = useQuery(
        [
            getUsersAdmin?.name,
            {
                filter: {
                    q: q ? q : undefined,
                    _id: { $ne: user.id },
                    impersonatable: true,
                },
                range: [0, 3],
                sort: null,
                unlimited: null,
            },
        ],
        getUsersAdmin,
        { enabled: isAdmin }
    )

    const onUserSelected = (id, role) => {
        const params = {
            role: role.role,
            Codcpt: role.Codcpt,
            Codage: role.Codage,
            Matric: role.Matric,
        }

        setQ('')
        setOpenList(false)
        impersonate(id, params)
        queryClient.invalidateQueries()
        const queryCache = new QueryCache()
        queryCache.clear()
    }

    const pieData = [
        {
            name: 'Pneus',
            value: data?.data?.totalTire || 0,
        },
        {
            name: 'Révision/entretien',
            value: data?.data?.totalRevision || 0,
        },
        {
            name: 'Freinage',
            value: data?.data?.totalBrakes || 0,
        },
        {
            name: 'Vitrage',
            value: data?.data?.totalWindow || 0,
        },
        {
            name: 'Pièces techniques',
            value: data?.data?.totalPieces || 0,
        },
    ]

    const hasPie =
        !!data?.data?.totalTire ||
        !!data?.data?.totalRevision ||
        !!data?.data?.totalBrakes ||
        !!data?.data?.totalWindow ||
        !!data?.data?.totalPieces

    // const getTotal = () => {
    //     switch (activeItem) {
    //         case 'tire':
    //             return get(data, 'totalTire', 0)
    //         case 'revision':
    //             return get(data, 'totalRevision', 0)
    //         case 'braking':
    //             return get(data, 'totalBrakes', 0)
    //         case 'glazing':
    //             return get(data, 'totaWindow', 0)
    //         case 'technical':
    //             return get(data, 'totalPieces', 0)

    //         default:
    //             return get(data, 'totalAmount', 0)
    //     }
    // }

    const getMonthValue = () => {
        switch (activeItem) {
            case '':
                return Math.round(data?.data?.totalAmount || 0)
            case 'tire':
                return Math.round(data?.data?.totalTire || 0)
            case 'revision':
                return Math.round(data?.data?.totalRevision || 0)
            case 'braking':
                return Math.round(data?.data?.totalBrakes || 0)
            case 'glazing':
                return Math.round(data?.data?.totalWindow || 0)
            case 'technical':
                return Math.round(data?.data?.totalPieces || 0)

            default:
                return Math.round(data?.data?.totalAmount || 0)
        }
    }

    let totalTireType = useMemo(() => {
        if (!data?.data?.totalTireType) return []

        return Object.keys(data?.data?.totalTireType).map((key) => ({
            name: key,
            value: data?.data?.totalTireType[key],
        }))
    }, [data?.data?.totalTireType])

    let totalTireBrand = useMemo(() => {
        if (!data?.data?.totalTireType) return []

        return Object.keys(data?.data?.totalTireBrand).map((key) => ({
            name: key,
            value: data?.data?.totalTireBrand[key],
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.data?.totalTireBrand])

    return (
        <PageWrap title="ACCUEIL">
            <Panel
                title="Activité"
                noCompare
                hasCalendar
                dates={dates}
                // dates={`01/01/${thisYear} - 31/01/${thisYear}`}
                setDates={setDates}
                isAdmin={isAdmin}
            />

            <Row className="home-status-card-container">
                <Col xs={4} xl={isAdmin ? 3 : 4}>
                    <StatsCard
                        title="Consommation du mois"
                        number={
                            data.isLoading ? (
                                <div className="icon-loading button-loading" />
                            ) : (
                                `${getMonthValue()}€`
                            )
                        }
                    />
                </Col>
                <Col xs={4} xl={isAdmin ? 3 : 4}>
                    <StatsCard
                        title="Véhicules gérés"
                        number={
                            cars.isLoading ? (
                                <div className="icon-loading button-loading" />
                            ) : (
                                Math.round(get(cars, 'data.amount', 0))
                            )
                        }
                    />
                </Col>
                <Col xs={4} xl={isAdmin ? 3 : 4}>
                    <StatsCard
                        title="Pneus en gardiennage"
                        number={
                            tires.isLoading ? (
                                <div className="icon-loading button-loading" />
                            ) : (
                                Math.round(
                                    get(tires, 'data.list.0.totalAmount', 0)
                                )
                            )
                        }
                    />
                </Col>
                {isAdmin &&
                    (isImpersonated || isImpersonateLoading ? (
                        <Col xs={12} xl={3}>
                            <div className="card impersonating">
                                {isImpersonateLoading ? (
                                    <div className="impersonating-header">
                                        Changements de la masquerade...
                                    </div>
                                ) : (
                                    <>
                                        <div className="impersonating-header">
                                            Connecté en tant que :
                                        </div>
                                        <div className="impersonating-name">{`${
                                            impersonatedUser.firstName || ''
                                        } ${impersonatedUser.lastName || ''} (${
                                            impersonatedUser.role === 'group'
                                                ? 'Groupe'
                                                : 'Agence'
                                        })`}</div>
                                        <div
                                            className="impersonating-disconnect"
                                            onClick={() => {
                                                unimpersonate()
                                                queryClient.invalidateQueries()
                                            }}
                                        >
                                            Déconnexion
                                        </div>
                                    </>
                                )}
                            </div>
                        </Col>
                    ) : (
                        <Col className="home-search-container" xs={12} xl={3}>
                            <div ref={wrapperRef}>
                                <Input
                                    search
                                    value={q}
                                    color="primary"
                                    size="large"
                                    fullwidth="true"
                                    // onBlur={() => setOpenList(false)}
                                    onFocus={() => setOpenList(true)}
                                    onChange={(e) => setQ(e.target.value)}
                                />
                                {openList && (
                                    <div className="user-list-container">
                                        <ul className="user-list card">
                                            {get(
                                                userForAdmin,
                                                'data.list',
                                                []
                                            ).map((e, i) => (
                                                <li
                                                    key={i}
                                                    className="user-list-item"
                                                >
                                                    <span className="name">{`${
                                                        e.firstName || ''
                                                    }${
                                                        !!e.lastName
                                                            ? ` ${e.lastName}`
                                                            : ''
                                                    }`}</span>
                                                    {e.roles.map((role) => (
                                                        <>
                                                            <div
                                                                onClick={() =>
                                                                    onUserSelected(
                                                                        e.id,
                                                                        role
                                                                    )
                                                                }
                                                                className="roles"
                                                            >
                                                                <span>
                                                                    {role.role ===
                                                                        'manager' &&
                                                                        `${role.Matric} (Manager)`}
                                                                    {role.role ===
                                                                        'group' &&
                                                                        `${role.Raicpt} (Groupe)`}
                                                                    {role.role ===
                                                                        'agency' &&
                                                                        `${role.Raiage} (Agence)`}
                                                                    {role.role ===
                                                                        'admin' &&
                                                                        'Admin'}
                                                                    {role.role ===
                                                                        'super-admin' &&
                                                                        'Super Admin'}
                                                                </span>
                                                                <span>
                                                                    {'>'}
                                                                </span>
                                                            </div>
                                                        </>
                                                    ))}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </Col>
                    ))}
            </Row>

            <Row>
                {activeItem === '' || activeItem === 'tire' ? (
                    <Col xl={9} className="tablet-large">
                        <div className="panel">
                            <div className="panel-wrap">
                                <div className="panel-label">
                                    Consommation (factures uniquement)
                                </div>
                            </div>
                            <div className="panel-line"></div>
                        </div>
                    </Col>
                ) : (
                    <Col xl={9}></Col>
                )}

                <Col xl={3}>
                    <div className="panel">
                        <div className="panel-wrap">
                            <div className="panel-label">Accès rapide</div>
                        </div>
                        <div className="panel-line"></div>
                    </div>
                </Col>
            </Row>

            <Row>
                {/* <Col xl={9} className="mobile">
                    <div className="panel">
                        <div className="panel-wrap">
                            <div className="panel-label">Consommation</div>
                        </div>
                        <div className="panel-line"></div>
                    </div>
                </Col> */}

                {activeItem === '' || activeItem === 'tire' ? null : (
                    <Col xl={9}></Col>
                )}

                {(activeItem === '' || !activeItem) && (
                    <Col xl={9}>
                        <div className="chart">
                            <div className="card full-width">
                                {hasPie ? (
                                    <PieChart
                                        outerData={pieData}
                                        legendBottom={true}
                                        height={
                                            Math.ceil(pieData.length / 2) * 30 +
                                            300
                                        }
                                    />
                                ) : (
                                    <div className="empty-data">
                                        <span>
                                            {
                                                'Pas de données pour cette période'
                                            }
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>
                )}

                {activeItem === 'tire' && (
                    <>
                        <Col xl={4.5}>
                            <div className="chart">
                                <div className="card full-width">
                                    {!totalTireType?.length ? (
                                        <div className="empty-data">
                                            <span>
                                                {
                                                    'Pas de données pour cette période'
                                                }
                                            </span>
                                        </div>
                                    ) : (
                                        <PieChart
                                            outerData={totalTireType}
                                            legendBottom={true}
                                            // height={300}
                                            height={
                                                Math.ceil(
                                                    totalTireType.length / 2
                                                ) *
                                                    30 +
                                                300
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col xl={4.5}>
                            <div className="chart">
                                <div className="card full-width">
                                    {!totalTireBrand?.length ? (
                                        <div className="empty-data">
                                            <span>
                                                {
                                                    'Pas de données pour cette période'
                                                }
                                            </span>
                                        </div>
                                    ) : (
                                        <PieChart
                                            outerData={totalTireBrand}
                                            legendBottom={true}
                                            // height={300}
                                            height={
                                                Math.ceil(
                                                    totalTireBrand.length / 2
                                                ) *
                                                    30 +
                                                300
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </Col>
                    </>
                )}

                <Col xl={3} className="order-first-mobile">
                    <QuickAccess
                        active={activeItem}
                        onClick={(e) =>
                            setSelectItem((o) => (e === o ? '' : e))
                        }
                    />
                </Col>
            </Row>
        </PageWrap>
    )
}

export default Home
