import { useState, useRef } from 'react'
import { useCalendar } from '../../hooks/useCalendar'
import { useOutsideAlerter } from '../../hooks/useOutsideAlerter'
import './calendar.scss'

const Calendar = ({ onChange, defaultDates, children }) => {
    const [show, setShow] = useState(false)
    const [selected, setSelected] = useState(defaultDates ? defaultDates : [])

    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef, () => setShow(false))
    const {
        goPrevMonth,
        goNextMonth,
        currentMonth,
        currentYear,
        currentMonthDays,
        goToCurrentMonth,
    } = useCalendar({
        selected,
        disabled: [],
    })

    const days = ['D', 'L', 'M', 'M', 'J', 'V', 'S']
    const months = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
    ]

    const dateMatch = (day, month, year, date) =>
        date &&
        date.getDate() === day &&
        date.getMonth() === month &&
        date.getFullYear() === year

    const handleDateCLick = (day, month, year) => {
        const isFirstClicked = dateMatch(day, month, year, selected[0])
        const isSecondClicked = dateMatch(day, month, year, selected[1])

        if (!selected.length)
            setSelected([new Date(year, month, day, 0, 0, 0, 0)])

        if (selected.length === 1) {
            if (isFirstClicked) {
                setSelected([])
            } else {
                setSelected([
                    selected[0],
                    new Date(year, month, day, 0, 0, 0, 0),
                ])
            }
        }

        if (selected.length === 2) {
            if (isFirstClicked) {
                setSelected([])
            } else if (isSecondClicked) {
                setSelected([selected[0]])
            } else {
                setSelected([
                    selected[0],
                    new Date(year, month, day, 0, 0, 0, 0),
                ])
            }
        }
    }

    const cancel = () => {
        goToCurrentMonth()
        setShow(false)
    }

    const validate = () => {
        if (onChange) {
            if (selected.length === 2) {
                onChange(selected)
            } else {
                onChange([])
                setSelected([])
            }
        }

        setShow(false)
        goToCurrentMonth()
    }

    const Case = ({ date, disabled, selected, noClick, start, end }) => (
        <div className="day-wrap">
            <div
                onClick={() =>
                    disabled || noClick
                        ? null
                        : handleDateCLick(date, currentMonth, currentYear)
                }
                className={`case cursor-pointer${disabled ? ' disabled' : ''}${
                    selected ? ' selected' : ''
                }${start ? ' start' : ''}${end ? ' end' : ''}`}
            >
                <p>{date}</p>
            </div>
        </div>
    )

    const isCaseDisabled = (day, month, year) => {
        if (!selected[0]) return false

        const date = new Date(year, month, day)

        return date < selected[0]
    }

    const isCaseSelected = (day, month, year) => {
        if (!selected[0] || !selected[1]) return false

        const date = new Date(year, month, day)
        return date > selected[0] && date < selected[1]
    }

    return (
        <div ref={wrapperRef} className="calendar">
            <div onClick={() => setShow((e) => !e)}>{children}</div>
            <div className={`popup${show ? ' show' : ''}`}>
                <div className="current">
                    <div
                        className="prev-month icon-arrow-left cursor-pointer"
                        onClick={goPrevMonth}
                    />
                    <div
                        className="display-date cursor-pointer"
                        onClick={goToCurrentMonth}
                    >{`${months[currentMonth]} ${currentYear}`}</div>
                    <div
                        className="next-month icon-arrow-right cursor-pointer"
                        onClick={goNextMonth}
                    />
                </div>
                <div className="dates-wrap">
                    {[
                        ...days.map((day, index) => (
                            <div key={`days-${index}`} className="day-wrap">
                                <div className="case days">
                                    <p>{day}</p>
                                </div>
                            </div>
                        )),
                        ...currentMonthDays.prev.map((day, index) => (
                            <Case
                                key={`prev-${index}`}
                                // date={day.date}
                                disabled={true}
                                selected={false}
                            />
                        )),
                        ...currentMonthDays.current.map((day, index) => (
                            <Case
                                key={`curr-${index}`}
                                date={day.date}
                                disabled={isCaseDisabled(
                                    day.date,
                                    currentMonth,
                                    currentYear
                                )}
                                selected={
                                    day.selected ||
                                    isCaseSelected(
                                        day.date,
                                        currentMonth,
                                        currentYear
                                    )
                                }
                                start={
                                    day.selected &&
                                    dateMatch(
                                        day.date,
                                        currentMonth,
                                        currentYear,
                                        selected[0]
                                    ) &&
                                    selected[1]
                                }
                                end={
                                    day.selected &&
                                    dateMatch(
                                        day.date,
                                        currentMonth,
                                        currentYear,
                                        selected[1]
                                    )
                                }
                            />
                        )),
                        ...currentMonthDays.next.map((day, index) => (
                            <Case
                                key={`next-${index}`}
                                // date={day.date}
                                disabled={true}
                                selected={false}
                            />
                        )),
                    ]}
                </div>
                <div className="action">
                    <button className="cancel" onClick={cancel}>
                        Annuler
                    </button>
                    <button className="validate" onClick={validate}>
                        Valider
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Calendar
