import { useState, useEffect } from 'react'
import PageWrap from '../components/pageWrap'
import Tabs from '../components/tabs'
import Tab from '../components/tab'
import TabPanel from '../components/tabPanel'
import Table from '../components/table'
import {
    getByImmat,
    downloadFile,
    importFile,
    transferAccount,
    deleteMultiple,
    downloadVehicleFile,
} from '../network/parkManagement'
import { getAccountsDashboard } from '../network/dashboard'
import { useLocation, withRouter } from 'react-router-dom'
import './ParkManagement.scss'
import get from 'lodash.get'
import { useAuth } from '../hooks/useAuth'
import queryString from 'query-string'

// const byImmatBtnsSelect = [
//     {
//         color: 'primary',
//         label: 'Ajouter',
//     },
//     {
//         color: 'secondary',
//         label: 'Importer',
//         icon: 'export',
//     },
// ]

const ParkManagement = ({ history }) => {
    const { user, impersonatedUser } = useAuth()
    const location = useLocation()
    const [selectedAccounts, setSelectedAccount] = useState(null)
    const [, setSelectedLines] = useState([])
    const [selectedLinesImmat, setSelectedLinesImmat] = useState([])

    const handleLineSelected = (e) => {
        setSelectedAccount(e.Codage)
    }

    useEffect(() => {
        const isAdmin =
            user.role === 'super-admin' ||
            user.role === 'admin' ||
            user.role === 'manager'

        if (isAdmin && !impersonatedUser) {
            history.push('/')
        }
    })

    useEffect(() => {
        let queryParams = queryString.parse(location.search)

        if (!queryParams.account && !!selectedAccounts) {
            setSelectedAccount(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search])

    const defaultTab =
        queryString.parse(location.search).byimmat === 'true'
            ? 'registration'
            : 'profile'

    useEffect(() => {
        let queryParams = queryString.parse(location.search)
        queryParams.account = !!selectedAccounts ? true : undefined
        const stringified = queryString.stringify(queryParams)

        history.replace(`?${stringified}`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccounts])

    const byAccBtns = [
        {
            type: 'modal',
            modalType: 'import-info',
        },
        {
            type: 'import',
            color: 'primary',
            label: 'Importer',
            request: importFile,
        },
        {
            color: 'secondary',
            action: 'callback',
            label: 'Modèle',
            icon: 'export',
            onClick: downloadFile,
        },
        {
            color: 'secondaryAlt',
            action: 'callback',
            label: 'Exporter',
            icon: 'export',
            onClick: () => downloadVehicleFile(),
        },
        {
            type: 'link',
            color: 'primary',
            label: 'Ajouter',
            link: '/park-managment/create',
        },
    ]

    const byImmatBtns = [
        {
            color: 'danger',
            label: 'Supprimer',
            action: 'modal-action',
            request: deleteMultiple,
            modalIcon: 'warning',
            contentText: (selectedLines) =>
                `Êtes-vous sûr de vouloir supprimer ${selectedLines?.length} immatriculations ?`,
            cancelLabel: 'Non, annuler',
            actionLabel: 'Oui, supprimer',
        },
        {
            color: 'primary',
            label: 'Transférer vers un autre compte',
            action: 'modal-select',
            request: transferAccount,
        },
        {
            color: 'secondaryAlt',
            action: 'callback',
            label: 'Exporter',
            icon: 'export',
            onClick: () => downloadVehicleFile(selectedAccounts),
        },
    ]

    return (
        <PageWrap title="GESTION DU PARC">
            <Tabs initialValue={defaultTab} className="park-page">
                <div className="card border-bottom-none no-padding">
                    <div className="tabs-wrap">
                        <Tab
                            closable={!!selectedAccounts}
                            onClose={() => setSelectedAccount(null)}
                            name="profile"
                        >
                            Par compte
                        </Tab>
                        <Tab name="registration">Par immatriculation</Tab>
                    </div>
                </div>
                <div className="card border-top-none no-padding">
                    <TabPanel name="profile" className="full-width">
                        {!selectedAccounts ? (
                            <Table
                                filter={null}
                                buttons={byAccBtns}
                                request={getAccountsDashboard}
                                columns={columnsCompte}
                                defaultFilters={null}
                                size="small"
                                checkbox={false}
                                hasPagination={false}
                                lineClassname={(e) => {
                                    switch (e.status) {
                                        case 'active':
                                            return 'background-error'
                                        case 'r':
                                            return 'background-success'
                                        case 'rt':
                                            return 'background-info'
                                        default:
                                            return ''
                                    }
                                }}
                                onSelectedLinesChange={setSelectedLines}
                                onLineSelected={handleLineSelected}
                            />
                        ) : (
                            <Table
                                filter={{
                                    key: 'q',
                                    placeholder:
                                        'Rechercher par immatriculation',
                                }}
                                buttons={byImmatBtns}
                                request={getByImmat}
                                columns={columnsImmatByAccount}
                                defaultFilters={{
                                    CODECLI: { $in: [selectedAccounts] },
                                }}
                                size="small"
                                checkbox={true}
                                lineClassname={(e) => {
                                    switch (e.status) {
                                        case 'active':
                                            return 'background-error'
                                        case 'r':
                                            return 'background-success'
                                        case 'rt':
                                            return 'background-info'
                                        default:
                                            return ''
                                    }
                                }}
                                onSelectedLinesChange={setSelectedLines}
                                onLineSelected={(e) =>
                                    history.push(`/park-managment/${e.id}`)
                                }
                            />
                        )}
                    </TabPanel>
                    <TabPanel name="registration">
                        <Table
                            filter={{
                                key: 'q',
                                placeholder: 'Rechercher par immatriculation',
                            }}
                            buttons={
                                selectedLinesImmat.length
                                    ? byImmatBtns
                                    : byAccBtns
                            }
                            request={getByImmat}
                            columns={columnsImmat}
                            checkbox
                            lineClassname={(e) => {
                                switch (e.status) {
                                    case 'active':
                                        return 'background-error'
                                    case 'r':
                                        return 'background-success'
                                    case 'rt':
                                        return 'background-info'
                                    default:
                                        return ''
                                }
                            }}
                            onSelectedLinesChange={setSelectedLinesImmat}
                            onLineSelected={(e) =>
                                history.push(`/park-managment/${e.id}`)
                            }
                            size="small"
                        />
                    </TabPanel>
                </div>
            </Tabs>
        </PageWrap>
    )
}

const columnsCompte = [
    {
        title: 'Code',
        key: 'Codage',
        render: (e) => e.Codage,
        sorter: true,
    },
    {
        title: 'Compte',
        key: 'Raiage',
        render: (e) => e.Raiage,
        sorter: true,
    },
    // {
    //     render: (e, history) => (
    //         <IconRedirection url={`/park-managment/${e.id}`} />
    //     ),
    //     width: 100,
    // },
]

const columnsImmatByAccount = [
    {
        title: 'Immatriculation',
        key: 'IMMAT',
        render: (e) => e.IMMAT,
        sorter: true,
    },
    {
        title: 'Compte',
        key: 'agency',
        render: (e) => e.agency?.Raiage,
    },
    {
        title: 'Carte Speedy Fleet',
        key: 'cards',
        render: (e) => get(e, 'cards.0.NUMCARTE', '-'),
        sorter: true,
    },
]

const columnsImmat = [
    {
        title: 'Immatriculation',
        key: 'IMMAT',
        render: (e) => e.IMMAT,
        sorter: true,
    },
    {
        title: 'Compte',
        key: 'agency',
        render: (e) => e.agency?.Raiage,
        // sorter: true,
        dropdown: {
            title: 'Compte',
            request: getAccountsDashboard,
            labelKey: 'Raiage',
            valueKey: 'Codage',
        },
    },
    {
        title: 'Carte Speedy Fleet',
        key: 'cards',
        render: (e) => get(e, 'cards.0.NUMCARTE', '-'),
        sorter: true,
    },
]

export default withRouter(ParkManagement)
