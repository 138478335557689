import { React } from 'react'

import { Switch, Link, Route } from 'react-router-dom'
import cn from 'classnames'

import Home from '../../pages/Home'
import Account from '../../pages/Account'
import AccountDetail from '../../pages/AccountDetail'
import Dashboard from '../../pages/Dashboard'
import DashboardAccount from '../../pages/DashboardAccount'
import ParkManagement from '../../pages/ParkManagement'
import Guarding from '../../pages/Guarding'
import Invoices from '../../pages/Invoices'
import Users from '../../pages/Users'
// import PrivateRoute from './Route'
import Footer from '../footer'
import Menu from '../menu'
import RegDetail from '../../pages/RegDetail'
import UserInfo from '../userInfo'
import UserProfile from '../../pages/UserProfile'
import { useData } from '../../hooks/useData'
import './navigation.scss'
import logo from '../../assets/images/logo-speedy-fleet.png'
import { useAuth } from '../../hooks/useAuth'

const Navigation = () => {
    const { isMenuOpen, closeMenu } = useData()
    const { signOut } = useAuth()

    return (
        <div className="page">
            <nav className={cn('nav', { show: isMenuOpen })}>
                <button
                    type="button"
                    className="nav-close icon-close"
                    onClick={closeMenu}
                />
                <Link
                    to="/"
                    className="nav-logo"
                    style={{ backgroundImage: `url(${logo})` }}
                />

                <Menu />

                <div className="nav-user">
                    <div className="nav-user-left">
                        <UserInfo />
                    </div>
                    <div className="nav-user-right">
                        <Link to="/" className="icon-logout" onClick={signOut}>
                            <span>Se déconnecter</span>
                        </Link>
                    </div>
                </div>
                <Footer />
            </nav>
            {/* <div className="main-wrap"> */}
            {/* <Header /> */}
            {/* <main className="main"> */}
            <Switch>
                <Route path="/account" exact>
                    <Account />
                </Route>
                {/* hardcored url for dynamic detail account page */}
                <Route path="/account1" exact>
                    <AccountDetail />
                </Route>
                <Route path="/dashboard" exact>
                    <Dashboard />
                </Route>
                <Route path="/dashboard/:id" exact>
                    <DashboardAccount />
                </Route>
                <Route path="/park-managment" exact>
                    <ParkManagement />
                </Route>
                {/* hardcored url for dynamic detail page */}
                <Route path="/park-managment/:id" exact>
                    <RegDetail />
                </Route>
                <Route path="/guarding" exact>
                    <Guarding />
                </Route>
                <Route path="/invoices" exact>
                    <Invoices />
                </Route>
                <Route path="/users" exact>
                    <Users />
                </Route>
                {/* hardcored url for dynamic user profile page */}
                <Route path="/users/:id" exact>
                    <UserProfile />
                </Route>
                <Route path="/" exact>
                    <Home />
                </Route>
                <Route>
                    <Home />
                </Route>
            </Switch>
            {/* </main> */}
            {/* <Footer /> */}
            {/* </div> */}

            <Menu fixed />
        </div>
    )
}

export default Navigation
