import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import get from 'lodash.get'
import Dropdown from '../dropdown'
import Checkbox from '../checkbox'
import Button from '../button'
import './dropdown-search.scss'
import { useAuth } from '../../hooks/useAuth'

const DropdownSearch = ({
    title,
    request,
    onSave,
    // keyValue,
    filters,
    defaultFilters,
    options,
    labelKey,
    valueKey,
}) => {
    const [search, seatSearch] = useState('')
    const { impersonatedUser, isImpersonated, activeToken, user } = useAuth()
    const [selectedValues, setSelectedValues] = useState(
        new Set(filters && filters['$in'] ? filters['$in'] : [])
    )
    // const [showAutocomplete, setShowAutocomplete] = useState(false)

    useEffect(
        () =>
            setSelectedValues((e) =>
                filters && filters['$in'] ? new Set(filters['$in']) : e
            ),
        [filters]
    )

    const checkboxes = useQuery(
        [
            request?.name,
            {
                activeToken,
                user: isImpersonated ? impersonatedUser.id : user.id,
                filter: null,
                range: null,
                sort: null,
                unlimited: null,
            },
        ],
        request,
        { enabled: !!request }
    )

    // const handleChange = (value) => {
    //     if (value.length) {
    //         setShowAutocomplete(true)
    //     } else {
    //         setShowAutocomplete(false)
    //     }
    // }

    const handleCheckboxChange = (id) => {
        const tempSelected = new Set(selectedValues)

        if (tempSelected.has(id)) {
            tempSelected.delete(id)
        } else {
            tempSelected.add(id)
        }

        setSelectedValues(tempSelected)
    }

    return (
        <div className="dropdown-search">
            <Dropdown title={title}>
                <div action="" className="dropdown-search-form">
                    <div className="dropdown-search-scrollable">
                        {!!request && (
                            <div className="dropdown-search-wrap">
                                <input
                                    type="text"
                                    className="dropdown-search-input"
                                    placeholder="Rechercher par compte"
                                    value={search}
                                    onChange={(event) =>
                                        seatSearch(event.target.value)
                                    }
                                />

                                {/* {showAutocomplete ? (
                                <div className="dropdown-search-autocomplete">
                                    <div className="dropdown-search-result">
                                        <span>DHL - Compte 2</span>3
                                    </div>
                                    <div className="dropdown-search-result">
                                        <span>DHL - Compte 2</span>4
                                    </div>
                                    <div className="dropdown-search-result">
                                        <span>DHL - Compte 2</span>5
                                    </div>
                                </div>
                            ) : null} */}
                            </div>
                        )}
                        {options?.map((item, index) => (
                            <Checkbox
                                key={item[valueKey]}
                                label={item[labelKey]}
                                name={item[valueKey]}
                                size="sm"
                                checked={selectedValues.has(item[valueKey])}
                                onChange={() =>
                                    handleCheckboxChange(item[valueKey])
                                }
                            />
                        ))}
                        {get(checkboxes, 'data.list', [])
                            .filter((e) =>
                                e[labelKey]
                                    ?.toLowerCase()
                                    ?.includes(search.toLowerCase())
                            )
                            .map((item, index) => (
                                <Checkbox
                                    key={item.id}
                                    label={item[labelKey]}
                                    name={item[valueKey]}
                                    size="sm"
                                    checked={selectedValues.has(item[valueKey])}
                                    onChange={() =>
                                        handleCheckboxChange(item[valueKey])
                                    }
                                />
                            ))}
                    </div>
                    <Button
                        color="primary"
                        size="sm"
                        skewed
                        onClick={() =>
                            onSave({ $in: Array.from(selectedValues) })
                        }
                    >
                        Valider
                    </Button>
                </div>
            </Dropdown>
        </div>
    )
}

export default DropdownSearch
