import { React } from 'react'
import BarChart from '../barChart'
import './chart.scss'
import get from 'lodash.get'

// const data = [
//     {
//         name: 'Group A',
//         value: 400,
//         value2: 500,
//     },
//     {
//         name: 'Group B',
//         value: 300,
//         value2: 250,
//     },
//     {
//         name: 'Group C',
//         value: 300,
//         value2: 550,
//     },
//     {
//         name: 'Group D',
//         value: 200,
//         value2: 300,
//     },
//     {
//         name: 'Group E',
//         value: 275,
//         value2: 250,
//     },
// ]

// const data2 = [
//     {
//         name: 'Group A',
//         value: 400,
//     },
//     {
//         name: 'Group B',
//         value: 300,
//     },
//     {
//         name: 'Group C',
//         value: 300,
//     },
//     {
//         name: 'Group D',
//         value: 200,
//     },
//     {
//         name: 'Group E',
//         value: 278,
//     },
// ]
const displayDate = (dates) => {
    if (!dates || dates.length < 2) return ''

    let date1 = new Date(dates[0])
    let date2 = new Date(dates[1])

    const display1 = `${date1.getDate()}/${
        date1.getMonth() + 1
    }/${date1.getFullYear()}`
    const display2 = `${date2.getDate()}/${
        date2.getMonth() + 1
    }/${date2.getFullYear()}`

    return `Du ${display1} au ${display2}`
}

const Chart = ({ data, dates }) => {
    return (
        <div className="chart">
            <div className="card full-width">
                {/* <PieChart outerData={data} legendBottom /> */}
                {/* <PieChart outerData={data} innerData={data} /> */}
                {/* <BarChart
                    data={data}
                    valueKey="value"
                    legend="Du 01/01/20 au 31/01/2020"
                /> */}
                <BarChart
                    data={get(data, '', [])}
                    valueKey="value"
                    // comparedValueKey="value2"
                    legend={displayDate(dates)}
                    // comparedLegend="Du 01/01/21 au 31/01/2021"
                />
            </div>
        </div>
    )
}

export default Chart
