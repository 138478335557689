import { useState, useEffect } from 'react'
import PageWrap from '../components/pageWrap'
import Table from '../components/table'
import { getInvoices } from '../network/invoices'
import IconDownload from '../components/iconDownload'
import get from 'lodash.get'
import { useAuth } from '../hooks/useAuth'
import { withRouter } from 'react-router-dom'
import { getAccountsDashboard } from '../network/dashboard'

const Invoices = ({ history }) => {
    const { user, impersonatedUser } = useAuth()

    useEffect(() => {
        const isAdmin =
            user.role === 'super-admin' ||
            user.role === 'admin' ||
            user.role === 'manager'

        if (isAdmin && !impersonatedUser) {
            history.push('/')
        }
    })

    return (
        <PageWrap title="Factures">
            <div className="card full-width no-padding">
                <Table
                    filter={{
                        key: 'q',
                        placeholder:
                            'Rechercher par numéro de facture ou par immatriculation',
                    }}
                    request={getInvoices}
                    columns={columns}
                    size="small"
                />
            </div>
        </PageWrap>
    )
}

const columns = [
    {
        title: 'Numéro de facture',
        key: 'NUMDOC',
        render: (e) => e.NFACGR,
        sorter: true,
    },
    {
        title: 'Immatriculation',
        key: 'IMMAT',
        render: (e) => e.IMMAT,
        sorter: true,
    },
    {
        title: 'Compte',
        key: 'CODECLI',
        render: (e) => get(e, 'account.0.Raiage', ''),
        sorter: true,
        dropdown: {
            title: 'Compte',
            request: getAccountsDashboard,
            labelKey: 'Raiage',
            valueKey: 'Codage',
        },
    },
    {
        title: 'Date',
        key: 'dateDocument',
        render: (e) => displayDate(e.dateDocument),
        // sorter: true,
        date: 'dateDocument',
    },
    {
        title: 'Télécharger',
        key: 'action',
        render: (e, history) => <DownloadInvoice invoice={e} />,
    },
]

const DownloadInvoice = ({ invoice }) => {
    const [loading, setLoading] = useState(false)
    const downloadFile = (id, fileName) => {
        setLoading(true)
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        let filename = `${fileName}.pdf`
        let xmlHttpRequest = new XMLHttpRequest()
        xmlHttpRequest.onreadystatechange = function () {
            var a
            if (
                xmlHttpRequest.readyState === 4 &&
                xmlHttpRequest.status === 200
            ) {
                a = document.createElement('a')
                a.href = window.URL.createObjectURL(xmlHttpRequest.response)
                a.download = filename
                a.style.display = 'none'
                document.body.appendChild(a)
                a.click()
            }

            if (xmlHttpRequest.status) {
                setLoading(false)
            }
        }
        xmlHttpRequest.open(
            'GET',
            `${process.env.REACT_APP_API_URL}/payment-headers/${id}/bill`
        )
        xmlHttpRequest.setRequestHeader('Content-Type', 'application/json')
        xmlHttpRequest.setRequestHeader(
            'Authorization',
            `Bearer ${impersonatedToken || token}`
        )
        xmlHttpRequest.responseType = 'blob'
        xmlHttpRequest.send(JSON.stringify())
    }

    return loading ? (
        <div className="icon-loading invoices-download button-loading" />
    ) : (
        <IconDownload
            request={() =>
                downloadFile(
                    invoice.id,
                    `F_${invoice.CODESOC}_${invoice.CODEPAYS}_${
                        invoice.TYPEDOC
                    }_${invoice.NUMPS.padStart(
                        4,
                        '0'
                    )}_${invoice.NUMDOC.padStart(11, '0')}`
                )
            }
        />
    )
}

const displayDate = (dateStr) => {
    if (!dateStr) return { d: '', h: '' }

    const date = new Date(dateStr)

    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    return `${day < 10 ? '0' : ''}${day}/${
        month < 10 ? '0' : ''
    }${month}/${year}`
}

export default withRouter(Invoices)
