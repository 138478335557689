import './styles/index.scss'

import { BrowserRouter as Router } from 'react-router-dom'
import { useAuth } from './hooks/useAuth'
import Navigation from './components/navigation'
import Login from './pages/Login'
import { setConfiguration } from 'react-grid-system'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { ProvideAuth } from './hooks/useAuth'
import { ProvideData } from './hooks/useData'

setConfiguration({ gutterWidth: 20 })

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            keepPreviousData: false,
        },
    },
})

function App() {
    return (
        <QueryClientProvider
            client={queryClient}
            persistOptions={{
                throttleTime: 0,
            }}
        >
            <ProvideAuth>
                <ProvideData>
                    <AppContent />
                </ProvideData>
            </ProvideAuth>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    )
}

const AppContent = () => {
    const { isAuth } = useAuth()

    return <Router>{isAuth ? <Navigation /> : <Login />}</Router>
}

export default App
