import { React, useState } from 'react'
import cn from 'classnames'
import './input.scss'

const Input = ({
    disabled,
    color,
    error,
    errorMessage,
    errorLong,
    fullwidth,
    icon,
    flat,
    label,
    password,
    placeholder,
    search,
    size,
    type,
    onSearch,
    ...otherProps
}) => {
    const [passwordShown, setPasswordShown] = useState(false)

    const togglePasswordVisiblity = () => setPasswordShown(!passwordShown)

    return (
        <div
            className={cn('input-item', {
                'icon-left': !!icon,
                'input-error': error,
                'long-error': errorLong && error,
                'icon-right': !!password || !!search,
            })}
            {...otherProps}
        >
            {label ? <label className="label">{label}</label> : ''}
            {icon ? <div className={`icon-${icon}`} /> : null}
            {error ? <div className="input-error-icon icon-warning" /> : null}
            <input
                className={cn(
                    'input',
                    {
                        error: error,
                        disabled: disabled,
                        flat: flat,
                        fullwidth: fullwidth,
                    },
                    { [`input-${color}`]: !!color },
                    { [`input-${size}`]: !!size }
                )}
                placeholder={placeholder}
                type={passwordShown ? 'text' : type}
                disabled={disabled}
                {...otherProps}
            />
            {error ? (
                <div className="input-error-message">{errorMessage}</div>
            ) : null}
            {password ? (
                <button
                    className="input-toggle-password"
                    onClick={togglePasswordVisiblity}
                    type="button"
                >
                    {passwordShown ? (
                        <div className="icon-eye-close" />
                    ) : (
                        <div className="icon-eye-open" />
                    )}
                </button>
            ) : null}
            {search ? (
                <button
                    className="icon-search"
                    type="button"
                    onClick={onSearch}
                />
            ) : null}
        </div>
    )
}

export default Input
