import { INSTANCELIST, INSTANCE } from './API'

export const getGroupsDashboard = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter } = queryKey[1]
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        if (filter) {
            filter.user = undefined
            filter.activeToken = undefined
        }

        INSTANCELIST({
            method: 'get',
            url: '/accounts/mine',
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
            params: {
                sort: sort ? JSON.stringify(sort) : null,
                range: range ? JSON.stringify(range) : null,
                filter: filter ? JSON.stringify(filter) : null,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getGroups = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter } = queryKey[1]
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        if (filter) {
            filter.user = undefined
            filter.activeToken = undefined
        }

        INSTANCELIST({
            method: 'get',
            url: '/accounts?range=[0,15]',
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
            params: {
                sort: sort ? JSON.stringify(sort) : null,
                range: range ? JSON.stringify(range) : null,
                filter: filter ? JSON.stringify(filter) : null,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getAccountsDashboard = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter } = queryKey[1]
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        if (filter) {
            filter.user = undefined
            filter.activeToken = undefined
        }

        INSTANCELIST({
            method: 'get',
            url: '/agencies/mine',
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
            params: {
                sort: sort ? JSON.stringify(sort) : null,
                range: range ? JSON.stringify(range) : null,
                filter: filter ? JSON.stringify(filter) : null,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getAccountsAdmin = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter } = queryKey[1]
        const token = localStorage.getItem('token')

        if (filter) {
            filter.user = undefined
            filter.activeToken = undefined
        }

        INSTANCELIST({
            method: 'get',
            url: '/accounts?range=[0,15]',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: sort ? JSON.stringify(sort) : null,
                range: range ? JSON.stringify(range) : null,
                filter: filter ? JSON.stringify(filter) : null,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getManagers = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter } = queryKey[1]
        const token = localStorage.getItem('token')

        if (filter) {
            filter.user = undefined
            filter.activeToken = undefined
        }

        INSTANCELIST({
            method: 'get',
            url: '/managers?range=[0,10]',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: sort ? JSON.stringify(sort) : null,
                range: range ? JSON.stringify(range) : null,
                filter: filter ? JSON.stringify(filter) : null,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getAgencies = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter } = queryKey[1]
        const token = localStorage.getItem('token')
        // const impersonatedToken = localStorage.getItem('impersonatedToken')

        if (filter) {
            filter.user = undefined
            filter.activeToken = undefined
        }

        INSTANCELIST({
            method: 'get',
            url: '/agencies?range=[0,20]',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: sort ? JSON.stringify(sort) : null,
                range: range ? JSON.stringify(range) : null,
                filter: filter ? JSON.stringify(filter) : null,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getMineAgencies = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter } = queryKey[1]
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        if (filter) {
            filter.user = undefined
            filter.activeToken = undefined
        }

        INSTANCELIST({
            method: 'get',
            url: '/agencies?range=[0,20]',
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
            params: {
                sort: sort ? JSON.stringify(sort) : null,
                range: range ? JSON.stringify(range) : null,
                filter: filter ? JSON.stringify(filter) : null,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getAccountsDetail = (id) =>
    new Promise((resolve, reject) => {
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        INSTANCE({
            method: 'get',
            url: `/agencies/${id}`,
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getGroupsDetail = (id) =>
    new Promise((resolve, reject) => {
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        INSTANCE({
            method: 'get',
            url: `/accounts/${id}`,
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })
