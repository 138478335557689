import React, { useState, useEffect } from 'react'
import Checkbox from '../checkbox'
import cn from 'classnames'
import './table.scss'
import Pagination from '../pagination'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import get from 'lodash.get'
import Input from '../../components/input'
import Select from '../../components/select'
import Button from '../../components/button'
import ImportButton from '../../components/buttonInputFile'
import { useModal } from '../../hooks/useModal'
import { useAuth } from '../../hooks/useAuth'
import Modal from '../../components/modal'
import IconRedirection from '../../components/iconRedirection'
import DropdownSearch from '../dropdownSearch'
import { withRouter } from 'react-router'
import Calendar from '../calendar'
import { getAccountsDashboard } from '../../network/dashboard'
import { Link, useLocation } from 'react-router-dom'
import queryString from 'query-string'

// .background-success, .background-error, .background-info, .selected are for table rows

const ModalButton = ({ modalType }) => {
    const { isShowing, toggleModal } = useModal()

    const DisplayModal = ({ type }) => {
        switch (type) {
            case 'import-info':
                return (
                    <Modal
                        isShowing={isShowing}
                        onClose={toggleModal}
                        icon="info-circle"
                        title="A propos du fichier d’import des immatriculations de votre parc"
                    >
                        <p>
                            Pour que l’import de vos immatriculations se déroule
                            parfaitement, quelques règles sont à respecter :
                        </p>
                        <ul>
                            <li>
                                Importez toujours un fichier contenant
                                l'intégralité de votre parc à jour.
                            </li>
                            <br />
                            <li>
                                Pour toutes vos données : aucun caractère
                                spécial n’est autorisé (virgules, points,
                                accents, cédilles, tiret bas, points
                                d’interrogation et d’exclamation, slash,
                                arobase, crochets, parenthèse, dièse, monnaies
                                …). Seuls les tirets (« - ») sont acceptés pour
                                l’immatriculation, la marque et le modèle.
                            </li>
                            <br />
                            <li>
                                Formats de vos immatriculations : AA-123-AA (ce
                                format sans tirets n’est pas accepté), 123AA12,
                                1234AA12, 1234AAA12
                            </li>
                            <br />
                            <li>
                                Date de première mise en circulation : seul le
                                format AAAAMMJJ est accepté (exemple : 20191114,
                                ne pas mettre de « / »).
                            </li>
                            <br />
                            <li>
                                Type : seules les valeurs VP ou VU sont
                                acceptées.
                            </li>
                            <br />
                            <li>
                                Le champ Energie ne peut être qu&#39;une des
                                valeurs suivantes (en deux lettres) : ES (pour
                                essence), DI (pour diesel), GP (pour GPL), EL
                                (pour électrique), FL (pour flexible), MD (pour
                                Micro hybride diesel), ME (pour Micro hybride
                                essence), FD (Pour Full hybride diesel), FE
                                (pour Full hybride essence), RD (pour hybride
                                rechargeable diesel) ou RE (pour hybride
                                rechargeable essence)
                            </li>
                            <br />
                            <li>
                                Dans les données complémentaires (DCP) : aucun
                                caractère spécial n’est autorisé. Les espaces et
                                les tirets « - » sont tolérés.
                            </li>
                        </ul>
                        <Button
                            color="primary"
                            size="large"
                            skewed
                            onClick={toggleModal}
                        >
                            Fermer
                        </Button>
                    </Modal>
                )

            case 'modal-select':
                return (
                    <Modal
                        isShowing={isShowing}
                        onClose={toggleModal}
                        title="Séléctionner le compte de destination :"
                    >
                        <br />
                        <div>
                            <Input
                                color="primary"
                                placeholder="Rechercher par numéro de facture ou par immatriculation"
                                search
                                size="large"
                                fullwidth="true"
                            />
                            <br />
                            <Button color="primary" size="large" skewed>
                                Annuler
                            </Button>
                            <Button color="danger" size="large" skewed>
                                Valider
                            </Button>
                        </div>
                    </Modal>
                )

            default:
                return null
        }
    }

    return (
        <>
            <button
                className="button-icon button-info icon-info-circle"
                onClick={toggleModal}
                style={{ marginRight: 12 }}
            />
            <DisplayModal type={modalType} />
            {/* Modal import info */}
            {/*
                    <Modal
                        isShowing={isShowing}
                        onClose={toggleModal}
                        title="Séléctionner le compte de destination :"
                    >
                        <br />
                        <div>
                            <Input
                                color="primary"
                                placeholder="Rechercher par numéro de facture ou par immatriculation"
                                search
                                size="large"
                                fullwidth="true"
                            />
                            <br />
                            <Button
                                onClick={toggleModal}
                                color="primary"
                                size="large"
                                skewed
                            >
                                Annuler
                            </Button>
                            <Button color="danger" size="large" skewed>
                                Valider
                            </Button>
                        </div>
                    </Modal> */}
            {/* Modal import info error */}
            {/* <Modal
                            isShowing={isShowing}
                            onClose={toggleModal}
                            icon="warning"
                        >
                            <p>
                                <b className="color-error">
                                    Une erreur est survenue lors de l’import de
                                    vos immatriculations
                                </b>
                            </p>
                            <p>
                                <b>Rapport d’import :</b>
                            </p>
                            <ul>
                                <li>Accent présent dans la cellule XX</li>
                            </ul>
                            <br />
                            <br />
                            <Button
                                color="danger"
                                size="large"
                                skewed
                            >
                                importer un nouveau fichier
                            </Button>
                            <Button
                                color="primary"
                                size="large"
                                skewed
                                onClick={toggleModal}
                            >
                                Fermer
                            </Button>
                        </Modal> */}
            {/* Modal import info error */}

            {/* Modal Ajoutfait */}
            {/* <Modal
                            isShowing={isShowing}
                            onClose={toggleModal}
                            icon="checkmark-circle"
                        >
                            <p>
                                <b>L’importation s’est bien déroulée.</b>
                            </p>
                            <br />
                            <Button color="primary" size="large" skewed>
                                Valider et actualiser
                            </Button>
                        </Modal> */}
            {/* Modal Ajoutfait */}

            {/* Modal Suppression */}
            {/* <Modal
                            isShowing={isShowing}
                            onClose={toggleModal}
                            icon="warning"
                        >
                            <p>
                                <b>
                                    Êtes-vous sûr de vouloir supprimer 4
                                    immatriculations ?
                                </b>
                            </p>
                            <br />
                            <Button color="primary" size="large" skewed>
                                Non, annuler
                            </Button>
                            <Button color="danger" size="large" skewed>
                                Oui, supprimer
                            </Button>
                        </Modal> */}
            {/* Modal Suppression */}

            {/* Modal DeplacementCompte */}
        </>
    )
}

const Table = ({
    request,
    columns,
    checkbox = false,
    title,
    size,
    hasPagination = true,
    onSelectedLinesChange,
    filter,
    buttons,
    lineClassname,
    // onLineClick,
    pageSize = 8,
    onLineSelected,
    history,
    defaultFilters,
    params,
}) => {
    const deleteModal = useModal()
    let location = useLocation()
    const { impersonatedUser, isImpersonated, activeToken, user } = useAuth()
    const [selectInputValue, setSelectInputValue] = useState('')
    const [selectStep, setSelectStep] = useState(0)
    const [othersFilters, setOthersFilters] = useState({})
    const selectModal = useModal()
    const queryClient = useQueryClient()
    const [page, setPage] = useState(1)
    const [filterValue, setFilterValue] = useState('')
    const [search, setSearch] = useState(null)
    const [selectedLines, setSelectedLines] = useState(new Set())
    const [sort, setSort] = useState(null)
    const [savedInfo, setSavedInfo] = useState(null)
    const data = useQuery(
        [
            request?.name,
            {
                range: hasPagination
                    ? [page * pageSize - pageSize, page * pageSize - 1]
                    : null,
                unlimited: hasPagination ? null : true,
                sort,
                filter: search,
                activeToken,
                user: isImpersonated ? impersonatedUser.id : user.id,
                ...othersFilters,
                ...params,
            },
        ],
        request
    )

    useEffect(() => {
        const queryParams = queryString.parse(location.search)

        if (!!parseInt(queryParams.page)) {
            setPage(parseInt(queryParams.page))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let queryParams = queryString.parse(location.search)
        queryParams.page = page
        const stringified = queryString.stringify(queryParams)

        history.replace(`?${stringified}`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    useEffect(() => {
        setSearch(defaultFilters)
    }, [defaultFilters])

    // useEffect(() => {
    //     const parsed = queryString.parse(location.search)

    //     if (!!parsed.page) {
    //         setPage(parsed.page)
    //     }
    // }, [])

    // useEffect(() => {
    //     console.log('page', page)
    //     history.replace(`?page=${page}`)
    // }, [page])

    const deletion = useMutation(
        () =>
            get(
                buttons,
                `${savedInfo}.request`,
                null
            )(
                get(buttons, `${savedInfo}.noImmat`)
                    ? selectedLines
                    : getImmatsFromIds(Array.from(selectedLines)),
                data
            ),
        {
            onSettled: () => {
                setSelectedLines(new Set())
                onSelectedLinesChange([])
                queryClient.invalidateQueries(request?.name)
                deleteModal.toggleModal()
            },
        }
    )

    const getImmatsFromIds = (list) => {
        const immatList = []

        for (const item of list) {
            const immat = get(data, 'data.list', []).find(
                (e) => e.id === item
            )?.IMMAT

            if (immat) immatList.push(immat)
        }

        return immatList
    }

    const transfert = useMutation(
        () =>
            get(
                buttons,
                `${savedInfo}.request`,
                null
            )(selectInputValue, getImmatsFromIds(Array.from(selectedLines))),
        {
            onSuccess: () => {
                setSelectStep(2)
            },
        }
    )

    const updateSelectedLines = (id) => {
        if (!id) return

        let newSelectedLines = new Set(selectedLines)

        if (newSelectedLines.has(id)) {
            newSelectedLines.delete(id)
        } else {
            newSelectedLines.add(id)
        }
        setSelectedLines(newSelectedLines)

        if (onSelectedLinesChange)
            onSelectedLinesChange(Array.from(newSelectedLines))
    }

    const handleAsc = (key) => {
        if (sort && sort[0] === key && sort[1] === 'ASC') {
            setSort(null)
            return
        }

        setSort([key, 'ASC'])
    }
    const handleDsc = (key) => {
        if (sort && sort[0] === key && sort[1] === 'DESC') {
            setSort(null)
            return
        }

        setSort([key, 'DESC'])
    }

    const isSortSelected = (key, type) => {
        if (!sort) return false

        if (key === sort[0] && type === sort[1]) {
            return true
        }
        return false
    }

    const onSearch = () => {
        setSelectedLines(new Set())
        setSearch((e) => ({ ...e, [filter.key]: filterValue || null }))
    }

    const handleKeyPress = (event) =>
        event.key === 'Enter' ? onSearch() : null

    const onClick = (btn, index) => {
        switch (btn.action) {
            case 'callback':
                btn.onClick()
                break
            case 'modal-action':
                setSavedInfo(index)
                deleteModal.toggleModal()
                break
            case 'modal-select':
                setSavedInfo(index)
                selectModal.toggleModal()
                break

            default:
                break
        }
    }

    const setDates = (dates, key) => {
        setSelectedLines(new Set())

        let newDates = { $and: undefined }

        if (dates?.length === 2) {
            newDates = {
                $and: [
                    { [key]: { $gt: dates[0] } },
                    { [key]: { $lt: dates[1] } },
                ],
            }
        }

        setSearch((e) => ({
            ...e,
            ...newDates,

            // [key]:
            //     !dates || dates.length !== 2
            //         ? null
            //         : {
            //               $and: [
            //                   { date: { $gt: dates[0] } },
            //                   { date: { $lt: dates[1] } },
            //               ],
            //           },
        }))

        setPage(1)
    }

    const handleDropdownSave = (key, values, noFilter) => {
        if (noFilter) {
            setOthersFilters((e) => ({ ...e, [key]: values?.$in }))
            setSelectedLines(new Set())
            return
        }

        let tempFilters = { ...search }
        setSelectedLines(new Set())

        if (!values?.$in || !values?.$in.length) {
            delete tempFilters[key]
            setSearch(tempFilters)
            return
        }

        tempFilters[key] = values
        setSearch(tempFilters)
        setPage(1)
    }

    return (
        <>
            {filter || buttons ? (
                <div className="table-header">
                    <div className="full-width filters-wrap">
                        {/* <Row> */}
                        {filter && (
                            <div style={{ flex: 1 }}>
                                {/* <Col lg={5}> */}
                                <div className="table-header-search">
                                    <Input
                                        name="search"
                                        color="primary"
                                        placeholder={filter.placeholder}
                                        value={filterValue}
                                        onChange={(e) => {
                                            setPage(1)
                                            setFilterValue(e.target.value)
                                            setSelectedLines(new Set())
                                        }}
                                        onKeyPress={handleKeyPress}
                                        search
                                        size="large"
                                        fullwidth="true"
                                        onSearch={onSearch}
                                    />
                                </div>
                                {/* </Col> */}
                            </div>
                        )}

                        {buttons && (
                            <div style={{ flex: 1 }}>
                                {/* <Col lg={5} offset={{ lg: 1 }}> */}
                                <div className="table-header-buttons">
                                    {buttons.map((button, index) =>
                                        button.type === 'modal' ? (
                                            <ModalButton
                                                key={index}
                                                modalType={button.modalType}
                                            />
                                        ) : button.type === 'import' ? (
                                            <ImportButton
                                                key={index}
                                                name={request.name}
                                                color={button.color}
                                                request={button.request}
                                                size="large"
                                                skewed
                                            >
                                                {button.label}
                                            </ImportButton>
                                        ) : button.type === 'link' ? (
                                            <Link key={index} to={button.link}>
                                                <Button
                                                    icon={button.icon}
                                                    color={button.color}
                                                    size="large"
                                                    skewed
                                                >
                                                    {button.label}
                                                </Button>
                                            </Link>
                                        ) : (
                                            <Button
                                                key={index}
                                                icon={button.icon}
                                                color={button.color}
                                                size="large"
                                                onClick={() =>
                                                    onClick(button, index)
                                                }
                                                skewed
                                            >
                                                {button.label}
                                            </Button>
                                        )
                                    )}
                                </div>
                                {/* </Col> */}
                            </div>
                        )}
                        {/* </Row> */}
                    </div>
                </div>
            ) : null}
            <div className={cn('table-wrapper')}>
                <table className={cn('table', { [`table-${size}`]: !!size })}>
                    {title ? <caption>{title}</caption> : null}
                    <thead>
                        <tr>
                            {checkbox ? <th className="check" /> : null}
                            {columns?.map((col) => (
                                <th key={col.key} style={{ width: col.width }}>
                                    {col.dropdown ? (
                                        <DropdownSearch
                                            // keyValue={col.key}
                                            {...col.dropdown}
                                            // defaultFilters={defaultFilters}
                                            filters={search && search[col.key]}
                                            onSave={(values) =>
                                                handleDropdownSave(
                                                    col.key,
                                                    values,
                                                    col.dropdown.noFilter
                                                )
                                            }
                                        />
                                    ) : (
                                        <div
                                            className="table-head-wrap sort"
                                            onClick={() => {
                                                if (!col.sorter) return

                                                setSelectedLines(new Set())
                                                if (
                                                    !isSortSelected(
                                                        col.key,
                                                        'ASC'
                                                    ) &&
                                                    !isSortSelected(
                                                        col.key,
                                                        'DESC'
                                                    )
                                                ) {
                                                    handleAsc(col.key)
                                                } else if (
                                                    isSortSelected(
                                                        col.key,
                                                        'ASC'
                                                    )
                                                ) {
                                                    handleDsc(col.key)
                                                } else {
                                                    handleDsc(col.key)
                                                }
                                                setPage(1)
                                            }}
                                        >
                                            <span className="table-head-title">
                                                {col.title}
                                            </span>
                                            {col.sorter && (
                                                <div className="table-head-sort">
                                                    <button
                                                        // onClick={() => {
                                                        //     setSelectedLines(
                                                        //         new Set()
                                                        //     )
                                                        //     if (
                                                        //         !isSortSelected(
                                                        //             col.key,
                                                        //             'ASC'
                                                        //         ) &&
                                                        //         !isSortSelected(
                                                        //             col.key,
                                                        //             'DESC'
                                                        //         )
                                                        //     ) {
                                                        //         handleAsc(
                                                        //             col.key
                                                        //         )
                                                        //     } else if (
                                                        //         isSortSelected(
                                                        //             col.key,
                                                        //             'ASC'
                                                        //         )
                                                        //     ) {
                                                        //         handleDsc(
                                                        //             col.key
                                                        //         )
                                                        //     } else {
                                                        //         handleDsc(
                                                        //             col.key
                                                        //         )
                                                        //     }
                                                        // }}
                                                        className={`icon-triangle-up${
                                                            isSortSelected(
                                                                col.key,
                                                                'ASC'
                                                            )
                                                                ? ' selected'
                                                                : ''
                                                        }`}
                                                    />
                                                    <button
                                                        // onClick={() => {
                                                        //     setSelectedLines(
                                                        //         new Set()
                                                        //     )
                                                        //     if (
                                                        //         !isSortSelected(
                                                        //             col.key,
                                                        //             'ASC'
                                                        //         ) &&
                                                        //         !isSortSelected(
                                                        //             col.key,
                                                        //             'DESC'
                                                        //         )
                                                        //     ) {
                                                        //         handleAsc(
                                                        //             col.key
                                                        //         )
                                                        //     } else if (
                                                        //         isSortSelected(
                                                        //             col.key,
                                                        //             'ASC'
                                                        //         )
                                                        //     ) {
                                                        //         handleDsc(
                                                        //             col.key
                                                        //         )
                                                        //     } else {
                                                        //         handleDsc(
                                                        //             col.key
                                                        //         )
                                                        //     }
                                                        // }}
                                                        className={`icon-triangle-down${
                                                            isSortSelected(
                                                                col.key,
                                                                'DESC'
                                                            )
                                                                ? ' selected'
                                                                : ''
                                                        }`}
                                                    />
                                                </div>
                                            )}
                                            {col.date && (
                                                <div className="table-head-sort">
                                                    <Calendar
                                                        onChange={(d) =>
                                                            setDates(
                                                                d,
                                                                col.date
                                                            )
                                                        }
                                                    >
                                                        <button
                                                            onClick={() => {}}
                                                            className={`icon-triangle-down${
                                                                // isSortSelected(
                                                                //     col.key,
                                                                //     'DESC'
                                                                // )
                                                                //     ? ' selected'
                                                                //     :
                                                                ''
                                                            }`}
                                                        />
                                                    </Calendar>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </th>
                            ))}
                            {onLineSelected ? (
                                <th style={{ width: 100 }} />
                            ) : null}
                        </tr>
                    </thead>
                    <tbody>
                        {get(data, 'data.list', []).map((rowData, index) => (
                            <tr
                                key={index}
                                className={
                                    // lineClassname && lineClassname(rowData)
                                    //     ? lineClassname(rowData)
                                    //     : selectedLines.has(rowData.id)
                                    //     ? 'selected'
                                    //     : ''

                                    lineClassname && lineClassname(rowData)
                                        ? lineClassname(rowData)
                                        : selectedLines.has(rowData.id)
                                        ? 'selected'
                                        : rowData.disabled
                                        ? 'background-error'
                                        : ''
                                }
                                // onClick={(e) => {
                                //     if (
                                //         onLineClick &&
                                //         !e.target.className.includes('checkbox')
                                //     )
                                //         onLineClick(rowData, history)
                                // }}
                            >
                                {checkbox ? (
                                    <td className="check">
                                        <Checkbox
                                            value={rowData.id}
                                            checked={selectedLines.has(
                                                rowData.id
                                            )}
                                            onChange={() => {
                                                updateSelectedLines(rowData.id)
                                            }}
                                        />
                                    </td>
                                ) : null}
                                {columns.map((col, index) => (
                                    <td
                                        key={col.key}
                                        style={{ width: col.width }}
                                    >
                                        {col.render(rowData, history)}
                                    </td>
                                ))}
                                {onLineSelected ? (
                                    <td style={{ width: 100 }}>
                                        {rowData.id && (
                                            <IconRedirection
                                                onClick={() =>
                                                    onLineSelected(rowData)
                                                }
                                            />
                                        )}
                                    </td>
                                ) : null}
                            </tr>
                        ))}
                    </tbody>
                </table>

                {data.isLoading && (
                    <div className="empty-data">
                        <div className="icon-loading button-loading" />
                        <br />
                        <span>{'Chargement...'}</span>
                    </div>
                )}
                {!data.isLoading &&
                !data?.data?.list?.length &&
                !data.isError ? (
                    <div className="empty-data">
                        <span>{'Aucune donnée'}</span>
                    </div>
                ) : null}
                {data.isError ? (
                    <>
                        <div className="empty-data">
                            <div>Une erreur est survenue</div>

                            <Button
                                onClick={data.refetch}
                                color="primary"
                                size="sm"
                                skewed
                            >
                                Retry
                            </Button>
                        </div>
                    </>
                ) : null}
            </div>
            {/*{data.isLoading && (*/}
            {/*    <div className="empty-data">*/}
            {/*        <span>{'Chargement...'}</span>*/}
            {/*    </div>*/}
            {/*)}*/}
            {/*{!data.isLoading && !data?.data?.list?.length && !data.isError ? (*/}
            {/*    <div className="empty-data">*/}
            {/*        <span>{'Aucune donnees'}</span>*/}
            {/*    </div>*/}
            {/*) : null}*/}
            {/*{data.isError ? (*/}
            {/*    <>*/}
            {/*        <div className="empty-data">*/}
            {/*            <div>Un probleme est survenue</div>*/}

            {/*            <Button*/}
            {/*                onClick={data.refetch}*/}
            {/*                color="primary"*/}
            {/*                size="sm"*/}
            {/*                skewed*/}
            {/*            >*/}
            {/*                Retry*/}
            {/*            </Button>*/}
            {/*        </div>*/}
            {/*    </>*/}
            {/*) : null}*/}
            {hasPagination && !data.isError && !data.isLoading ? (
                <Pagination
                    page={page}
                    onChange={(e) => {
                        setSelectedLines(new Set())
                        setPage(e + 1)
                    }}
                    pageSize={pageSize}
                    totalPage={get(data, 'data.range', 0)}
                />
            ) : null}

            <Modal
                isShowing={deleteModal.isShowing}
                onClose={deleteModal.toggleModal}
                icon={get(buttons, `${savedInfo}.modalIcon`, '')}
            >
                <p>
                    {get(
                        buttons,
                        `${savedInfo}.contentText`,
                        () => null
                    )(Array.from(selectedLines), data?.data?.list)}
                </p>
                <br />
                <Button
                    disabled={deletion.isLoading}
                    onClick={deleteModal.toggleModal}
                    color="primary"
                    size="large"
                    skewed
                >
                    {get(buttons, `${savedInfo}.cancelLabel`, '')}
                </Button>
                <Button
                    isLoading={deletion.isLoading}
                    onClick={deletion.mutate}
                    color="danger"
                    size="large"
                    skewed
                >
                    {get(buttons, `${savedInfo}.actionLabel`, '')}
                </Button>
            </Modal>

            <Modal
                isShowing={selectModal.isShowing}
                onClose={() => {
                    setSelectStep(0)
                    setSelectInputValue('')
                    selectModal.toggleModal()
                }}
                // title="Séléctionner le compte de destination :"
            >
                <br />
                {selectStep === 0 && (
                    <div>
                        {/* <Input
                            color="primary"
                            placeholder="Rechercher par numéro de facture ou par immatriculationiiii"
                            search
                            value={selectInputValue}
                            onChange={(e) =>
                                setSelectInputValue(e.target.value)
                            }
                            size="large"
                            fullwidth="true"
                        /> */}
                        {selectModal.isShowing && (
                            <Select
                                fullwidth="true"
                                request={getAccountsDashboard}
                                keyData="Codage"
                                label="Raiage"
                                onChange={(e) => setSelectInputValue(e)}
                                sort={(a, b) => {
                                    if (a.Codage > b.Codage) {
                                        return -1
                                    }
                                    if (b.Codage > a.Codage) {
                                        return 1
                                    }
                                    return 0
                                }}
                                display={(e) => `${e.Codage} - ${e.Raiage}`}
                            />
                        )}
                        <br />
                        <Button
                            disabled={transfert.isLoading}
                            onClick={() => {
                                setSelectStep(0)
                                setSelectInputValue('')
                                selectModal.toggleModal()
                            }}
                            color="primary"
                            size="large"
                            skewed
                        >
                            Annuler
                        </Button>
                        <Button
                            disabled={selectInputValue === ''}
                            isLoading={transfert.isLoading}
                            onClick={() => setSelectStep(1)}
                            color="danger"
                            size="large"
                            skewed
                        >
                            Valider
                        </Button>
                    </div>
                )}

                {selectStep === 1 && (
                    <>
                        <p>
                            <b>
                                {`Êtes-vous sûr de vouloir supprimer ${selectedLines.size}
                                immatriculations ?`}
                            </b>
                        </p>
                        <p>
                            En déplaçant vos immatriculations, vous supprimez
                            les immatriculations du compte d’origine et créez de
                            nouvelles immatriculations dans le nouveau compte.
                            Cette action en peut pas être annulée si vous
                            validez le déplacement de compte.
                        </p>
                        <br />
                        <Button
                            onClick={() => {
                                setSelectStep(0)
                                setSelectInputValue('')
                                selectModal.toggleModal()
                            }}
                            color="primary"
                            size="large"
                            skewed
                        >
                            Annuler
                        </Button>
                        <Button
                            onClick={() => transfert.mutate()}
                            color="danger"
                            size="large"
                            skewed
                        >
                            Valider
                        </Button>
                    </>
                )}

                {selectStep === 2 && (
                    <>
                        <p>
                            <b>{`Vos immatriculations ont bien été déplacées vers
                                le compte ${selectInputValue}.`}</b>
                        </p>
                        <br />
                        <ul>
                            {getImmatsFromIds(Array.from(selectedLines)).map(
                                (e) => (
                                    <li key={e}>{e}</li>
                                )
                            )}
                        </ul>
                        <br />
                        <p>
                            <b>
                                {`Nous vous invitons à vérifier les informations
                                des immatriculations déplacées dans le compte
                                ${selectInputValue}.`}
                            </b>
                        </p>
                        <br />
                        <Button
                            onClick={() => {
                                queryClient.invalidateQueries(request?.name)
                                setSelectedLines(new Set())
                                onSelectedLinesChange([])
                                setSelectStep(0)
                                setSelectInputValue('')
                                selectModal.toggleModal()
                            }}
                            color="primary"
                            size="large"
                            skewed
                        >
                            Valider et actualiser
                        </Button>
                    </>
                )}
                {/*
                 */}
            </Modal>
        </>
    )
}

export default withRouter(Table)
