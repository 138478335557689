import { React } from 'react'
import './quick-access.scss'

const QuickAccess = ({ active, onClick }) => {
    return (
        <div className="quick-access">
            <ul className="quick-access-list card">
                <li
                    className={`quick-access-item${
                        active === 'tire' ? ' active' : ''
                    }`}
                >
                    <button
                        onClick={() => onClick('tire')}
                        className="quick-access-link"
                    >
                        <div className="quick-access-icon icon-pneumatics"></div>
                        <div className="quick-access-title">Pneumatiques</div>
                    </button>
                </li>
                <li
                    className={`quick-access-item${
                        active === 'revision' ? ' active' : ''
                    }`}
                >
                    <button
                        onClick={() => onClick('revision')}
                        className="quick-access-link"
                    >
                        <div className="quick-access-icon icon-revision"></div>
                        <div className="quick-access-title">Révision</div>
                    </button>
                </li>
                <li
                    className={`quick-access-item${
                        active === 'braking' ? ' active' : ''
                    }`}
                >
                    <button
                        onClick={() => onClick('braking')}
                        className="quick-access-link"
                    >
                        <div className="quick-access-icon icon-brakes"></div>
                        <div className="quick-access-title">Freinage</div>
                    </button>
                </li>
                <li
                    className={`quick-access-item${
                        active === 'glazing' ? ' active' : ''
                    }`}
                >
                    <button
                        onClick={() => onClick('glazing')}
                        className="quick-access-link"
                    >
                        <div className="quick-access-icon icon-windscreen">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                            <span className="path6" />
                        </div>
                        <div className="quick-access-title">Vitrage</div>
                    </button>
                </li>
                <li
                    className={`quick-access-item${
                        active === 'technical' ? ' active' : ''
                    }`}
                >
                    <button
                        onClick={() => onClick('technical')}
                        className="quick-access-link"
                    >
                        <div className="quick-access-icon icon-instruments"></div>
                        <div className="quick-access-title">Technique</div>
                    </button>
                </li>
            </ul>
        </div>
    )
}

export default QuickAccess
