import { React } from 'react'
import cn from 'classnames'
import './checkbox.scss'

const Checkbox = ({ color, label, name, size, value, ...otherProps }) => {
    return (
        <label
            className={cn(
                'checkbox',
                { [`checkbox-${size}`]: !!size },
                { [`checkbox-${color}`]: !!color }
            )}
        >
            <input
                className={cn('checkbox-input')}
                name={name}
                type="checkbox"
                checked={value}
                {...otherProps}
            />
            <span className="checkbox-box icon-checkmark" />
            <span className="checkbox-label">{label}</span>
        </label>
    )
}

export default Checkbox
