import { useEffect } from 'react'
import PageWrap from '../components/pageWrap'
import Table from '../components/table'
import { getGuarding } from '../network/guarding'
import { useQuery } from 'react-query'
import { getTires, downloadFile } from '../network/guarding'
import get from 'lodash.get'
import { useAuth } from '../hooks/useAuth'
import { withRouter } from 'react-router-dom'

import './Guarding.scss'
import moment from 'moment'

const Guarding = ({ history }) => {
    // const [selectedLines, setSelectedLines] = useState([])
    const { impersonatedUser, isImpersonated, activeToken, user } = useAuth()

    useEffect(() => {
        const isAdmin =
            user.role === 'super-admin' ||
            user.role === 'admin' ||
            user.role === 'manager'

        if (isAdmin && !impersonatedUser) {
            history.push('/')
        }
    })

    const tires = useQuery(
        [
            'tires',
            {
                activeToken,
                user: isImpersonated ? impersonatedUser.id : user.id,
            },
        ],
        getTires
    )

    return (
        <PageWrap title="Gardiennage">
            <div className="content card full-width no-padding">
                <div className="filter-box">
                    <div className="comparison-card card">
                        <div className="comparison-title">
                            Nombre de pneus en gardiennage
                        </div>
                        <div className="comparison-number">
                            {tires.isLoading ? (
                                <div className="icon-loading button-loading" />
                            ) : (
                                Math.round(
                                    get(tires, 'data.list.0.totalAmount', 0)
                                )
                            )}
                        </div>
                    </div>
                </div>

                <Table
                    filter={{
                        key: 'q',
                        placeholder: 'Rechercher par immatriculation',
                    }}
                    buttons={[
                        {
                            color: 'primary',
                            action: 'callback',
                            label: 'Exporter',
                            // icon: 'export',
                            onClick: downloadFile,
                        },
                    ]}
                    // checkbox
                    lineClassname={(e) => {
                        switch (e.status) {
                            case 'active':
                                return 'background-error'
                            case 'r':
                                return 'background-success'
                            case 'rt':
                                return 'background-info'
                            default:
                                return ''
                        }
                    }}
                    // onSelectedLinesChange={setSelectedLines}
                    request={getGuarding}
                    columns={columns}
                    size="small"
                />
            </div>
        </PageWrap>
    )
}

const columns = [
    {
        title: 'Immatriculation',
        key: 'IMMAT',
        render: (e) => e.IMMAT,
        sorter: true,
    },
    {
        title: 'Quantité',
        key: 'NBCONS',
        render: (e) => e.NBCONS,
        sorter: true,
    },
    {
        title: 'Début',
        key: 'DATEDEB',
        render: (e) =>
            moment(e.DATEDEB, 'AAAA-MM-DD').isValid()
                ? moment(e.DATEDEB).format('DD/MM/YYYY')
                : e.DATEDEB,
        sorter: true,
    },
    {
        title: 'Fin',
        key: 'DATEFIN',
        render: (e) =>
            moment(e.DATEFIN, 'AAAA-MM-DD').isValid()
                ? moment(e.DATEFIN).format('DD/MM/YYYY')
                : e.DATEFIN,
        sorter: true,
    },
    {
        title: 'Centre',
        key: 'CODELIEU',
        render: (e) => `${e.center?.Nom || ''} ${e.center?.Ville || ''}`,
        sorter: true,
    },
    {
        title: 'Saison',
        key: 'SAISON',
        render: (e) => (e.SAISON === 'E' ? 'Été' : 'Hiver'),
        sorter: true,
    },
]

export default withRouter(Guarding)
