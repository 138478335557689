import { INSTANCELIST, INSTANCE } from './API'

export const getUsers = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter } = queryKey[1]
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        if (filter) {
            filter.user = undefined
            filter.activeToken = undefined
        }

        INSTANCELIST({
            method: 'get',
            // url: '/contacts/info',
            // url: '/users',
            url: '/users/info',
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
            params: {
                sort: sort ? JSON.stringify(sort) : null,
                range: range ? JSON.stringify(range) : null,
                filter: filter ? JSON.stringify(filter) : null,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getContacts = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter } = queryKey[1]
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        if (filter) {
            filter.user = undefined
            filter.activeToken = undefined
        }

        INSTANCELIST({
            method: 'get',
            url: '/contacts',
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
            params: {
                sort: sort ? JSON.stringify(sort) : null,
                range: range ? JSON.stringify(range) : null,
                filter: filter ? JSON.stringify(filter) : null,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getUsersAdmin = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, ...rest } = queryKey[1]
        const token = localStorage.getItem('token')
        // const impersonatedToken = localStorage.getItem('impersonatedToken')

        if (filter) {
            filter.user = undefined
            filter.activeToken = undefined
        }
        if (rest.activeToken) {
            rest.activeToken = undefined
        }

        INSTANCELIST({
            method: 'get',
            // url: '/contacts/info',
            // url: '/users',
            url: '/users',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: sort ? JSON.stringify(sort) : null,
                range: range ? JSON.stringify(range) : null,
                filter: filter ? JSON.stringify(filter) : null,
                ...rest,
                user: undefined,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getUserInfo = (id) =>
    new Promise((resolve, reject) => {
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        INSTANCE({
            method: 'get',
            url: '/users/' + id,
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const createUser = (data, url = '') =>
    new Promise((resolve, reject) => {
        const token = localStorage.getItem('token')
        // const impersonatedToken = localStorage.getItem('impersonatedToken')

        INSTANCE({
            method: 'post',
            url: `/users/create${url}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data,
        })
            .then(resolve)
            .catch(reject)
    })

export const updateUser = (data, id) =>
    new Promise((resolve, reject) => {
        const token = localStorage.getItem('token')
        // const impersonatedToken = localStorage.getItem('impersonatedToken')

        INSTANCE({
            method: 'put',
            url: `/users/${id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data,
        })
            .then(resolve)
            .catch(reject)
    })

const desactiveUser = (id) =>
    new Promise((resolve, reject) => {
        const token = localStorage.getItem('token')
        // const impersonatedToken = localStorage.getItem('impersonatedToken')

        INSTANCE({
            method: 'put',
            url: `/users/${id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: { disabled: true },
        })
            .then(resolve)
            .catch(reject)
    })

export const desactiveMultiple = (listUser, data) =>
    new Promise(async (resolve, reject) => {
        for (const user of listUser) {
            await desactiveUser(user).then().catch(reject)
        }

        resolve()
    })

const deleteUser = (id) =>
    new Promise((resolve, reject) => {
        console.log('listUser deleteUser', id)
        const token = localStorage.getItem('token')
        // const impersonatedToken = localStorage.getItem('impersonatedToken')

        INSTANCE({
            method: 'delete',
            url: `/users/${id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const deleteMultiple = (listUser) =>
    new Promise(async (resolve, reject) => {
        console.log('listUser', listUser)

        for (const user of listUser) {
            await deleteUser(user).then().catch(reject)
        }

        resolve()
    })
