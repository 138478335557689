import React, { useState, useMemo } from 'react'
import Button from '../components/button'
import Input from '../components/input'
import Modal from '../components/modal'
import Select from '../components/select'
import { Container, Row, Col, Visible } from 'react-grid-system'
import PageWrap from '../components/pageWrap'
import { useParams, withRouter, useLocation } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'
import { getUserInfo, createUser, updateUser } from '../network/users'
import { useFormik } from 'formik'
import {
    getAccountsDashboard,
    getAccountsAdmin,
    getManagers,
} from '../network/dashboard'
import { getContacts } from '../network/users'
import get from 'lodash.get'
import queryString from 'query-string'
import moment from 'moment'

import { useAuth } from '../hooks/useAuth'

const UserProfile = ({ history }) => {
    let { id } = useParams()
    const {
        user: { role },
        impersonatedUser,
    } = useAuth()

    let location = useLocation()
    const adminMode = queryString.parse(location.search).type === 'admin'

    const isCreate = id === 'create'
    const [isModalOpen, setIsModalOpen] = useState(null)
    const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false)

    const mutation = useMutation(
        (values) => {
            if (isCreate) {
                if (values.role === 'manager') {
                    return createUser(values, '/manager')
                }
                if (values.role === 'group' && adminMode && role === 'admin') {
                    return createUser(values, '/group')
                }
                return createUser(values)
            } else {
                return updateUser(
                    { ...values, disabled: values.disabled === 'true' },
                    id
                )
            }
        },
        {
            onSuccess: () => setIsModalSuccessOpen(true),
            onError: () => setIsModalOpen(true),
        }
    )

    const formik = useFormik({
        initialValues: {
            disabled: 'false',
            Codage: '',
            role: '',

            firstName: '',
            lastName: '',
            email: '',
        },
        validate: (values) => {
            const errors = {}

            // if (values.disabled === true || values.disabled === false) {
            //     errors.disabled = 'Status requis'
            // }
            if (!values.Codage) {
                errors.Codage = 'Compte requis'
            }
            if (!values.role) {
                errors.role = 'Role requis'
            }

            if (!values.firstName) {
                errors.firstName = 'Prenom requis'
            }
            if (!values.lastName) {
                errors.lastName = 'Nom requis'
            }
            if (!values.email) {
                errors.email = 'Addresse email requise'
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
            ) {
                errors.email = 'Addresse email invalide'
            }

            return errors
        },
        onSubmit: (values) => {
            mutation.mutate(values)
        },
    })

    const creatingAdmin = formik.values.role === 'admin'
    const creatingAgency = formik.values.role === 'agency'
    const creatingManager = formik.values.role === 'manager'
    const creatingGroup = formik.values.role === 'group'

    const asAdmin = adminMode && role === 'admin'
    // const asManager = adminMode && role === 'manager'
    // const asAgency =
    //     !adminMode && (role === 'agency' || impersonatedUser?.role === 'agency')
    const asGroup =
        !adminMode && (role === 'group' || impersonatedUser?.role === 'group')

    const handleSubmit = (values) => {
        // if (creatingGroup && asGroup) {
        //     console.log('impersonatedUser', impersonatedUser)
        //     values.Maictt = values.email
        //     values.Codcpt = impersonatedUser.Codcpt || Codcpt
        // }

        mutation.mutate(values)
    }

    const user = useQuery([getUserInfo?.name, id], () => getUserInfo(id), {
        enabled: !isCreate,
        onSuccess: (data) => {
            formik.setValues({
                disabled: get(data, 'disabled', false) ? 'true' : 'false',
                Codage: get(data, 'Codage', ''),
                role: get(data, 'roles.0.role', ''),

                firstName: get(data, 'firstName', ''),
                lastName: get(data, 'lastName', ''),
                email: get(data, 'email', ''),
            })
        },
        refetchOnWindowFocus: false,
    })
    // group
    // c-agence / group
    // x-agence
    // f-nom / prenom / email / codeage ?
    // x-group
    // f-nom / prenom / email

    // agence
    // c-agence
    // f-nom / prenom / email

    // commerciaux
    // c-group
    // x-group
    // f-account(Codcpt) > contact(email)

    // admin
    // c-group / commer / admin
    // x-commer { Matric }
    // f-commer(Matric)
    // x-group { Codcpt, Maictt }
    // f-account(Codcpt) > contact(email)

    //accounts/mine // /contacts??Codcpt=0000002042
    // x-admin
    // f-nom / prenom / email

    const options = useMemo(() => {
        const imperRole = impersonatedUser?.role

        if (adminMode) {
            switch (role) {
                case 'admin':
                case 'super-admin':
                    return [
                        { value: 'admin', label: 'Administrateur' },
                        { value: 'group', label: 'Group' },
                        { value: 'manager', label: 'Commercial' },
                    ]
                case 'manager':
                    return [{ value: 'group', label: 'Group' }]
                case 'group':
                    return [
                        { value: 'agency', label: 'Agence' },
                        { value: 'group', label: 'Group' },
                    ]
                default:
                    return [{ value: 'agency', label: 'Agence' }]
            }
        } else {
            switch (imperRole) {
                case 'admin':
                case 'super-admin':
                    return [
                        { value: 'admin', label: 'Administrateur' },
                        { value: 'group', label: 'Group' },
                        { value: 'manager', label: 'Commercial' },
                    ]
                case 'manager':
                    return [{ value: 'group', label: 'Group' }]
                case 'group':
                    return [
                        { value: 'agency', label: 'Agence' },
                        { value: 'group', label: 'Group' },
                    ]
                default:
                    return [{ value: 'agency', label: 'Agence' }]
            }
        }
    }, [role, impersonatedUser?.role, adminMode])

    return (
        <>
            <Modal
                icon={'warning'}
                isShowing={isModalOpen}
                onClose={() => setIsModalOpen(null)}
                title={`Une erreur est survenue en voulant ${
                    isCreate ? 'cree' : 'modifier'
                } l'utilisateur.`}
            >
                <Button
                    onClick={() => setIsModalOpen(null)}
                    color={'danger'}
                    skewed
                    style={{ marginRight: 24 }}
                >
                    Fermer
                </Button>
            </Modal>

            <Modal
                isShowing={isModalSuccessOpen}
                onClose={() => {
                    setIsModalSuccessOpen(null)
                    history.push('/users')
                }}
                title={`Utilisateur crée avec succès.`}
            >
                <Button
                    color="primary"
                    onClick={() => {
                        setIsModalSuccessOpen(null)
                        history.push('/users')
                    }}
                    skewed
                    style={{ marginRight: 24 }}
                >
                    Fermer
                </Button>
            </Modal>

            <PageWrap
                onBack={() => history.push('/users')}
                title="Gestion des utilisateurs"
            >
                <Container>
                    <h2>Profil utilisateur</h2>

                    <div className="card form" style={{ width: '100%' }}>
                        <form onSubmit={formik.handleSubmit}>
                            <Row>
                                <Col xs={12}>
                                    <h3>Utilisateur</h3>
                                </Col>
                                <Col lg={6} xl={5} xxl={3}>
                                    <div className="label label-inline">
                                        Rôle
                                    </div>
                                </Col>
                                <Col lg={6} xl={5} xxl={3}>
                                    <Select
                                        option={options}
                                        disabled={!isCreate}
                                        value={formik.values.role}
                                        keyData="value"
                                        label="label"
                                        onChange={(value) => {
                                            formik.handleChange({
                                                target: {
                                                    name: 'role',
                                                    value: value,
                                                },
                                            })
                                        }}
                                        fullwidth="true"
                                        error={formik.errors.role}
                                        errorMessage={formik.errors.role}
                                    />
                                </Col>
                                <Visible xl xxl>
                                    <Col xl={2} xxl={5}></Col>
                                </Visible>
                                {/*  */}
                                {!isCreate && creatingGroup && (
                                    <>
                                        <Col lg={6} xl={5} xxl={3}>
                                            <div className="label label-inline">
                                                Compte
                                            </div>
                                        </Col>
                                        <Col lg={6} xl={5} xxl={3}>
                                            <Input
                                                type="text"
                                                placeholder="Nom"
                                                disabled={!isCreate}
                                                fullwidth="true"
                                                value={get(
                                                    user,
                                                    'data.roles.0.Raicpt',
                                                    ''
                                                )}
                                            />
                                        </Col>
                                        <Visible xl xxl>
                                            <Col xl={2} xxl={5}></Col>
                                        </Visible>
                                    </>
                                )}
                                {/*  */}
                                <Col lg={6} xl={5} xxl={3}>
                                    <div className="label label-inline">
                                        Statut
                                    </div>
                                </Col>
                                <Col lg={6} xl={5} xxl={3}>
                                    <Select
                                        option={[
                                            {
                                                key: 'false',
                                                label: 'Actif',
                                            },
                                            {
                                                key: 'true',
                                                label: 'Désactivé',
                                            },
                                        ]}
                                        value={formik.values.disabled}
                                        keyData="key"
                                        label="label"
                                        onChange={(value) =>
                                            formik.handleChange({
                                                target: {
                                                    name: 'disabled',
                                                    value: value,
                                                },
                                            })
                                        }
                                        fullwidth="true"
                                        error={formik.errors.disabled}
                                        errorMessage={formik.errors.disabled}
                                    />
                                </Col>
                                <Visible xl xxl>
                                    <Col xl={2} xxl={5}></Col>
                                </Visible>
                                {creatingManager && isCreate && (
                                    <>
                                        <Col lg={6} xl={5} xxl={3}>
                                            <div className="label label-inline">
                                                Commercial
                                            </div>
                                        </Col>
                                        <Col lg={6} xl={5} xxl={3}>
                                            <Select
                                                canSearch
                                                fullwidth="true"
                                                request={getManagers}
                                                keyData="Matric"
                                                label="Maigst"
                                                // disabled={!isCreate}
                                                value={formik.values.Matric}
                                                onChange={(value) =>
                                                    formik.handleChange({
                                                        target: {
                                                            name: 'Matric',
                                                            value: value,
                                                        },
                                                    })
                                                }
                                                filter={{
                                                    binded: true,
                                                }}
                                                error={formik.errors.Matric}
                                                errorMessage={
                                                    formik.errors.Matric
                                                }
                                            />
                                        </Col>
                                        <Visible xl xxl>
                                            <Col xl={2} xxl={5}></Col>
                                        </Visible>
                                    </>
                                )}
                                {creatingGroup && asAdmin && (
                                    <>
                                        <Col lg={6} xl={5} xxl={3}>
                                            <div className="label label-inline">
                                                Compte groupe
                                            </div>
                                        </Col>
                                        <Col lg={6} xl={5} xxl={3}>
                                            <Select
                                                fullwidth="true"
                                                request={getAccountsAdmin}
                                                // request={getGroups}
                                                keyData="Codcpt"
                                                label="Raicpt"
                                                disabled={!isCreate}
                                                value={formik.values.Codcpt}
                                                onChange={(value) =>
                                                    formik.handleChange({
                                                        target: {
                                                            name: 'Codcpt',
                                                            value: value,
                                                        },
                                                    })
                                                }
                                                canSearch
                                                error={formik.errors.Codcpt}
                                                errorMessage={
                                                    formik.errors.Codcpt
                                                }
                                            />
                                        </Col>
                                        <Visible xl xxl>
                                            <Col xl={2} xxl={5}></Col>
                                        </Visible>
                                    </>
                                )}
                                {creatingGroup &&
                                    asAdmin &&
                                    !!formik.values.Codcpt && (
                                        <>
                                            <Col lg={6} xl={5} xxl={3}>
                                                <div className="label label-inline">
                                                    Contacts
                                                </div>
                                            </Col>
                                            <Col lg={6} xl={5} xxl={3}>
                                                <Select
                                                    fullwidth="true"
                                                    request={getContacts}
                                                    keyData="Maictt"
                                                    label="Maictt"
                                                    disabled={!isCreate}
                                                    filter={{
                                                        Codcpt: formik.values
                                                            .Codcpt,
                                                        Typrel: 2,
                                                    }}
                                                    // canSearch
                                                    value={formik.values.Maictt}
                                                    onChange={(value) =>
                                                        formik.handleChange({
                                                            target: {
                                                                name: 'Maictt',
                                                                value: value,
                                                            },
                                                        })
                                                    }
                                                    error={formik.errors.Maictt}
                                                    errorMessage={
                                                        formik.errors.Maictt
                                                    }
                                                />
                                            </Col>
                                            <Visible xl xxl>
                                                <Col xl={2} xxl={5}></Col>
                                            </Visible>
                                        </>
                                    )}
                                {creatingAgency && asGroup && (
                                    <>
                                        <Col lg={6} xl={5} xxl={3}>
                                            <div className="label label-inline">
                                                Compte agence
                                            </div>
                                        </Col>
                                        <Col lg={6} xl={5} xxl={3}>
                                            <Select
                                                fullwidth="true"
                                                request={getAccountsDashboard}
                                                keyData="Codage"
                                                label="Raiage"
                                                disabled={!isCreate}
                                                value={formik.values.Codage}
                                                onChange={(value) =>
                                                    formik.handleChange({
                                                        target: {
                                                            name: 'Codage',
                                                            value: value,
                                                        },
                                                    })
                                                }
                                                error={formik.errors.Codage}
                                                errorMessage={
                                                    formik.errors.Codage
                                                }
                                            />
                                        </Col>
                                        <Visible xl xxl>
                                            <Col xl={2} xxl={5}></Col>
                                        </Visible>
                                    </>
                                )}
                                {(creatingAgency ||
                                    // creatingGroup ||
                                    creatingAdmin ||
                                    (creatingGroup && asGroup) ||
                                    // (creatingAgency && asAgency) ||
                                    !isCreate) && (
                                    <>
                                        <div className="divider divider-middle" />
                                        <Col>
                                            <h3>Coordonnées</h3>
                                        </Col>
                                        <Col lg={8}></Col>
                                        <Col lg={6} xl={5} xxl={3}>
                                            <div className="label label-inline">
                                                Nom
                                            </div>
                                        </Col>
                                        <Col lg={6} xl={5} xxl={3}>
                                            <Input
                                                type="text"
                                                placeholder="Nom"
                                                disabled={!isCreate}
                                                fullwidth="true"
                                                name="lastName"
                                                value={formik.values.lastName}
                                                onChange={formik.handleChange}
                                                error={formik.errors.lastName}
                                                errorMessage={
                                                    formik.errors.lastName
                                                }
                                            />
                                        </Col>
                                        <Visible xl xxl>
                                            <Col xl={2} xxl={5}></Col>
                                        </Visible>
                                        <Col lg={6} xl={5} xxl={3}>
                                            <div className="label label-inline">
                                                Prénom
                                            </div>
                                        </Col>
                                        <Col lg={6} xl={5} xxl={3}>
                                            <Input
                                                type="text"
                                                placeholder="Prénom"
                                                disabled={!isCreate}
                                                fullwidth="true"
                                                name="firstName"
                                                value={formik.values.firstName}
                                                onChange={formik.handleChange}
                                                error={formik.errors.firstName}
                                                errorMessage={
                                                    formik.errors.firstName
                                                }
                                            />
                                        </Col>
                                        <Visible xl xxl>
                                            <Col xl={2} xxl={5}></Col>
                                        </Visible>
                                        <Col lg={6} xl={5} xxl={3}>
                                            <div className="label label-inline">
                                                Email
                                            </div>
                                        </Col>
                                        <Col lg={6} xl={5} xxl={3}>
                                            <Input
                                                type="email"
                                                placeholder="Email"
                                                disabled={!isCreate}
                                                fullwidth="true"
                                                name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                error={formik.errors.email}
                                                errorMessage={
                                                    formik.errors.email
                                                }
                                            />
                                        </Col>
                                        <Visible xl xxl>
                                            <Col xl={2} xxl={5}></Col>
                                        </Visible>
                                    </>
                                )}
                                {!isCreate && (
                                    <>
                                        <div className="divider divider-middle" />
                                        <Col lg={4}>
                                            <h3>Détails</h3>
                                        </Col>
                                        <Col lg={8}></Col>
                                        <Col lg={6} xl={5} xxl={3}>
                                            <div className="label label-inline">
                                                Activé le
                                            </div>
                                        </Col>
                                        <Col lg={6} xl={5} xxl={3}>
                                            <Input
                                                type="text"
                                                // placeholder="Activé le"
                                                fullwidth="true"
                                                disabled={true}
                                                // name="Datemod"
                                                value={displayDate(
                                                    get(
                                                        user,
                                                        'data.createdAt',
                                                        ''
                                                    ),
                                                    true
                                                )}
                                                // value={`${
                                                //     displayDate(
                                                //         get(
                                                //             user,
                                                //             'data.createdAt',
                                                //             ''
                                                //         ),
                                                //         true
                                                //     ).d
                                                // } ${
                                                //     displayDate(
                                                //         get(
                                                //             user,
                                                //             'data.createdAt',
                                                //             ''
                                                //         ),
                                                //         true
                                                //     ).h
                                                // }`}
                                                // onChange={formik.handleChange}
                                            />
                                        </Col>
                                        <Visible xl xxl>
                                            <Col xl={2} xxl={5}></Col>
                                        </Visible>
                                        <Col lg={6} xl={5} xxl={3}>
                                            <div className="label label-inline">
                                                Créé le
                                            </div>
                                        </Col>
                                        <Col lg={6} xl={5} xxl={3}>
                                            <Input
                                                type="text"
                                                // placeholder="Créé le"
                                                fullwidth="true"
                                                disabled={true}
                                                value={
                                                    user.data?.Datecre &&
                                                    user.data?.Heurecre
                                                        ? moment(
                                                              `${get(
                                                                  user,
                                                                  'data.Datecre',
                                                                  ''
                                                              )} ${get(
                                                                  user,
                                                                  'data.Heurecre',
                                                                  ''
                                                              )}`,
                                                              'YYYYMMDD HHmmss'
                                                          ).format(
                                                              'DD/MM/YYYY HH:mm:ss'
                                                          )
                                                        : ''
                                                }
                                                // name="Datemod"
                                                // value={formik.values.Datemod}
                                                // onChange={formik.handleChange}
                                            />
                                        </Col>
                                        <Visible xl xxl>
                                            <Col xl={2} xxl={5}></Col>
                                        </Visible>
                                        <Col lg={6} xl={5} xxl={3}>
                                            <div className="label label-inline">
                                                Créé par
                                            </div>
                                        </Col>
                                        <Col lg={6} xl={5} xxl={3}>
                                            <Input
                                                type="email"
                                                // placeholder="Créé par"
                                                fullwidth="true"
                                                disabled={true}
                                                // name="Maictt"
                                                value={`${get(
                                                    user,
                                                    'data.createdBy.firstName',
                                                    ''
                                                )} ${get(
                                                    user,
                                                    'data.createdBy.lastName',
                                                    ''
                                                )}`}
                                                // onChange={formik.handleChange}
                                            />
                                        </Col>
                                        <Visible xl xxl>
                                            <Col xl={2} xxl={5}></Col>
                                        </Visible>
                                    </>
                                )}
                            </Row>
                            <div style={{ marginTop: 12 }}>
                                <Button
                                    onClick={() => history.push('/users')}
                                    color="danger"
                                    skewed
                                    style={{ marginRight: 24 }}
                                >
                                    Retour
                                </Button>
                                <Button
                                    onClick={() => handleSubmit(formik.values)}
                                    // type="submit"
                                    disabled={mutation.isLoading}
                                    color="primary"
                                    skewed
                                >
                                    {mutation.isLoading ? (
                                        <div className="icon-loading button-loading" />
                                    ) : (
                                        'Valider'
                                    )}
                                </Button>
                            </div>
                        </form>
                    </div>
                </Container>
            </PageWrap>
        </>
    )
}

const displayDate = (dateStr, isFr) => {
    if (!dateStr) return { d: '', h: '' }

    let date = new Date(dateStr)

    if (isFr) {
        return date.toLocaleString('fr-FR', {
            hour12: false,
            timeZone: 'Europe/Paris',
        })
    }

    return date.toLocaleString('fr-FR', {
        hour12: false,
    })
}

export default withRouter(UserProfile)
