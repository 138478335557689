import {
    PieChart as RePieChart,
    Pie,
    Cell,
    Legend,
    LabelList,
    ResponsiveContainer,
    Tooltip,
} from 'recharts'
import './pie-chart.scss'

// const colors = ['#001338', '#00349C', '#558DFF', '#8AB1FF', '#C1D6FF']
const colors = [
    '#00002a',
    '#001d54',
    '#00349C',
    '#224db8',
    '#558DFF',
    '#8AB1FF',
    '#C1D6FF',
    '#e1eaff',
]

const percentFromData = (value, data) => {
    let total = 0
    for (const stat of data) {
        total += stat.value
    }
    return ((value / total) * 100).toFixed(0)
}

const PieChart = ({
    outerData,
    innerData,
    legendBottom,
    pieHeight = 125,
    pieThickness = 30,
    pieMargin = 5,
    height = 250,
    dataInner,
    dataOuter,
}) => {
    const resizedPieHeight = legendBottom ? pieHeight * 0.8 : pieHeight
    const renderLegend = (props) => {
        const { payload } = props

        return (
            <ul className={`legend${legendBottom ? ' is-bottom' : ''}`}>
                {payload.map((entry, index) =>
                    entry.payload.outerRadius === resizedPieHeight ? (
                        <li key={`item-${index}`}>
                            <div
                                className="dot"
                                style={{ backgroundColor: entry?.color }}
                            />
                            <span className="label">{entry.value}</span>
                        </li>
                    ) : null
                )}
            </ul>
        )
    }

    const cx = legendBottom ? '50%' : '33%'
    // const cy = legendBottom ? height * 0.33 : '50%'
    const cy = legendBottom ? 120 : '50%'

    const formatter = (value, data) => {
        const percent = percentFromData(value, data)
        return parseFloat(percent) < 5 ? null : `${percent}%`
    }

    return (
        <>
            <ResponsiveContainer width="100%" height={height}>
                <RePieChart>
                    <Legend
                        verticalAlign={legendBottom ? 'bottom' : 'middle'}
                        align={legendBottom ? 'center' : 'right'}
                        layout={'vertical'}
                        content={renderLegend}
                    />
                    {innerData && (
                        <Pie
                            isAnimationActive={false}
                            data={innerData}
                            dataKey="value"
                            nameKey="name"
                            cx={cx}
                            cy={cy}
                            innerRadius={
                                resizedPieHeight - pieThickness * 2 - pieMargin
                            }
                            outerRadius={
                                resizedPieHeight - pieThickness - pieMargin
                            }
                        >
                            <LabelList
                                dataKey="value"
                                formatter={(value) =>
                                    formatter(value, innerData)
                                }
                                position="inside"
                            />
                            {innerData.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={colors[index]}
                                />
                            ))}
                            {/* <Tooltip /> */}
                        </Pie>
                    )}
                    <Pie
                        isAnimationActive={false}
                        data={outerData}
                        dataKey="value"
                        nameKey="name"
                        cx={cx}
                        cy={cy}
                        innerRadius={resizedPieHeight - pieThickness}
                        outerRadius={resizedPieHeight}
                    >
                        <LabelList
                            dataKey="value"
                            formatter={(value) => formatter(value, outerData)}
                            position="inside"
                        />
                        {outerData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index]} />
                        ))}
                    </Pie>
                    <Tooltip
                        isAnimationActive={false}
                        formatter={(value, name, props) =>
                            `${new Intl.NumberFormat('fr-FR').format(value)} €`
                        }
                    />
                </RePieChart>
            </ResponsiveContainer>
            {!!dataOuter && !!dataInner && (
                <>
                    <br />
                    <p>
                        <strong>Inte. :</strong> {dataInner}
                    </p>
                    <p>
                        <strong>Exte. :</strong> {dataOuter}
                    </p>
                </>
            )}
        </>
    )
}

export default PieChart
