import React, { useState } from 'react'
import PageWrap from '../components/pageWrap'
import Table from '../components/table'
import {
    getUsers,
    getUsersAdmin,
    desactiveMultiple,
    deleteMultiple,
} from '../network/users'
import { withRouter } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import Tabs from '../components/tabs'
import Tab from '../components/tab'
import TabPanel from '../components/tabPanel'

const Users = ({ history }) => {
    const { user, isImpersonated, impersonatedUser } = useAuth()
    const [selectedLines, setSelectedLines] = useState([])

    const isAdmin = user?.role === 'super-admin' || user?.role === 'admin'

    if (isImpersonated && isAdmin) {
        return (
            <PageWrap title="Gestion des utilisateurs">
                <Tabs
                    initialValue="profile"
                    className="park-page"
                    // onClick={() => setSelectedLines([])}
                >
                    <div className="card border-bottom-none no-padding">
                        <div className="tabs-wrap">
                            <Tab
                                name="profile"
                                onClick={() => setSelectedLines([])}
                            >
                                Administrateurs MySpeedyFleet
                            </Tab>
                            <Tab
                                name="registration"
                                onClick={() => setSelectedLines([])}
                            >
                                Utilisateurs MySpeedyFleet
                            </Tab>
                        </div>
                    </div>
                    <div className="card border-top-none no-padding">
                        <TabPanel name="profile" className="full-width">
                            <UserTables
                                isAdmin={true}
                                isGroup={false}
                                selectedLines={selectedLines}
                                setSelectedLines={setSelectedLines}
                            />
                        </TabPanel>
                        <TabPanel name="registration">
                            <UserTables
                                isAdmin={false}
                                isGroup={impersonatedUser.role === 'group'}
                                selectedLines={selectedLines}
                                setSelectedLines={setSelectedLines}
                            />
                        </TabPanel>
                    </div>
                </Tabs>
            </PageWrap>
        )
    }

    return (
        <PageWrap title="Gestion des utilisateurs">
            <div className="card full-width no-padding">
                <UserTables
                    isAdmin={isAdmin}
                    isGroup={user.role === 'group'}
                    selectedLines={selectedLines}
                    setSelectedLines={setSelectedLines}
                />
                {/* <Table
                    filter={{
                        key: 'q',
                        placeholder: "Rechercher par nom d'utilisateurs",
                    }}
                    buttons={
                        selectedLines.length
                            ? [
                                  {
                                      color: 'primary',
                                      label: 'Désactiver',
                                      action: 'modal-action',
                                      request: desactiveMultiple,
                                      modalIcon: 'warning',
                                      contentText: (selectedLines, data) =>
                                          `Êtes-vous sûr de vouloir désactiver les ${
                                              selectedLines?.length
                                          } utilisateurs ${selectedLines
                                              ?.map((e) => {
                                                  if (!data) return e

                                                  const user = data.find(
                                                      (u) => u.id === e
                                                  )

                                                  return `${user.firstName} ${user.lastName}`
                                              })
                                              ?.join(', ')} ?`,
                                      cancelLabel: 'annuler',
                                      actionLabel: 'valider',
                                  },
                                  {
                                      color: 'danger',
                                      label: 'Supprimer',
                                      action: 'modal-action',
                                      request: deleteMultiple,
                                      modalIcon: 'warning',
                                      contentText: (selectedLines) =>
                                          `Êtes-vous sûr de vouloir supprimer ${selectedLines?.length} utilisateurs ?`,
                                      cancelLabel: 'Non, annuler',
                                      actionLabel: 'Oui, supprimer',
                                  },
                              ]
                            : user.role === 'group' || isAdmin
                            ? [
                                  {
                                      color: 'primary',
                                      label: 'Ajouter',
                                      action: 'callback',
                                      onClick: () =>
                                          history.push('/users/create'),
                                  },
                              ]
                            : null
                    }
                    checkbox
                    lineClassname={(e) => {
                        switch (e.status) {
                            case 'active':
                                return 'background-error'
                            case 'r':
                                return 'background-success'
                            case 'rt':
                                return 'background-info'
                            default:
                                return ''
                        }
                    }}
                    onSelectedLinesChange={setSelectedLines}
                    request={isAdmin ? getUsersAdmin : getUsers}
                    columns={columns}
                    size="small"
                    onLineSelected={
                        user.role === 'group'
                            ? (e) => history.push(`/users/${e.id}`)
                            : null
                    }
                /> */}
            </div>
        </PageWrap>
    )
}

const formatNb = (nb) => `${nb < 10 ? '0' : ''}${nb}`

const displayDate = (dateStr) => {
    if (!dateStr) return ''

    const date = new Date(dateStr)

    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    const hour = date.getHours()
    const min = date.getMinutes()

    return `${formatNb(day)}/${formatNb(month)}/${year} ${formatNb(
        hour
    )}:${formatNb(min)}`
}

const roles = {
    'super-admin': 'Administrateur',
    admin: 'Administrateur',
    manager: 'Commercial',
    group: 'Compte',
    agency: 'Agence',
}

const columns = [
    {
        title: 'Nom',
        key: 'lastName',
        render: (e) => e.lastName,
        sorter: true,
    },
    {
        title: 'Prénom',
        key: 'firstName',
        render: (e) => e.firstName,
        sorter: true,
    },
    {
        title: 'Email',
        key: 'email',
        render: (e) => e.email,
        sorter: true,
    },
    // {
    //     title: 'Compte',
    //     key: 'account.Raicpt',
    //     render: (e) => e?.agency?.Raiage || e?.account?.Raicpt,
    //     sorter: true,
    // },
    {
        title: 'Dernière utilisation',
        key: 'lastUsage',
        render: (e) => displayDate(e.lastUsage),
        sorter: true,
    },
    {
        title: 'Statut',
        key: 'role',
        render: (e) => (e.disabled ? 'Désactivé' : roles[e.role]),
        sorter: true,
    },
    // {
    //     key: 'action',
    //     render: (e, history) => <IconRedirection url={`/users/${e.id}`} />,
    //     width: 100,
    // },
]

export default withRouter(Users)

const UserTables = withRouter(
    ({ isAdmin, isGroup, selectedLines, setSelectedLines, history }) => (
        <Table
            filter={{
                key: 'q',
                placeholder: "Rechercher par nom d'utilisateurs",
            }}
            buttons={
                selectedLines.length
                    ? [
                          {
                              color: 'primary',
                              label: 'Désactiver',
                              action: 'modal-action',
                              request: desactiveMultiple,
                              modalIcon: 'warning',
                              contentText: (selectedLines, data) =>
                                  `Êtes-vous sûr de vouloir désactiver les ${
                                      selectedLines?.length
                                  } utilisateurs ${selectedLines
                                      ?.map((e) => {
                                          if (!data) return e

                                          const user = data.find(
                                              (u) => u.id === e
                                          )

                                          return `${user.firstName} ${user.lastName}`
                                      })
                                      ?.join(', ')} ?`,
                              cancelLabel: 'annuler',
                              actionLabel: 'valider',
                              noImmat: true,
                          },
                          {
                              color: 'danger',
                              label: 'Supprimer',
                              action: 'modal-action',
                              request: deleteMultiple,
                              modalIcon: 'warning',
                              contentText: (selectedLines) =>
                                  `Êtes-vous sûr de vouloir supprimer ${selectedLines?.length} utilisateurs ?`,
                              cancelLabel: 'Non, annuler',
                              actionLabel: 'Oui, supprimer',
                              noImmat: true,
                          },
                      ]
                    : isGroup || isAdmin
                    ? [
                          {
                              color: 'primary',
                              label: 'Ajouter',
                              action: 'callback',
                              onClick: () =>
                                  history.push(
                                      `/users/create${
                                          isAdmin ? '?type=admin' : ''
                                      }`
                                  ),
                          },
                      ]
                    : null
            }
            checkbox
            lineClassname={(e) => {
                switch (e.status) {
                    case 'active':
                        return 'background-error'
                    case 'r':
                        return 'background-success'
                    case 'rt':
                        return 'background-info'
                    default:
                        return ''
                }
            }}
            onSelectedLinesChange={setSelectedLines}
            request={isAdmin ? getUsersAdmin : getUsers}
            columns={columns}
            size="small"
            onLineSelected={
                isGroup || isAdmin
                    ? (e) => history.push(`/users/${e.id}`)
                    : null
            }
        />
    )
)
