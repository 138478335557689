import { useState } from 'react'
import Button from '../components/button'
import Checkbox from '../components/checkbox'
import Modal from '../components/modal'
import Input from '../components/input'
import './Login.scss'
import Logo from '../assets/images/logo-speedy-fleet.png'
import { useFormik } from 'formik'
import { useMutation } from 'react-query'

import { useAuth } from '../hooks/useAuth'
import { register, password } from '../network/auth'

const Login = () => {
    const { signIn, isError, isLoading, roles, resetRoles, chooseRole } =
        useAuth()
    const [mode, setMode] = useState('login')
    const [showModal, setShowModal] = useState(false)

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            account: '',
            remember: true,
        },
        validate: (values) => {
            const errors = {}

            if (!values.email) {
                errors.email = 'Addresse email requise'
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
            ) {
                errors.email = 'Addresse email invalide'
            }

            if (!values.password && mode === 'login') {
                errors.password = 'Mot de passe requis'
            }

            if (!values.account && mode !== 'login') {
                errors.account = 'N Compte requis'
            }

            return errors
        },
        onSubmit: (values) => {
            if (mode === 'ask') {
                askID.mutate()
            } else if (mode === 'forgot') {
                askPass.mutate()
            } else {
                signIn(values.email, values.password, values.remember)
            }
        },
    })

    const onSettled = () => {
        setShowModal(true)
        setMode('login')

        formik.setValues({
            email: '',
            password: '',
            account: '',
            remember: true,
        })
    }

    const askID = useMutation(() => register(formik.values.email), {
        // onSuccess: () => setMode('login'),
        onSettled,
    })
    const askPass = useMutation(
        () => password(formik.values.email, formik.values.account),
        {
            // onSuccess: () => setMode('login'),
            onSettled,
        }
    )

    return (
        <>
            <Modal
                isShowing={showModal}
                onClose={() => setShowModal(false)}
                icon="info-circle"
                title="An email has been sent"
            >
                <Button
                    color="primary"
                    size="large"
                    skewed
                    onClick={() => setShowModal(false)}
                >
                    Fermer
                </Button>
            </Modal>

            <div className="login-page">
                <div className="login-form">
                    <img
                        src={Logo}
                        width="204"
                        height="121"
                        alt="Speedy Fleet logo."
                        className="login-logo"
                    />
                    {mode === 'login' && (
                        <h2>Accès au portail Myspeedyfleet</h2>
                    )}
                    {mode === 'ask' && <h2>Demander un identifiant</h2>}
                    {mode === 'forgot' && <h2>Mot de passe oublié</h2>}

                    {!!roles ? (
                        <>
                            <span>Choisissez votre rôle</span>
                            <div
                                style={{
                                    gap: 12,
                                    display: 'flex',
                                    marginBottom: 24,
                                    marginTop: 12,
                                    flexDirection: 'column',
                                }}
                            >
                                {roles.map((e, idx) => (
                                    <div
                                        key={idx}
                                        style={{
                                            textAlign: 'left',
                                            padding: 10,
                                            backgroundColor:
                                                'rgba(255, 255, 255, 0.2)',
                                            cursor: 'pointer',
                                            borderRadius: 4,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                        onClick={() =>
                                            chooseRole(
                                                idx,
                                                formik.values.remember
                                            )
                                        }
                                    >
                                        <span>
                                            {e.role.role === 'manager' &&
                                                `${e.role.Matric} (Manager)`}
                                            {e.role.role === 'group' &&
                                                `${e.role.Raicpt} (Groupe)`}
                                            {e.role.role === 'agency' &&
                                                `${e.role.Raiage} (Agence)`}
                                            {e.role.role === 'admin' && 'Admin'}
                                            {e.role.role === 'super-admin' &&
                                                'Super Admin'}
                                        </span>
                                        <span>{'>'}</span>
                                    </div>
                                ))}
                            </div>

                            <div>
                                <Button
                                    type="button"
                                    skewed
                                    size="sm"
                                    onClick={resetRoles}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <form onSubmit={formik.handleSubmit}>
                                <Input
                                    id="email"
                                    name="email"
                                    type="text"
                                    placeholder="Adresse mail"
                                    flat="true"
                                    fullwidth="true"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.errors.email &&
                                        formik.touched.email
                                    }
                                    errorMessage={formik.errors.email}
                                />
                                {mode === 'login' && (
                                    <Input
                                        id="password"
                                        name="password"
                                        type="password"
                                        placeholder="Mot de passe"
                                        flat="true"
                                        fullwidth="true"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.password}
                                        error={
                                            formik.errors.password &&
                                            formik.touched.password
                                        }
                                        errorMessage={formik.errors.password}
                                    />
                                )}
                                {mode !== 'login' && (
                                    <Input
                                        id="account"
                                        name="account"
                                        type="text"
                                        placeholder="N de compte"
                                        flat="true"
                                        fullwidth="true"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.account}
                                        error={
                                            formik.errors.account &&
                                            formik.touched.account
                                        }
                                        errorMessage={formik.errors.account}
                                    />
                                )}
                                {mode === 'login' && (
                                    <>
                                        <div className="input-item">
                                            <Checkbox
                                                label="Se souvenir de moi"
                                                color="inverse"
                                                id="remember"
                                                name="remember"
                                                value={formik.values.remember}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className="input-item">
                                            <Button
                                                type="submit"
                                                skewed
                                                size="sm"
                                                disabled={isLoading}
                                            >
                                                {isLoading
                                                    ? 'Connexion...'
                                                    : 'Se connecter'}
                                            </Button>
                                        </div>
                                        {isError && (
                                            <>
                                                <b className="error-message">
                                                    {`Connexion impossible : ${
                                                        isError?.response?.data
                                                            ?.message ===
                                                        'Not allowed'
                                                            ? 'votre compte a été désactivé'
                                                            : 'Identifiant invalide'
                                                    }`}
                                                </b>
                                                <br />
                                            </>
                                        )}
                                        {/* <p>Vous n’avez pas d’identifiants ?</p>
                                <div className="input-item">
                                    <Button
                                         skewedLg
                                        size="sm"
                                        onClick={() => setMode('ask')}
                                    >
                                        Demander un identifiant
                                    </Button>
                                </div> */}
                                        <span
                                            className="link"
                                            onClick={() => setMode('forgot')}
                                        >
                                            Mot de passe oublié ?
                                        </span>
                                    </>
                                )}

                                {mode !== 'login' && (
                                    <>
                                        <div className="input-item">
                                            <Button
                                                type="submit"
                                                skewed
                                                size="sm"
                                                disabled={isLoading}
                                                onClick={formik.handleSubmit}
                                            >
                                                {askID.isLoading ||
                                                askPass.isLoading
                                                    ? 'Connexion...'
                                                    : 'Soumettre'}
                                            </Button>
                                        </div>

                                        <span
                                            /*to="/forgot"*/ className="link"
                                            onClick={() => setMode('login')}
                                        >
                                            Back
                                        </span>
                                    </>
                                )}
                            </form>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default Login
