import { React } from 'react'
import { NavLink } from 'react-router-dom'
import { useData } from '../../hooks/useData'
import { useAuth } from '../../hooks/useAuth'
import cn from 'classnames'
import './menu.scss'

const Menu = ({ fixed }) => {
    const { closeMenu } = useData()
    const { user, isImpersonated, impersonatedUser } = useAuth()
    const isAdmin =
        user.role === 'super-admin' ||
        user.role === 'admin' ||
        user.role === 'manager'

    return (
        <ul className={cn('menu', { fixed: !!fixed })}>
            <li>
                <NavLink to="/" className="menu-link" exact onClick={closeMenu}>
                    <span className="icon-home" />
                    <span className="menu-link-text">Accueil</span>
                    <span className="menu-link-text-mobile">Accueil</span>
                </NavLink>
            </li>
            {isImpersonated && (
                <li>
                    <NavLink
                        to={`/dashboard${
                            (isAdmin &&
                                isImpersonated &&
                                impersonatedUser.role !== 'group') ||
                            (!isAdmin && user.role !== 'group')
                                ? '/group'
                                : ''
                        }`}
                        className="menu-link"
                        exact
                        onClick={closeMenu}
                    >
                        <span className="icon-dashboard" />
                        <span className="menu-link-text">Tableau de bord</span>
                        <span className="menu-link-text-mobile">Tableau</span>
                    </NavLink>
                </li>
            )}
            {isImpersonated && (
                <li>
                    <NavLink
                        to="/park-managment"
                        className="menu-link"
                        exact
                        onClick={closeMenu}
                    >
                        <span className="icon-car" />
                        <span className="menu-link-text">Gestion du parc</span>
                        <span className="menu-link-text-mobile">Parc</span>
                    </NavLink>
                </li>
            )}
            {isImpersonated && (
                <li>
                    <NavLink
                        to="/guarding"
                        className="menu-link"
                        exact
                        onClick={closeMenu}
                    >
                        <span className="icon-guarding" />
                        <span className="menu-link-text">Gardiennage</span>
                        <span className="menu-link-text-mobile">
                            Gardiennage
                        </span>
                    </NavLink>
                </li>
            )}
            {isImpersonated && (
                <li>
                    <NavLink
                        to="/invoices"
                        className="menu-link"
                        exact
                        onClick={closeMenu}
                    >
                        <span className="icon-invoice" />
                        <span className="menu-link-text">Factures</span>
                        <span className="menu-link-text-mobile">Factures</span>
                    </NavLink>
                </li>
            )}
            <li>
                <NavLink
                    to="/users"
                    className="menu-link"
                    exact
                    onClick={closeMenu}
                >
                    <span className="icon-user" />
                    <span className="menu-link-text">Utilisateurs</span>
                    <span className="menu-link-text-mobile">Utilisateurs</span>
                </NavLink>
            </li>
        </ul>
    )
}

export default Menu
