import INSTANCE from './API'

export const signIn = (email, password) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: '/auth/login',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                email: email,
                password: password,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const refresh = (refreshToken) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: '/auth/refresh',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                refreshToken,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const impersonate = (id, params) =>
    new Promise((resolve, reject) => {
        const token = localStorage.getItem('token')

        INSTANCE({
            method: 'get',
            url: `/users/impersonate/${id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params,
        })
            .then(resolve)
            .catch(reject)
    })

export const updatePassword = (password) =>
    new Promise((resolve, reject) => {
        const token = localStorage.getItem('token')

        INSTANCE({
            method: 'put',
            url: '/users/mine',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                password,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const register = (email, account) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: '/auth/register',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                email,
                account,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const password = (email, account) =>
    new Promise((resolve, reject) => {
        INSTANCE({
            method: 'post',
            url: '/auth/password',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                email,
                accountCode: account,
            },
        })
            .then(resolve)
            .catch(reject)
    })

// /auth/register
// /auth/password
