import { INSTANCELIST } from './API'

export const getInvoices = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter } = queryKey[1]
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        if (filter) {
            filter.user = undefined
            filter.activeToken = undefined
        }

        INSTANCELIST({
            method: 'get',
            // url: '/payment-header/consumption?',
            url: '/payment-headers/mine',
            // url: '/contacts/info',
            // url: '/contacts/info',
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
            params: {
                sort: sort ? JSON.stringify(sort) : null,
                range: range ? JSON.stringify(range) : null,
                filter: filter ? JSON.stringify(filter) : null,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const downloadFile = (id, fileName) => {
    const token = localStorage.getItem('token')
    const impersonatedToken = localStorage.getItem('impersonatedToken')

    let filename = `${fileName}.pdf`
    let xmlHttpRequest = new XMLHttpRequest()
    xmlHttpRequest.onreadystatechange = function () {
        var a
        if (xmlHttpRequest.readyState === 4 && xmlHttpRequest.status === 200) {
            a = document.createElement('a')
            a.href = window.URL.createObjectURL(xmlHttpRequest.response)
            a.download = filename
            a.style.display = 'none'
            document.body.appendChild(a)
            a.click()
        }
    }
    xmlHttpRequest.open(
        'GET',
        `${process.env.REACT_APP_API_URL}/payment-headers/${id}/bill`
    )
    xmlHttpRequest.setRequestHeader('Content-Type', 'application/json')
    xmlHttpRequest.setRequestHeader(
        'Authorization',
        `Bearer ${impersonatedToken || token}`
    )
    xmlHttpRequest.responseType = 'blob'
    xmlHttpRequest.send(JSON.stringify())
}
