import React, { createContext, useState } from 'react'
import './tabs.scss'

export const TabContext = createContext()

function Tabs({ initialValue, className = '', children, ...otherProps }) {
    const [activeTab, changeTab] = useState(initialValue)
    const tabProviderValue = { activeTab, changeTab }

    const classNames = `tabs ${className}`

    return (
        <TabContext.Provider value={tabProviderValue}>
            <div className={classNames} {...otherProps}>
                {children}
            </div>
        </TabContext.Provider>
    )
}

export default Tabs
