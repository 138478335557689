import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { getHomeStats } from '../network/home'
import get from 'lodash.get'
import { getTires } from '../network/guarding'
import { useAuth } from '../hooks/useAuth'
import moment from 'moment/moment'

const getDates = (comparedYear, nbMonth) => {
    let currentYear

    if (!!comparedYear) {
        currentYear = comparedYear
    } else {
        currentYear = new Date().getFullYear()
    }
    //
    const currentMonth = new Date().getMonth() + 1
    let startMonth = new Date().getMonth() + 1 - (nbMonth - 1)

    let startYear = currentYear

    if (startMonth === 0) {
        if (currentMonth === 12) {
            startMonth = 1
        } else {
            startMonth = currentMonth + 1
        }
    } else if (startMonth < 0) {
        startMonth += 12
        startYear--
    }

    const startDate = `01/${
        startMonth < 9 ? '0' : ''
    }${startMonth}/${startYear}`
    const endDate = `01/${
        currentMonth < 9 ? '0' : ''
    }${currentMonth}/${currentYear}`

    const start = moment(startDate, 'DD/MM/YYYY')
        .startOf('month')
        .format('DD/MM/YYYY')
    const end = moment(endDate, 'DD/MM/YYYY')
        .endOf('month')
        .format('DD/MM/YYYY')

    console.log(`xxxx ${startDate} - ${endDate}`)
    return `${start} - ${end}`
}

const isMonthShowed = (month, totalMonthToShow, currentMonth) => {
    let start = currentMonth - totalMonthToShow
    let end = currentMonth
    if (start >= 0) {
        if (month > start && month <= end) {
            return true
        }
    } else {
        if (
            (month >= 0 && month <= end) ||
            (month > 12 + start && month <= 12)
        ) {
            return true
        }
    }
    return false
}

const useStats = (thisYear, codecli = undefined, nbMonth = 12, isCurrent) => {
    const nowDate = new Date()
    const nowMonth = nowDate.getMonth()
    const { impersonatedUser, isImpersonated, activeToken, user } = useAuth()

    const tires = useQuery('tires', getTires)

    const january = useQuery(
        [
            getHomeStats?.name + 0,
            {
                filter: {
                    dateStart: new Date(`01/01/${thisYear} 00:00:01`),
                    dateEnd: new Date(`02/01/${thisYear} 00:00:01`),
                },
                activeToken,
                user: isImpersonated ? impersonatedUser.id : user.id,
                codecli: codecli ? codecli : undefined,
                cache: nowMonth === 0 ? undefined : true,
            },
        ],
        getHomeStats,
        { enabled: !!thisYear && isMonthShowed(0, nbMonth, nowMonth) }
    )

    const february = useQuery(
        [
            getHomeStats?.name + 1,
            {
                filter: {
                    dateStart: new Date(
                        `02/01/${
                            nowMonth > 0 || isCurrent ? thisYear : thisYear - 1
                        } 00:00:02`
                    ),
                    dateEnd: new Date(
                        `03/01/${
                            nowMonth > 0 || isCurrent ? thisYear : thisYear - 1
                        } 00:00:01`
                    ),
                },
                activeToken,
                user: isImpersonated ? impersonatedUser.id : user.id,
                codecli: codecli ? codecli : undefined,
                cache: nowMonth === 1 ? undefined : true,
            },
        ],
        getHomeStats,
        { enabled: !!thisYear && isMonthShowed(1, nbMonth, nowMonth) }
    )

    const march = useQuery(
        [
            getHomeStats?.name + 2,
            {
                filter: {
                    dateStart: new Date(
                        `03/01/${
                            nowMonth > 1 || isCurrent ? thisYear : thisYear - 1
                        } 00:00:02`
                    ),
                    dateEnd: new Date(
                        `04/01/${
                            nowMonth > 1 || isCurrent ? thisYear : thisYear - 1
                        } 00:00:01`
                    ),
                },
                activeToken,
                user: isImpersonated ? impersonatedUser.id : user.id,
                codecli: codecli ? codecli : undefined,
                cache: nowMonth === 2 ? undefined : true,
            },
        ],
        getHomeStats,
        { enabled: !!thisYear && isMonthShowed(2, nbMonth, nowMonth) }
    )

    const april = useQuery(
        [
            getHomeStats?.name + 3,
            {
                filter: {
                    dateStart: new Date(
                        `04/01/${
                            nowMonth > 2 || isCurrent ? thisYear : thisYear - 1
                        } 00:00:02`
                    ),
                    dateEnd: new Date(
                        `05/01/${
                            nowMonth > 2 || isCurrent ? thisYear : thisYear - 1
                        } 00:00:01`
                    ),
                },
                activeToken,
                user: isImpersonated ? impersonatedUser.id : user.id,
                codecli: codecli ? codecli : undefined,
                cache: nowMonth === 3 ? undefined : true,
            },
        ],
        getHomeStats,
        { enabled: !!thisYear && isMonthShowed(3, nbMonth, nowMonth) }
    )

    const may = useQuery(
        [
            getHomeStats?.name + 4,
            {
                filter: {
                    dateStart: new Date(
                        `05/01/${
                            nowMonth > 3 || isCurrent ? thisYear : thisYear - 1
                        } 00:00:02`
                    ),
                    dateEnd: new Date(
                        `06/01/${
                            nowMonth > 3 || isCurrent ? thisYear : thisYear - 1
                        } 00:00:01`
                    ),
                },
                activeToken,
                user: isImpersonated ? impersonatedUser.id : user.id,
                codecli: codecli ? codecli : undefined,
                cache: nowMonth === 4 ? undefined : true,
            },
        ],
        getHomeStats,
        { enabled: !!thisYear && isMonthShowed(4, nbMonth, nowMonth) }
    )

    const june = useQuery(
        [
            getHomeStats?.name + 5,
            {
                filter: {
                    dateStart: new Date(
                        `06/01/${
                            nowMonth > 4 || isCurrent ? thisYear : thisYear - 1
                        } 00:00:02`
                    ),
                    dateEnd: new Date(
                        `07/01/${
                            nowMonth > 4 || isCurrent ? thisYear : thisYear - 1
                        } 00:00:01`
                    ),
                },
                activeToken,
                user: isImpersonated ? impersonatedUser.id : user.id,
                codecli: codecli ? codecli : undefined,
                cache: nowMonth === 5 ? undefined : true,
            },
        ],
        getHomeStats,
        { enabled: !!thisYear && isMonthShowed(5, nbMonth, nowMonth) }
    )

    const july = useQuery(
        [
            getHomeStats?.name + 6,
            {
                filter: {
                    dateStart: new Date(
                        `07/01/${
                            nowMonth > 5 || isCurrent ? thisYear : thisYear - 1
                        } 00:00:02`
                    ),
                    dateEnd: new Date(
                        `08/01/${
                            nowMonth > 5 || isCurrent ? thisYear : thisYear - 1
                        } 00:00:01`
                    ),
                },
                activeToken,
                user: isImpersonated ? impersonatedUser.id : user.id,
                codecli: codecli ? codecli : undefined,
                cache: nowMonth === 6 ? undefined : true,
            },
        ],
        getHomeStats,
        { enabled: !!thisYear && isMonthShowed(6, nbMonth, nowMonth) }
    )

    const august = useQuery(
        [
            getHomeStats?.name + 7,
            {
                filter: {
                    dateStart: new Date(
                        `08/01/${
                            nowMonth > 6 || isCurrent ? thisYear : thisYear - 1
                        } 00:00:02`
                    ),
                    dateEnd: new Date(
                        `09/01/${
                            nowMonth > 6 || isCurrent ? thisYear : thisYear - 1
                        } 00:00:01`
                    ),
                },
                activeToken,
                user: isImpersonated ? impersonatedUser.id : user.id,
                codecli: codecli ? codecli : undefined,
                cache: nowMonth === 7 ? undefined : true,
            },
        ],
        getHomeStats,
        { enabled: !!thisYear && isMonthShowed(7, nbMonth, nowMonth) }
    )

    const september = useQuery(
        [
            getHomeStats?.name + 8,
            {
                filter: {
                    dateStart: new Date(
                        `09/01/${
                            nowMonth > 7 || isCurrent ? thisYear : thisYear - 1
                        } 00:00:02`
                    ),
                    dateEnd: new Date(
                        `10/01/${
                            nowMonth > 7 || isCurrent ? thisYear : thisYear - 1
                        } 00:00:01`
                    ),
                },
                activeToken,
                user: isImpersonated ? impersonatedUser.id : user.id,
                codecli: codecli ? codecli : undefined,
                cache: nowMonth === 8 ? undefined : true,
            },
        ],
        getHomeStats,
        { enabled: !!thisYear && isMonthShowed(8, nbMonth, nowMonth) }
    )

    const october = useQuery(
        [
            getHomeStats?.name + 9,
            {
                filter: {
                    dateStart: new Date(
                        `10/01/${
                            nowMonth > 8 || isCurrent ? thisYear : thisYear - 1
                        } 00:00:02`
                    ),
                    dateEnd: new Date(
                        `11/01/${
                            nowMonth > 8 || isCurrent ? thisYear : thisYear - 1
                        } 00:00:01`
                    ),
                },
                activeToken,
                user: isImpersonated ? impersonatedUser.id : user.id,
                codecli: codecli ? codecli : undefined,
                cache: nowMonth === 9 ? undefined : true,
            },
        ],
        getHomeStats,
        { enabled: !!thisYear && isMonthShowed(9, nbMonth, nowMonth) }
    )

    const november = useQuery(
        [
            getHomeStats?.name + 10,
            {
                filter: {
                    dateStart: new Date(
                        `11/01/${
                            nowMonth > 9 || isCurrent ? thisYear : thisYear - 1
                        } 00:00:02`
                    ),
                    dateEnd: new Date(
                        `12/01/${
                            nowMonth > 9 || isCurrent ? thisYear : thisYear - 1
                        } 00:00:01`
                    ),
                },
                activeToken,
                user: isImpersonated ? impersonatedUser.id : user.id,
                codecli: codecli ? codecli : undefined,
                cache: nowMonth === 10 ? undefined : true,
            },
        ],
        getHomeStats,
        { enabled: !!thisYear && isMonthShowed(10, nbMonth, nowMonth) }
    )

    const december = useQuery(
        [
            getHomeStats?.name + 11,
            {
                filter: {
                    dateStart: new Date(
                        `12/01/${
                            nowMonth > 10 || isCurrent ? thisYear : thisYear - 1
                        } 00:00:02`
                    ),
                    dateEnd: new Date(
                        `01/01/${
                            nowMonth > 10 || isCurrent ? thisYear + 1 : thisYear
                        } 00:00:01`
                    ),
                },
                activeToken,
                user: isImpersonated ? impersonatedUser.id : user.id,
                codecli: codecli ? codecli : undefined,
                cache: nowMonth === 11 ? undefined : true,
            },
        ],
        getHomeStats,
        { enabled: !!thisYear && isMonthShowed(11, nbMonth, nowMonth) }
    )

    const getData = useMemo(() => {
        const nowMonth = new Date().getMonth()
        let month

        switch (nowMonth) {
            case 0:
                month = get(january, 'data', {})
                break
            case 1:
                month = get(february, 'data', {})
                break
            case 2:
                month = get(march, 'data', {})
                break
            case 3:
                month = get(april, 'data', {})
                break
            case 4:
                month = get(may, 'data', {})
                break
            case 5:
                month = get(june, 'data', {})
                break
            case 6:
                month = get(july, 'data', {})
                break
            case 7:
                month = get(august, 'data', {})
                break
            case 8:
                month = get(september, 'data', {})
                break
            case 9:
                month = get(october, 'data', {})
                break
            case 10:
                month = get(november, 'data', {})
                break
            case 11:
                month = get(december, 'data', {})
                break
            default:
                month = get(january, 'data', {})
                break
        }

        const thisYear = []
        const prevYear = []

        thisYear.push([
            january.isLoading ? 'Chargement...' : 'Janv',
            get(january, 'data', {}),
        ])

        nowMonth > 0 || isCurrent
            ? thisYear.push([
                  february.isLoading ? 'Chargement...' : 'Fevr',
                  get(february, 'data', {}),
              ])
            : prevYear.push([
                  february.isLoading ? 'Chargement...' : 'Fevr',
                  get(february, 'data', {}),
              ])

        nowMonth > 1 || isCurrent
            ? thisYear.push([
                  march.isLoading ? 'Chargement...' : 'Mars',
                  get(march, 'data', {}),
              ])
            : prevYear.push([
                  march.isLoading ? 'Chargement...' : 'Mars',
                  get(march, 'data', {}),
              ])

        nowMonth > 2 || isCurrent
            ? thisYear.push([
                  april.isLoading ? 'Chargement...' : 'Avril',
                  get(april, 'data', {}),
              ])
            : prevYear.push([
                  april.isLoading ? 'Chargement...' : 'Avril',
                  get(april, 'data', {}),
              ])

        nowMonth > 3 || isCurrent
            ? thisYear.push([
                  may.isLoading ? 'Chargement...' : 'Mai',
                  get(may, 'data', {}),
              ])
            : prevYear.push([
                  may.isLoading ? 'Chargement...' : 'Mai',
                  get(may, 'data', {}),
              ])

        nowMonth > 4 || isCurrent
            ? thisYear.push([
                  june.isLoading ? 'Chargement...' : 'Juin',
                  get(june, 'data', {}),
              ])
            : prevYear.push([
                  june.isLoading ? 'Chargement...' : 'Juin',
                  get(june, 'data', {}),
              ])

        nowMonth > 5 || isCurrent
            ? thisYear.push([
                  july.isLoading ? 'Chargement...' : 'Juil',
                  get(july, 'data', {}),
              ])
            : prevYear.push([
                  july.isLoading ? 'Chargement...' : 'Juil',
                  get(july, 'data', {}),
              ])

        nowMonth > 6 || isCurrent
            ? thisYear.push([
                  august.isLoading ? 'Chargement...' : 'Aout',
                  get(august, 'data', {}),
              ])
            : prevYear.push([
                  august.isLoading ? 'Chargement...' : 'Aout',
                  get(august, 'data', {}),
              ])

        nowMonth > 7 || isCurrent
            ? thisYear.push([
                  september.isLoading ? 'Chargement...' : 'Sept',
                  get(september, 'data', {}),
              ])
            : prevYear.push([
                  september.isLoading ? 'Chargement...' : 'Sept',
                  get(september, 'data', {}),
              ])

        nowMonth > 8 || isCurrent
            ? thisYear.push([
                  october.isLoading ? 'Chargement...' : 'Oct',
                  get(october, 'data', {}),
              ])
            : prevYear.push([
                  october.isLoading ? 'Chargement...' : 'Oct',
                  get(october, 'data', {}),
              ])

        nowMonth > 9 || isCurrent
            ? thisYear.push([
                  november.isLoading ? 'Chargement...' : 'Nov',
                  get(november, 'data', {}),
              ])
            : prevYear.push([
                  november.isLoading ? 'Chargement...' : 'Nov',
                  get(november, 'data', {}),
              ])

        nowMonth > 10 || isCurrent
            ? thisYear.push([
                  december.isLoading ? 'Chargement...' : 'Dec',
                  get(december, 'data', {}),
              ])
            : prevYear.push([
                  december.isLoading ? 'Chargement...' : 'Dec',
                  get(december, 'data', {}),
              ])

        let data = [...prevYear, ...thisYear].map((e) => {
            return {
                name: e[0],
                value: get(e, '1.totalAmount', 0),

                valuetire: get(e, '1.totalTire', 0),
                valuerevision: get(e, '1.totalRevision', 0),
                valuebraking: get(e, '1.totalBrakes', 0),
                valueglazing: get(e, '1.totalWindow', 0),
                valuetechnical: get(e, '1.totalPieces', 0),
            }
        })
        data = data.slice(-1 * nbMonth)

        let allTotalTireBrand = {}
        const totalTireBrand = [
            isMonthShowed(0, nbMonth, nowMonth)
                ? get(january, 'data.totalTireBrand', {})
                : {},
            isMonthShowed(1, nbMonth, nowMonth)
                ? get(february, 'data.totalTireBrand', {})
                : {},
            isMonthShowed(2, nbMonth, nowMonth)
                ? get(march, 'data.totalTireBrand', {})
                : {},
            isMonthShowed(3, nbMonth, nowMonth)
                ? get(april, 'data.totalTireBrand', {})
                : {},
            isMonthShowed(4, nbMonth, nowMonth)
                ? get(may, 'data.totalTireBrand', {})
                : {},
            isMonthShowed(5, nbMonth, nowMonth)
                ? get(june, 'data.totalTireBrand', {})
                : {},
            isMonthShowed(6, nbMonth, nowMonth)
                ? get(july, 'data.totalTireBrand', {})
                : {},
            isMonthShowed(7, nbMonth, nowMonth)
                ? get(august, 'data.totalTireBrand', {})
                : {},
            isMonthShowed(8, nbMonth, nowMonth)
                ? get(september, 'data.totalTireBrand', {})
                : {},
            isMonthShowed(9, nbMonth, nowMonth)
                ? get(october, 'data.totalTireBrand', {})
                : {},
            isMonthShowed(10, nbMonth, nowMonth)
                ? get(november, 'data.totalTireBrand', {})
                : {},
            isMonthShowed(11, nbMonth, nowMonth)
                ? get(december, 'data.totalTireBrand', {})
                : {},
        ]

        for (const tireBrand of totalTireBrand) {
            allTotalTireBrand = Object.entries(tireBrand).reduce(
                (acc, [key, value]) => ({
                    ...acc,
                    [key]: (acc[key] || 0) + value,
                }),
                { ...allTotalTireBrand }
            )
        }

        let allTotalTireType = {}
        const totalTireType = [
            isMonthShowed(0, nbMonth, nowMonth)
                ? get(january, 'data.totalTireType', {})
                : {},
            isMonthShowed(1, nbMonth, nowMonth)
                ? get(february, 'data.totalTireType', {})
                : {},
            isMonthShowed(2, nbMonth, nowMonth)
                ? get(march, 'data.totalTireType', {})
                : {},
            isMonthShowed(3, nbMonth, nowMonth)
                ? get(april, 'data.totalTireType', {})
                : {},
            isMonthShowed(4, nbMonth, nowMonth)
                ? get(may, 'data.totalTireType', {})
                : {},
            isMonthShowed(5, nbMonth, nowMonth)
                ? get(june, 'data.totalTireType', {})
                : {},
            isMonthShowed(6, nbMonth, nowMonth)
                ? get(july, 'data.totalTireType', {})
                : {},
            isMonthShowed(7, nbMonth, nowMonth)
                ? get(august, 'data.totalTireType', {})
                : {},
            isMonthShowed(8, nbMonth, nowMonth)
                ? get(september, 'data.totalTireType', {})
                : {},
            isMonthShowed(9, nbMonth, nowMonth)
                ? get(october, 'data.totalTireType', {})
                : {},
            isMonthShowed(10, nbMonth, nowMonth)
                ? get(november, 'data.totalTireType', {})
                : {},
            isMonthShowed(11, nbMonth, nowMonth)
                ? get(december, 'data.totalTireType', {})
                : {},
        ]

        for (const tireBrand of totalTireType) {
            allTotalTireType = Object.entries(tireBrand).reduce(
                (acc, [key, value]) => ({
                    ...acc,
                    [key]: (acc[key] || 0) + value,
                }),
                { ...allTotalTireType }
            )
        }

        return {
            nowMonth: month,
            totalTireBrand: Object.keys(allTotalTireBrand)
                .map((key) => ({
                    name: key,
                    value: allTotalTireBrand[key],
                }))
                .sort((a, b) => b.value - a.value),
            totalTireType: Object.keys(allTotalTireType)
                .map((key) => ({
                    name: key,
                    value: allTotalTireType[key],
                }))
                .sort((a, b) => b.value - a.value),
            totalGuarding: Math.round(get(tires, 'data.0.totalAmount', 0)),
            totalAmount:
                (isMonthShowed(0, nbMonth, nowMonth)
                    ? get(january, 'data.totalAmount', 0)
                    : 0) +
                (isMonthShowed(1, nbMonth, nowMonth)
                    ? get(february, 'data.totalAmount', 0)
                    : 0) +
                (isMonthShowed(2, nbMonth, nowMonth)
                    ? get(march, 'data.totalAmount', 0)
                    : 0) +
                (isMonthShowed(3, nbMonth, nowMonth)
                    ? get(april, 'data.totalAmount', 0)
                    : 0) +
                (isMonthShowed(4, nbMonth, nowMonth)
                    ? get(may, 'data.totalAmount', 0)
                    : 0) +
                (isMonthShowed(5, nbMonth, nowMonth)
                    ? get(june, 'data.totalAmount', 0)
                    : 0) +
                (isMonthShowed(6, nbMonth, nowMonth)
                    ? get(july, 'data.totalAmount', 0)
                    : 0) +
                (isMonthShowed(7, nbMonth, nowMonth)
                    ? get(august, 'data.totalAmount', 0)
                    : 0) +
                (isMonthShowed(8, nbMonth, nowMonth)
                    ? get(september, 'data.totalAmount', 0)
                    : 0) +
                (isMonthShowed(9, nbMonth, nowMonth)
                    ? get(october, 'data.totalAmount', 0)
                    : 0) +
                (isMonthShowed(10, nbMonth, nowMonth)
                    ? get(november, 'data.totalAmount', 0)
                    : 0) +
                (isMonthShowed(11, nbMonth, nowMonth)
                    ? get(december, 'data.totalAmount', 0)
                    : 0),
            totalTire:
                (isMonthShowed(0, nbMonth, nowMonth)
                    ? get(january, 'data.totalTire', 0)
                    : 0) +
                (isMonthShowed(1, nbMonth, nowMonth)
                    ? get(february, 'data.totalTire', 0)
                    : 0) +
                (isMonthShowed(2, nbMonth, nowMonth)
                    ? get(march, 'data.totalTire', 0)
                    : 0) +
                (isMonthShowed(3, nbMonth, nowMonth)
                    ? get(april, 'data.totalTire', 0)
                    : 0) +
                (isMonthShowed(4, nbMonth, nowMonth)
                    ? get(may, 'data.totalTire', 0)
                    : 0) +
                (isMonthShowed(5, nbMonth, nowMonth)
                    ? get(june, 'data.totalTire', 0)
                    : 0) +
                (isMonthShowed(6, nbMonth, nowMonth)
                    ? get(july, 'data.totalTire', 0)
                    : 0) +
                (isMonthShowed(7, nbMonth, nowMonth)
                    ? get(august, 'data.totalTire', 0)
                    : 0) +
                (isMonthShowed(8, nbMonth, nowMonth)
                    ? get(september, 'data.totalTire', 0)
                    : 0) +
                (isMonthShowed(9, nbMonth, nowMonth)
                    ? get(october, 'data.totalTire', 0)
                    : 0) +
                (isMonthShowed(10, nbMonth, nowMonth)
                    ? get(november, 'data.totalTire', 0)
                    : 0) +
                (isMonthShowed(11, nbMonth, nowMonth)
                    ? get(december, 'data.totalTire', 0)
                    : 0),
            totalRevision:
                (isMonthShowed(0, nbMonth, nowMonth)
                    ? get(january, 'data.totalRevision', 0)
                    : 0) +
                (isMonthShowed(1, nbMonth, nowMonth)
                    ? get(february, 'data.totalRevision', 0)
                    : 0) +
                (isMonthShowed(2, nbMonth, nowMonth)
                    ? get(march, 'data.totalRevision', 0)
                    : 0) +
                (isMonthShowed(3, nbMonth, nowMonth)
                    ? get(april, 'data.totalRevision', 0)
                    : 0) +
                (isMonthShowed(4, nbMonth, nowMonth)
                    ? get(may, 'data.totalRevision', 0)
                    : 0) +
                (isMonthShowed(5, nbMonth, nowMonth)
                    ? get(june, 'data.totalRevision', 0)
                    : 0) +
                (isMonthShowed(6, nbMonth, nowMonth)
                    ? get(july, 'data.totalRevision', 0)
                    : 0) +
                (isMonthShowed(7, nbMonth, nowMonth)
                    ? get(august, 'data.totalRevision', 0)
                    : 0) +
                (isMonthShowed(8, nbMonth, nowMonth)
                    ? get(september, 'data.totalRevision', 0)
                    : 0) +
                (isMonthShowed(9, nbMonth, nowMonth)
                    ? get(october, 'data.totalRevision', 0)
                    : 0) +
                (isMonthShowed(10, nbMonth, nowMonth)
                    ? get(november, 'data.totalRevision', 0)
                    : 0) +
                (isMonthShowed(11, nbMonth, nowMonth)
                    ? get(december, 'data.totalRevision', 0)
                    : 0),
            totalBrakes:
                (isMonthShowed(0, nbMonth, nowMonth)
                    ? get(january, 'data.totalBrakes', 0)
                    : 0) +
                (isMonthShowed(1, nbMonth, nowMonth)
                    ? get(february, 'data.totalBrakes', 0)
                    : 0) +
                (isMonthShowed(2, nbMonth, nowMonth)
                    ? get(march, 'data.totalBrakes', 0)
                    : 0) +
                (isMonthShowed(3, nbMonth, nowMonth)
                    ? get(april, 'data.totalBrakes', 0)
                    : 0) +
                (isMonthShowed(4, nbMonth, nowMonth)
                    ? get(may, 'data.totalBrakes', 0)
                    : 0) +
                (isMonthShowed(5, nbMonth, nowMonth)
                    ? get(june, 'data.totalBrakes', 0)
                    : 0) +
                (isMonthShowed(6, nbMonth, nowMonth)
                    ? get(july, 'data.totalBrakes', 0)
                    : 0) +
                (isMonthShowed(7, nbMonth, nowMonth)
                    ? get(august, 'data.totalBrakes', 0)
                    : 0) +
                (isMonthShowed(8, nbMonth, nowMonth)
                    ? get(september, 'data.totalBrakes', 0)
                    : 0) +
                (isMonthShowed(9, nbMonth, nowMonth)
                    ? get(october, 'data.totalBrakes', 0)
                    : 0) +
                (isMonthShowed(10, nbMonth, nowMonth)
                    ? get(november, 'data.totalBrakes', 0)
                    : 0) +
                (isMonthShowed(11, nbMonth, nowMonth)
                    ? get(december, 'data.totalBrakes', 0)
                    : 0),
            totalWindow:
                (isMonthShowed(0, nbMonth, nowMonth)
                    ? get(january, 'data.totalWindow', 0)
                    : 0) +
                (isMonthShowed(1, nbMonth, nowMonth)
                    ? get(february, 'data.totalWindow', 0)
                    : 0) +
                (isMonthShowed(2, nbMonth, nowMonth)
                    ? get(march, 'data.totalWindow', 0)
                    : 0) +
                (isMonthShowed(3, nbMonth, nowMonth)
                    ? get(april, 'data.totalWindow', 0)
                    : 0) +
                (isMonthShowed(4, nbMonth, nowMonth)
                    ? get(may, 'data.totalWindow', 0)
                    : 0) +
                (isMonthShowed(5, nbMonth, nowMonth)
                    ? get(june, 'data.totalWindow', 0)
                    : 0) +
                (isMonthShowed(6, nbMonth, nowMonth)
                    ? get(july, 'data.totalWindow', 0)
                    : 0) +
                (isMonthShowed(7, nbMonth, nowMonth)
                    ? get(august, 'data.totalWindow', 0)
                    : 0) +
                (isMonthShowed(8, nbMonth, nowMonth)
                    ? get(september, 'data.totalWindow', 0)
                    : 0) +
                (isMonthShowed(9, nbMonth, nowMonth)
                    ? get(october, 'data.totalWindow', 0)
                    : 0) +
                (isMonthShowed(10, nbMonth, nowMonth)
                    ? get(november, 'data.totalWindow', 0)
                    : 0) +
                (isMonthShowed(11, nbMonth, nowMonth)
                    ? get(december, 'data.totalWindow', 0)
                    : 0),
            totalPieces:
                (isMonthShowed(0, nbMonth, nowMonth)
                    ? get(january, 'data.totalPieces', 0)
                    : 0) +
                (isMonthShowed(1, nbMonth, nowMonth)
                    ? get(february, 'data.totalPieces', 0)
                    : 0) +
                (isMonthShowed(2, nbMonth, nowMonth)
                    ? get(march, 'data.totalPieces', 0)
                    : 0) +
                (isMonthShowed(3, nbMonth, nowMonth)
                    ? get(april, 'data.totalPieces', 0)
                    : 0) +
                (isMonthShowed(4, nbMonth, nowMonth)
                    ? get(may, 'data.totalPieces', 0)
                    : 0) +
                (isMonthShowed(5, nbMonth, nowMonth)
                    ? get(june, 'data.totalPieces', 0)
                    : 0) +
                (isMonthShowed(6, nbMonth, nowMonth)
                    ? get(july, 'data.totalPieces', 0)
                    : 0) +
                (isMonthShowed(7, nbMonth, nowMonth)
                    ? get(august, 'data.totalPieces', 0)
                    : 0) +
                (isMonthShowed(8, nbMonth, nowMonth)
                    ? get(september, 'data.totalPieces', 0)
                    : 0) +
                (isMonthShowed(9, nbMonth, nowMonth)
                    ? get(october, 'data.totalPieces', 0)
                    : 0) +
                (isMonthShowed(10, nbMonth, nowMonth)
                    ? get(november, 'data.totalPieces', 0)
                    : 0) +
                (isMonthShowed(11, nbMonth, nowMonth)
                    ? get(december, 'data.totalPieces', 0)
                    : 0),
            totalOther:
                (isMonthShowed(0, nbMonth, nowMonth)
                    ? get(january, 'data.totalOther', 0)
                    : 0) +
                (isMonthShowed(1, nbMonth, nowMonth)
                    ? get(february, 'data.totalOther', 0)
                    : 0) +
                (isMonthShowed(2, nbMonth, nowMonth)
                    ? get(march, 'data.totalOther', 0)
                    : 0) +
                (isMonthShowed(3, nbMonth, nowMonth)
                    ? get(april, 'data.totalOther', 0)
                    : 0) +
                (isMonthShowed(4, nbMonth, nowMonth)
                    ? get(may, 'data.totalOther', 0)
                    : 0) +
                (isMonthShowed(5, nbMonth, nowMonth)
                    ? get(june, 'data.totalOther', 0)
                    : 0) +
                (isMonthShowed(6, nbMonth, nowMonth)
                    ? get(july, 'data.totalOther', 0)
                    : 0) +
                (isMonthShowed(7, nbMonth, nowMonth)
                    ? get(august, 'data.totalOther', 0)
                    : 0) +
                (isMonthShowed(8, nbMonth, nowMonth)
                    ? get(september, 'data.totalOther', 0)
                    : 0) +
                (isMonthShowed(9, nbMonth, nowMonth)
                    ? get(october, 'data.totalOther', 0)
                    : 0) +
                (isMonthShowed(10, nbMonth, nowMonth)
                    ? get(november, 'data.totalOther', 0)
                    : 0) +
                (isMonthShowed(11, nbMonth, nowMonth)
                    ? get(december, 'data.totalOther', 0)
                    : 0),
            data,
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        january.data,
        february.data,
        march.data,
        april.data,
        may.data,
        june.data,
        july.data,
        august.data,
        september.data,
        october.data,
        november.data,
        december.data,
        tires.data,
        nbMonth,
    ])

    return {
        dates: isCurrent
            ? `01/01/${thisYear} - 31/12/${thisYear}`
            : getDates(thisYear, nbMonth),
        ...getData,
        isLoading:
            january.isLoading ||
            february.isLoading ||
            march.isLoading ||
            april.isLoading ||
            may.isLoading ||
            june.isLoading ||
            july.isLoading ||
            august.isLoading ||
            september.isLoading ||
            october.isLoading ||
            november.isLoading ||
            december.isLoading,
    }
}

export default useStats
