import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './footer.scss'
import { DidomiSDK } from '@didomi/react'

const didomiConfig = {
    app: {
        name: 'Didomi',
        apiKey: 'cfde16d1-7d26-422e-a2a4-aa36baacfc6d',
        vendors: {
            iab: {
                all: true,
                version: 2,
            },
        },
    },
    notice: {
        position: 'bottom',
    },
    languages: {
        enabled: ['fr'],
        default: 'fr',
    },
    tagManager: {
        provider: 'gtm',
    },
}

const Footer = () => {
    const [didomiObject, setDidomiObject] = useState()
    const year = new Date().getFullYear()

    useEffect(() => {
        const accepted = localStorage.getItem('didomiAccepted')

        if (!accepted) {
            localStorage.setItem('didomiAccepted', false)
            didomiObject?.preferences.show()
        }
    }, [didomiObject])

    return (
        <>
            <DidomiSDK
                iabVersion={2}
                config={didomiConfig}
                gdprAppliesGlobally={true}
                onReady={(didomi) => {
                    setDidomiObject(didomi)
                    console.log('Didomi SDK is loaded and ready', didomi)
                }}
                onPreferencesClickAgreeToAll={() => {
                    localStorage.setItem('didomiAccepted', true)
                }}
                onPreferencesClickDisagreeToAll={() => {
                    localStorage.setItem('didomiAccepted', false)
                }}
            />
            <footer className="footer">
                <div className="footer-links">
                    <Link to="/" exact>
                        Mentions légales
                    </Link>
                    {didomiObject && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                            onClick={() => {
                                didomiObject?.preferences.show()
                            }}
                            exact
                        >
                            Gérez vos cookies
                        </a>
                    )}
                </div>
                <ul className="social">
                    <li>
                        <a
                            className="icon-twitter"
                            href="https://twitter.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            &nbsp;
                        </a>
                    </li>
                    <li>
                        <a
                            className="icon-facebook"
                            href="https://www.facebook.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            &nbsp;
                        </a>
                    </li>
                    <li>
                        <a
                            className="icon-youtube"
                            href="https://www.youtube.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            &nbsp;
                        </a>
                    </li>
                </ul>
                <div className="footer-copy">&copy; Speedy {year}</div>
            </footer>
        </>
    )
}

export default Footer
