import React, { useState, useRef } from 'react'
import PageWrap from '../components/pageWrap'
import Input from '../components/input'
import Select from '../components/select'
import Button from '../components/button'
import Table from '../components/table'
import Modal from '../components/modal'
import { Container, Row, Col } from 'react-grid-system'
import { useFormik } from 'formik'
import {
    getVehiculeDetail,
    updateVehicule,
    getVehiculeHisto,
    getInfoCodeTypes,
    createVehicule,
} from '../network/parkManagement'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import get from 'lodash.get'
import { useMutation } from 'react-query'
import { withRouter } from 'react-router-dom'
import { getGroupsDashboard, getAccountsDashboard } from '../network/dashboard'
import { useAuth } from '../hooks/useAuth'

const RegDetail = ({ history }) => {
    let { id } = useParams()
    const myCertifRef = useRef(null)
    const myInfoRef = useRef(null)
    const mySuppDataRef = useRef(null)
    const { user, isImpersonated, activeToken, impersonatedUser } = useAuth()
    const isCreate = id === 'create'
    const [isModalOpen, setIsModalOpen] = useState(null)
    const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(null)

    const groupsUser = useQuery(
        [
            getGroupsDashboard?.name,
            {
                range: null,
                unlimited: true,
                activeToken,
                user: isImpersonated ? impersonatedUser.id : user.id,
            },
        ],
        getGroupsDashboard,
        {
            enabled: isCreate,
        }
    )

    const mutation = useMutation(
        (values) => {
            let newValues = { ...values }

            newValues.vehiculeInfo = (newValues.vehiculeInfo || []).filter(
                (item) => item.CODTIC
            )

            if (isCreate) {
                return createVehicule(newValues)
            } else {
                return updateVehicule(newValues, id)
            }
        },
        {
            onSuccess: () => setIsModalSuccessOpen(true),
            onError: (res) => {
                if (res.response?.data.code === 409) {
                    formik.setFieldError(
                        'IMMAT',
                        'Immatriculation déjà existante'
                    )
                    setIsModalOpen(409)
                    myInfoRef.current.scrollIntoView()
                } else {
                    setIsModalOpen(true)
                }
            },
        }
    )

    const data = useQuery(
        [getVehiculeDetail?.name, id],
        () => getVehiculeDetail(id),
        {
            onSuccess: (data) => {
                formik.setValues({
                    IMMAT: get(data, 'IMMAT', ''),
                    CODECLI: get(data, 'CODECLI', ''),
                    Compte: get(data, 'Compte', ''),
                    EMAIL: get(data, 'EMAIL', ''),

                    LMARQUE: get(data, 'LMARQUE', ''),
                    LMODEL: get(data, 'LMODEL', ''),
                    ANNEEVEH: get(data, 'ANNEEVEH', ''),
                    ENERGIE: get(data, 'ENERGIE', ''),
                    GENRE: get(data, 'GENRE', ''),
                    SERIE: get(data, 'SERIE', ''),

                    vehiculeInfo: get(data, 'vehiculeInfo', ''),

                    account: get(data, 'account.Codcpt', ''),
                    agency: get(data, 'agency.Codage', ''),
                })
            },
            retry: (failureCount, error) => {
                if (error.response.status === 403) {
                    history.push('/park-managment')
                    return false
                }

                return true
            },
            refetchOnWindowFocus: false,
            enabled: !isCreate,
        }
    )

    const validate = (values) => {
        const errors = {}

        if (isCreate && !formik.values?.CODECLI) {
            errors.CODECLI = 'Compte requis'
        }

        if (isCreate && !formik.values?.IMMAT) {
            errors.IMMAT = 'Immat requis'
        }

        if (!values.GENRE) {
            errors.GENRE = 'Type requis'
        }

        if (
            values?.vehiculeInfo?.[0]?.VALRTR &&
            !values?.vehiculeInfo?.[0]?.CODTIC
        ) {
            errors.vehiculeInfo = {
                ...errors.vehiculeInfo,
                0: {
                    ...errors.vehiculeInfo?.[0],
                    CODTIC: 'Type requis',
                },
            }
        }

        if (
            values?.vehiculeInfo?.[1]?.VALRTR &&
            !values?.vehiculeInfo?.[1]?.CODTIC
        ) {
            errors.vehiculeInfo = {
                ...errors.vehiculeInfo,
                1: {
                    ...errors.vehiculeInfo?.[1],
                    CODTIC: 'Type requis',
                },
            }
        }

        const regex = /^[0-9]{8}$/

        if (values.ANNEEVEH) {
            if (!regex.test(values.ANNEEVEH)) {
                errors.ANNEEVEH = 'Date invalide'
            } else {
                let year = parseInt(values.ANNEEVEH.substr(0, 4))
                let month = parseInt(values.ANNEEVEH.substr(4, 2))
                let day = parseInt(values.ANNEEVEH.substr(6, 2))

                if (
                    year < 1900 ||
                    year > 2100 ||
                    month < 1 ||
                    month > 12 ||
                    day < 1 ||
                    day > 31
                ) {
                    errors.ANNEEVEH = 'Date invalide'
                }
            }
        }

        return errors
    }

    const formik = useFormik({
        initialValues: {
            IMMAT: '',
            CODECLI: '',
            Compte: '',
            EMAIL: '',

            LMARQUE: '',
            LMODEL: '',
            ANNEEVEH: '',
            ENERGIE: '',
            SERIE: '',
            GENRE: '',

            vehiculeInfo: [
                {
                    CODTIC: undefined,
                    VALRTR: '',
                },
                {
                    CODTIC: undefined,
                    VALRTR: '',
                },
            ],
        },
        validate,
        onSubmit: (values) => mutation.mutate(values),
        validateOnChange: false,
    })

    return (
        <>
            <Modal
                icon={'warning'}
                isShowing={!!isModalOpen}
                onClose={() => setIsModalOpen(null)}
                title={
                    isModalOpen === 409
                        ? 'Un vehicule avec cette IMMAT existe déjà'
                        : `Une erreur est survenue en voulant ${
                              isCreate ? 'creer' : 'modifier'
                          } le vehicule`
                }
            >
                <Button
                    onClick={() => setIsModalOpen(null)}
                    color={'danger'}
                    skewed
                    style={{ marginRight: 24 }}
                >
                    Fermer
                </Button>
            </Modal>

            <Modal
                icon={'info-circle'}
                isShowing={!!isModalSuccessOpen}
                title={`Le vehicule à été ${
                    isCreate ? 'créer' : 'modifié'
                } avec succès`}
            >
                <Button
                    onClick={() => history.push('/park-managment?byimmat=true')}
                    // color={'danger'}
                    color="primary"
                    skewed
                    style={{ marginRight: 24 }}
                >
                    Retour
                </Button>
            </Modal>

            <PageWrap
                onBack={() => history.push('/park-managment?byimmat=true')}
            >
                <Container>
                    <h2>Détail de l’immatriculation</h2>
                    {!isCreate && data.isLoading && (
                        <div>
                            <div
                                style={{ width: 'fit-content' }}
                                className="icon-loading button-loading"
                            />
                            Chargement...
                        </div>
                    )}
                    {(!data.isLoading || isCreate) && (
                        <div className="card form">
                            {/* <form> */}
                            <Row>
                                <Col lg={4} offset={{ lg: 1 }}>
                                    <h3 id="info-vehicule" ref={myInfoRef}>
                                        Informations du véhicule
                                    </h3>
                                </Col>
                                <Col lg={7}></Col>
                                <Col lg={4} offset={{ lg: 1 }}>
                                    <div className="label label-inline">
                                        Détail de l’immatriculation (XX-XXX-XX)
                                    </div>
                                </Col>
                                <Col xs={10} lg={6} xl={4} xxl={3}>
                                    <Input
                                        value={formik.values?.IMMAT}
                                        onChange={(e) =>
                                            formik.handleChange({
                                                target: {
                                                    name: 'IMMAT',
                                                    value: e.target.value,
                                                },
                                            })
                                        }
                                        type="text"
                                        placeholder=""
                                        disabled={!isCreate}
                                        fullwidth="true"
                                        error={formik.errors?.IMMAT}
                                        errorMessage={formik.errors?.IMMAT}
                                    />
                                </Col>
                                <Col lg={4} offset={{ lg: 1 }}>
                                    <div className="label label-inline">
                                        Compte *
                                    </div>
                                </Col>
                                <Col xs={10} lg={6} xl={4} xxl={3}>
                                    {isCreate ? (
                                        <Select
                                            allowClear
                                            request={getAccountsDashboard}
                                            keyData="Codage"
                                            label="Raiage"
                                            value={formik.values?.CODECLI}
                                            onChange={(value) =>
                                                formik.handleChange({
                                                    target: {
                                                        name: 'CODECLI',
                                                        value: value,
                                                    },
                                                })
                                            }
                                            display={(e) =>
                                                `${e.Raiage} - ${e.Codage}`
                                            }
                                            fullwidth="true"
                                            error={formik.errors?.CODECLI}
                                            errorMessage={
                                                formik.errors?.CODECLI
                                            }
                                        />
                                    ) : (
                                        <Input
                                            value={get(
                                                data,
                                                'data.agency.Raiage',
                                                ''
                                            )}
                                            type="text"
                                            placeholder=""
                                            disabled
                                            fullwidth="true"
                                        />
                                    )}
                                </Col>
                                <Col lg={4} offset={{ lg: 1 }}>
                                    <div className="label label-inline">
                                        Compte de regroupement
                                    </div>
                                </Col>
                                <Col xs={10} lg={6} xl={4} xxl={3}>
                                    {isCreate ? (
                                        <Input
                                            value={get(
                                                groupsUser,
                                                'data.list.0.Raicpt',
                                                ''
                                            )}
                                            type="text"
                                            placeholder=""
                                            disabled
                                            fullwidth="true"
                                        />
                                    ) : (
                                        <Input
                                            value={get(
                                                data,
                                                'data.account.Raicpt',
                                                ''
                                            )}
                                            type="text"
                                            placeholder=""
                                            disabled
                                            fullwidth="true"
                                        />
                                    )}
                                </Col>
                                <Col lg={4} offset={{ lg: 1 }}>
                                    <div className="label label-inline">
                                        Adresse mail collaborateur
                                    </div>
                                </Col>
                                <Col xs={10} lg={6} xl={4} xxl={3}>
                                    <Input
                                        value={get(data, 'data.EMAIL', '')}
                                        type="text"
                                        placeholder=""
                                        disabled
                                        fullwidth="true"
                                    />
                                </Col>
                                <div className="divider divider-middle"></div>
                                <Col xl={4} offset={{ xl: 1 }}>
                                    <h3 id="certificat" ref={myCertifRef}>
                                        Certificat d’immatriculation
                                    </h3>
                                </Col>
                                <Col xl={7}></Col>
                                <Col lg={4} offset={{ lg: 1 }}>
                                    <div className="label label-inline">
                                        Marque
                                    </div>
                                </Col>
                                <Col xs={10} lg={6} xl={4} xxl={3}>
                                    <Input
                                        type="text"
                                        placeholder=""
                                        // disabled={!isCreate}
                                        fullwidth="true"
                                        name="LMARQUE"
                                        value={formik.values.LMARQUE}
                                        onChange={formik.handleChange}
                                        error={formik.errors.LMARQUE}
                                        errorMessage={formik.errors.LMARQUE}
                                    />
                                </Col>

                                <Col lg={4} offset={{ lg: 1 }}>
                                    <div className="label label-inline">
                                        Modèle
                                    </div>
                                </Col>
                                <Col xs={10} lg={6} xl={4} xxl={3}>
                                    <Input
                                        type="text"
                                        placeholder=""
                                        // disabled={!isCreate}
                                        fullwidth="true"
                                        name="LMODEL"
                                        value={formik.values.LMODEL}
                                        onChange={formik.handleChange}
                                        error={formik.errors.LMODEL}
                                        errorMessage={formik.errors.LMODEL}
                                    />
                                </Col>

                                <Col lg={4} offset={{ lg: 1 }}>
                                    <div className="label label-inline">
                                        Date de mise en circulation (AAAAMMJJ)
                                    </div>
                                </Col>
                                <Col xs={10} lg={6} xl={4} xxl={3}>
                                    <Input
                                        type="text"
                                        placeholder=""
                                        // disabled={!isCreate}
                                        fullwidth="true"
                                        name="ANNEEVEH"
                                        value={formik.values.ANNEEVEH}
                                        onChange={formik.handleChange}
                                        error={formik.errors.ANNEEVEH}
                                        errorMessage={formik.errors.ANNEEVEH}
                                    />
                                </Col>

                                <Col lg={4} offset={{ lg: 1 }}>
                                    <div className="label label-inline">
                                        Energie
                                    </div>
                                </Col>
                                <Col xs={10} lg={6} xl={4} xxl={3}>
                                    <Select
                                        option={[
                                            {
                                                key: 'ES',
                                                label: 'Essence',
                                            },
                                            {
                                                key: 'DI',
                                                label: 'Diesel',
                                            },
                                            {
                                                key: 'GP',
                                                label: 'GPL',
                                            },
                                            {
                                                key: 'EL',
                                                label: 'Electrique',
                                            },
                                            {
                                                key: 'FL',
                                                label: 'Flex',
                                            },
                                            {
                                                key: 'GO',
                                                label: 'Gazoil',
                                            },
                                            {
                                                key: 'HY',
                                                label: 'Hybride',
                                            },
                                        ]}
                                        value={formik.values.ENERGIE}
                                        keyData="key"
                                        label="label"
                                        onChange={(value) =>
                                            formik.handleChange({
                                                target: {
                                                    name: 'ENERGIE',
                                                    value: value,
                                                },
                                            })
                                        }
                                        fullwidth="true"
                                        error={formik.errors.ENERGIE}
                                        errorMessage={formik.errors.ENERGIE}
                                    />
                                </Col>

                                <Col lg={4} offset={{ lg: 1 }}>
                                    <div className="label label-inline">
                                        Numéro de série
                                    </div>
                                </Col>
                                <Col xs={10} lg={6} xl={4} xxl={3}>
                                    <Input
                                        type="text"
                                        placeholder=""
                                        // disabled={!isCreate}
                                        fullwidth="true"
                                        name="SERIE"
                                        value={formik.values.SERIE}
                                        onChange={formik.handleChange}
                                        error={formik.errors.SERIE}
                                        errorMessage={formik.errors.SERIE}
                                    />
                                </Col>

                                <Col lg={4} offset={{ lg: 1 }}>
                                    <div className="label label-inline">
                                        Type de véhicule *
                                    </div>
                                </Col>
                                <Col xs={10} lg={6} xl={4} xxl={3}>
                                    <Select
                                        option={[
                                            {
                                                key: 'VP',
                                                label: 'Véhicule particulier',
                                            },
                                            {
                                                key: 'VU',
                                                label: 'Véhicule utilitaire',
                                            },
                                        ]}
                                        value={formik.values.GENRE}
                                        keyData="key"
                                        label="label"
                                        onChange={(value) =>
                                            formik.handleChange({
                                                target: {
                                                    name: 'GENRE',
                                                    value: value,
                                                },
                                            })
                                        }
                                        fullwidth="true"
                                        error={formik.errors.GENRE}
                                        errorMessage={formik.errors.GENRE}
                                    />
                                </Col>

                                <div className="divider divider-middle"></div>
                                <Col xl={4} offset={{ xl: 1 }}>
                                    <h3 id="supp-data" ref={mySuppDataRef}>
                                        Données complémentaires
                                    </h3>
                                </Col>
                                <Col xl={7}></Col>

                                <Col lg={4} offset={{ lg: 1 }}>
                                    <div className="label label-inline">
                                        LIBELLE 1
                                    </div>
                                </Col>
                                <Col xs={10} lg={6} xl={4} xxl={3}>
                                    <Select
                                        allowClear
                                        request={getInfoCodeTypes}
                                        keyData="CODTIC"
                                        label="LIBTIC"
                                        value={
                                            formik.values?.vehiculeInfo?.[0]
                                                ?.CODTIC
                                        }
                                        onChange={(value) =>
                                            formik.handleChange({
                                                target: {
                                                    name: 'vehiculeInfo.0.CODTIC',
                                                    value: value,
                                                },
                                            })
                                        }
                                        fullwidth="true"
                                        error={
                                            formik.errors?.vehiculeInfo?.[0]
                                                ?.CODTIC
                                        }
                                        errorMessage={
                                            formik.errors?.vehiculeInfo?.[0]
                                                ?.CODTIC
                                        }
                                    />
                                </Col>
                                <Col lg={4} offset={{ lg: 1 }}>
                                    <div className="label label-inline">
                                        VALEUR 1
                                    </div>
                                </Col>
                                <Col xs={10} lg={6} xl={4} xxl={3}>
                                    <Input
                                        type="text"
                                        placeholder=""
                                        // disabled={!isCreate}
                                        fullwidth="true"
                                        name="vehiculeInfo.0.VALRTR"
                                        value={
                                            formik.values?.vehiculeInfo?.[0]
                                                ?.VALRTR
                                        }
                                        onChange={formik.handleChange}
                                        error={
                                            formik.errors?.vehiculeInfo?.[0]
                                                ?.VALRTR
                                        }
                                        errorMessage={
                                            formik.errors?.vehiculeInfo?.[0]
                                                ?.VALRTR
                                        }
                                    />
                                </Col>

                                <Col lg={4} offset={{ lg: 1 }}>
                                    <div className="label label-inline">
                                        LIBELLE 2
                                    </div>
                                </Col>
                                <Col xs={10} lg={6} xl={4} xxl={3}>
                                    <Select
                                        allowClear
                                        request={getInfoCodeTypes}
                                        keyData="CODTIC"
                                        label="LIBTIC"
                                        value={
                                            formik.values?.vehiculeInfo?.[1]
                                                ?.CODTIC
                                        }
                                        onChange={(value) =>
                                            formik.handleChange({
                                                target: {
                                                    name: 'vehiculeInfo.1.CODTIC',
                                                    value: value,
                                                },
                                            })
                                        }
                                        fullwidth="true"
                                        error={
                                            formik.errors?.vehiculeInfo?.[1]
                                                ?.CODTIC
                                        }
                                        errorMessage={
                                            formik.errors?.vehiculeInfo?.[1]
                                                ?.CODTIC
                                        }
                                    />
                                </Col>
                                <Col lg={4} offset={{ lg: 1 }}>
                                    <div className="label label-inline">
                                        VALEUR 2
                                    </div>
                                </Col>
                                <Col xs={10} lg={6} xl={4} xxl={3}>
                                    <Input
                                        type="text"
                                        placeholder=""
                                        // disabled={!isCreate}
                                        fullwidth="true"
                                        name="vehiculeInfo.1.VALRTR"
                                        value={
                                            formik.values?.vehiculeInfo?.[1]
                                                ?.VALRTR
                                        }
                                        onChange={formik.handleChange}
                                        error={
                                            formik.errors?.vehiculeInfo?.[1]
                                                ?.VALRTR
                                        }
                                        errorMessage={
                                            formik.errors?.vehiculeInfo?.[1]
                                                ?.VALRTR
                                        }
                                    />
                                </Col>
                            </Row>
                            <div style={{ marginTop: 12 }}>
                                <Button
                                    onClick={() =>
                                        history.push('/park-managment')
                                    }
                                    color="danger"
                                    skewed
                                    style={{ marginRight: 24 }}
                                >
                                    Retour
                                </Button>
                                <Button
                                    onClick={async () => {
                                        const errors =
                                            await formik.validateForm()

                                        if (Object.keys(errors).length) {
                                            if (
                                                errors.CODECLI ||
                                                errors.IMMAT
                                            ) {
                                                myInfoRef.current.scrollIntoView()
                                            } else if (errors.GENRE) {
                                                myCertifRef.current.scrollIntoView()
                                            } else {
                                                mySuppDataRef.current.scrollIntoView()
                                            }
                                        } else {
                                            formik.handleSubmit()
                                        }
                                    }}
                                    // type="submit"
                                    disabled={mutation.isLoading}
                                    color="primary"
                                    skewed
                                >
                                    {mutation.isLoading ? (
                                        <div className="icon-loading button-loading" />
                                    ) : (
                                        'Valider'
                                    )}
                                </Button>
                            </div>
                            {/* </form> */}
                            {!isCreate && (
                                <>
                                    <div className="divider divider-middle" />
                                    <Row>
                                        <Col lg={4} offset={{ lg: 1 }}>
                                            <h3>Historique des prestations</h3>
                                        </Col>
                                    </Row>
                                    <Table
                                        pageSize={15}
                                        hasPagination={false}
                                        request={getVehiculeHisto}
                                        columns={columns}
                                        size="small"
                                        params={{ id }}
                                    />
                                </>
                            )}
                        </div>
                    )}
                </Container>
            </PageWrap>
        </>
    )
}

const displayDate = (d) => {
    if (!d) return 'XX/XX/XXXX'

    const date = new Date(d)

    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    return `${day < 10 ? '0' : ''}${day}/${
        month < 10 ? '0' : ''
    }${month}/${year}`
}

const columns = [
    {
        title: 'Date',
        key: 'dateDocument',
        render: (e) => displayDate(e.dateDocument),
        sorter: true,
    },
    {
        title: 'Prestation',
        key: 'DESFAC',
        sorter: false,
        render: (e) => e.DESFAC,
    },
]

export default withRouter(RegDetail)
