import React from 'react'
import { useData } from '../../hooks/useData'
import UserInfo from '../userInfo'
import './header.scss'
import Arrow from '../../assets/icons/LeftArrow'

const Header = ({ title, onBack }) => {
    const { openMenu } = useData()

    return (
        <header className="header">
            <div className="header-menu">
                <button className="icon-menu" onClick={openMenu} />
            </div>

            <h2 onClick={onBack} className={!!onBack && 'clickable'}>
                {!!onBack && <Arrow />}
                {/* {!!onBack && !!title ? '' : 'Retour'} */}
                {title}
            </h2>

            {/* <div>test</div> */}

            <UserInfo />
        </header>
    )
}

export default Header
