import React from 'react'
import './pagination.scss'

const Pagination = ({ page, onChange, pageSize, totalPage }) => {
    page = page - 1
    const lastPage = Math.ceil(totalPage / pageSize)

    const isOutOfBoudaries = (n) => {
        if (n < 2 || n > lastPage - 1) {
            return true
        }

        if (n > page + 2) {
            if (page !== 0 && n > 3) {
                return true
            }
        }

        if (n < page) {
            if (page !== lastPage && n < lastPage - 2) {
                return true
            }
        }

        return false
    }

    return (
        <div className="pagination">
            <span>Page :</span>
            {page !== 0 && (
                <button
                    onClick={() => onChange(page - 1)}
                    className="pagination-link pagination-next"
                >
                    « Precedent |&nbsp;
                </button>
            )}
            <ul className="pagination-list">
                <li>
                    <button
                        onClick={() => onChange(0)}
                        className={`pagination-link${
                            page === 0 ? ' selected' : ''
                        }`}
                    >
                        1
                    </button>
                </li>
                {page > 1 && (
                    <li>
                        <button className="pagination-link">...</button>
                    </li>
                )}
                {!isOutOfBoudaries(page - 1) && (
                    <li>
                        <button
                            onClick={() => onChange(page - 2)}
                            className="pagination-link"
                        >
                            {page - 1}
                        </button>
                    </li>
                )}
                {!isOutOfBoudaries(page) && (
                    <li>
                        <button
                            onClick={() => onChange(page - 1)}
                            className="pagination-link"
                        >
                            {page}
                        </button>
                    </li>
                )}
                {!isOutOfBoudaries(page + 1) && (
                    <li>
                        <button className="pagination-link selected">
                            {page + 1}
                        </button>
                    </li>
                )}
                {!isOutOfBoudaries(page + 2) && (
                    <li>
                        <button
                            onClick={() => onChange(page + 1)}
                            className="pagination-link"
                        >
                            {page + 2}
                        </button>
                    </li>
                )}
                {!isOutOfBoudaries(page + 3) && (
                    <li>
                        <button
                            onClick={() => onChange(page + 2)}
                            className="pagination-link"
                        >
                            {page + 3}
                        </button>
                    </li>
                )}
                {(page < lastPage - 2 || (lastPage < 3 && lastPage > 1)) && (
                    <li>
                        <button className="pagination-link">...</button>
                    </li>
                )}
                {lastPage && lastPage > 1 ? (
                    <li>
                        <button
                            onClick={() => onChange(lastPage - 1)}
                            className={`pagination-link${
                                page === lastPage - 1 ? ' selected' : ''
                            }`}
                        >
                            {lastPage}
                        </button>
                    </li>
                ) : null}
            </ul>
            {lastPage && page !== lastPage - 1 ? (
                <button
                    onClick={() => onChange(page + 1)}
                    className="pagination-link pagination-next"
                >
                    &nbsp;| Suivant »
                </button>
            ) : null}
        </div>
    )
}

export default Pagination
