import { React } from 'react'
import Rodal from 'rodal'

import 'rodal/lib/rodal.css'
import './modal.scss'

const Modal = ({ isShowing, toggleModal, children, icon, onClose, title }) => {
    return (
        <>
            <Rodal
                visible={isShowing}
                onClose={onClose}
                closeOnEsc
                showCloseButton={false}
                width={600}
                height="auto"
            >
                <header className="modal-header">
                    {icon ? (
                        <div className={`modal-icon icon-${icon}`} />
                    ) : null}
                </header>
                <div className="modal-body">
                    {title ? <h3>{title}</h3> : null}
                    {children}
                </div>
            </Rodal>
        </>
    )
}

export default Modal
