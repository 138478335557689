import { React } from 'react'
import Header from '../header'
import Footer from '../footer'

const PageWrap = ({ children, title, onBack }) => {
    return (
        <div className="main-wrap">
            <Header title={title} onBack={onBack} />
            <main className="main">{children}</main>

            <Footer />
        </div>
    )
}

export default PageWrap
