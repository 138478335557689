import { INSTANCE, INSTANCELIST } from './API'

export const getHomeStats = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { codecli, sort, range, filter, cache } = queryKey[1]
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        if (filter) {
            filter.user = undefined
            filter.activeToken = undefined
        }

        INSTANCE({
            method: 'get',
            url: '/payment-headers/consumption',
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
            params: {
                codecli,
                sorts: sort ? JSON.stringify(sort) : null,
                range: range ? JSON.stringify(range) : null,
                filter: filter ? JSON.stringify(filter) : null,
                cache,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getTireStats = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter } = queryKey[1]
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        if (filter) {
            filter.user = undefined
            filter.activeToken = undefined
        }

        INSTANCE({
            method: 'get',
            url: '/tire-vehicule-links/tires',
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
            params: {
                sort: sort ? JSON.stringify(sort) : null,
                range: range ? JSON.stringify(range) : null,
                filter: filter ? JSON.stringify(filter) : null,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getVehiculeStats = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter } = queryKey[1]
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        if (filter) {
            filter.user = undefined
            filter.activeToken = undefined
        }

        INSTANCE({
            method: 'get',
            url: '/client-vehicule-links/amount',
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
            params: {
                sort: sort ? JSON.stringify(sort) : null,
                range: range ? JSON.stringify(range) : null,
                filter: filter ? JSON.stringify(filter) : null,
            },
        })
            .then(resolve)
            .catch(reject)
    })

//     GET /users//impersonate/:id (uniquement pour les admins)
// result: {
//     user: {...},
//     token: xxxxx
// }

export const getUserHistory = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, ...rest } = queryKey[1]
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        if (filter) {
            filter.user = undefined
            filter.activeToken = undefined
        }
        if (rest.activeToken) {
            rest.activeToken = undefined
        }

        INSTANCELIST({
            method: 'get',
            url: 'user-histories',
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
            params: {
                sort: sort ? JSON.stringify(sort) : null,
                range: range ? JSON.stringify(range) : null,
                filter: filter ? JSON.stringify(filter) : null,
                ...rest,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getAdminHistory = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, ...rest } = queryKey[1]
        const token = localStorage.getItem('token')

        if (filter) {
            filter.user = undefined
            filter.activeToken = undefined
        }
        if (rest.activeToken) {
            rest.activeToken = undefined
        }

        INSTANCELIST({
            method: 'get',
            url: 'user-histories/admin',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: sort ? JSON.stringify(sort) : null,
                range: range ? JSON.stringify(range) : null,
                filter: filter ? JSON.stringify(filter) : null,
                ...rest,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const exportData = (id, name) => {
    const token = localStorage.getItem('token')
    const impersonatedToken = localStorage.getItem('impersonatedToken')

    let filename = `${name}.xlsx`
    let xmlHttpRequest = new XMLHttpRequest()
    xmlHttpRequest.onreadystatechange = function () {
        var a
        if (xmlHttpRequest.readyState === 4 && xmlHttpRequest.status === 200) {
            a = document.createElement('a')
            a.href = window.URL.createObjectURL(xmlHttpRequest.response)
            a.download = filename
            a.style.display = 'none'
            document.body.appendChild(a)
            a.click()
        }
    }
    xmlHttpRequest.open(
        'GET',
        `${process.env.REACT_APP_API_URL}/payment-headers/consumption/export${
            id ? `?codecli=${id}` : ''
        }`
    )
    xmlHttpRequest.setRequestHeader('Content-Type', 'application/json')
    xmlHttpRequest.setRequestHeader(
        'Authorization',
        `Bearer ${impersonatedToken || token}`
    )
    xmlHttpRequest.responseType = 'blob'
    xmlHttpRequest.send(JSON.stringify())
}
