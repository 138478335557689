import { React } from 'react'
import cn from 'classnames'
import './stats-card.scss'

const StatsCard = ({
    className,
    color,
    order,
    decrease,
    increase,
    number,
    size,
    title,
    type,
    onClick,
    loading,
}) => {
    return (
        <div
            onClick={onClick}
            className={cn(
                'stats-card',
                { 'cursor-pointer': !!onClick },
                { [`${className}`]: !!className },
                { [`stats-card-${color}`]: !!color },
                { [`stats-card-${size}`]: !!size },
                { [`stats-card-${type}`]: !!type },
                { [`stats-card-order-${order}`]: !!order }
            )}
        >
            <div className="stats-card-item">
                <div className="stats-card-title">{title}</div>
                <div className="stats-card-number">
                    {loading && <div className="icon-loading button-loading" />}
                    {number}
                    {decrease ? (
                        <span className="stats-card-diff stats-card-decrease">
                            (-{decrease}%)
                        </span>
                    ) : null}
                    {increase ? (
                        <span className="stats-card-diff stats-card-increase">
                            (+{increase}%)
                        </span>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default StatsCard
