import { INSTANCE, INSTANCELIST } from './API'

export const getByAccount = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter } = queryKey[1]
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        if (filter) {
            filter.user = undefined
            filter.activeToken = undefined
        }

        INSTANCELIST({
            method: 'get',
            // url: '/agencies/dashboard',
            url: '/vehicules/mine',
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
            params: {
                sort: sort ? JSON.stringify(sort) : null,
                range: range ? JSON.stringify(range) : null,
                filter: filter ? JSON.stringify(filter) : null,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getByImmat = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter } = queryKey[1]
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        if (filter) {
            filter.user = undefined
            filter.activeToken = undefined
        }

        INSTANCELIST({
            method: 'get',
            // url: '/client-vehicule-links/amount',
            url: '/vehicules/mine',
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
            params: {
                sort: sort ? JSON.stringify(sort) : null,
                range: range ? JSON.stringify(range) : null,
                filter: filter ? JSON.stringify(filter) : null,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getVehiculeDetail = (id) =>
    new Promise((resolve, reject) => {
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        INSTANCE({
            method: 'get',
            // url: '/agencies/dashboard',
            url: '/vehicules/' + id,
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getInfoCodeTypes = () =>
    new Promise((resolve, reject) => {
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        INSTANCELIST({
            method: 'get',
            url: '/info-code-types',
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const createVehicule = (data) =>
    new Promise((resolve, reject) => {
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        INSTANCE({
            method: 'post',
            url: `/vehicules/create`,
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
            data,
        })
            .then(resolve)
            .catch(reject)
    })

export const updateVehicule = (data, id) =>
    new Promise((resolve, reject) => {
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        INSTANCE({
            method: 'put',
            url: `/vehicules/${id}`,
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
            data,
        })
            .then(resolve)
            .catch(reject)
    })

export const getVehiculeHisto = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { sort, range, filter, id } = queryKey[1]
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        if (filter) {
            filter.user = undefined
            filter.activeToken = undefined
        }

        INSTANCELIST({
            method: 'get',
            url: `/vehicules/${id}/history`,
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
            params: {
                sort: sort ? JSON.stringify(sort) : null,
                range: range ? JSON.stringify(range) : null,
                filter: filter ? JSON.stringify(filter) : null,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const downloadFile2 = () =>
    new Promise((resolve, reject) => {
        const token = localStorage.getItem('token')

        INSTANCE({
            method: 'get',
            url: '/storage/template/immatriculation_import_template.xlsx',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'blob',
        })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                )
                const link = document.createElement('a')
                link.href = url
                link.setAttribute(
                    'download',
                    'immatriculation_import_template.xlsx'
                )
                document.body.appendChild(link)
                link.click()
                resolve()
            })
            .catch(reject)
    })

export const testCanImport = () =>
    new Promise((resolve, reject) => {
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        INSTANCE({
            method: 'get',
            url: '/vehicules/import-available',
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const downloadFile = () => {
    let filename = 'immatriculation_import_template.xlsx'
    let xmlHttpRequest = new XMLHttpRequest()
    xmlHttpRequest.onreadystatechange = function () {
        var a
        if (xmlHttpRequest.readyState === 4 && xmlHttpRequest.status === 200) {
            a = document.createElement('a')
            a.href = window.URL.createObjectURL(xmlHttpRequest.response)
            a.download = filename
            a.style.display = 'none'
            document.body.appendChild(a)
            a.click()
        }
    }
    xmlHttpRequest.open(
        'GET',
        `${process.env.REACT_APP_API_URL}/storage/template/${filename}`
    )
    xmlHttpRequest.setRequestHeader('Content-Type', 'application/json')
    xmlHttpRequest.responseType = 'blob'
    xmlHttpRequest.send(JSON.stringify())
}

// export const importFile = () => {
//     // POST /vehicules/import
// }

export const importFile = (data) =>
    new Promise((resolve, reject) => {
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        INSTANCE({
            method: 'post',
            timeout: Infinity,
            url: `/vehicules/import`,
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
            data,
        })
            .then(resolve)
            .catch(reject)
    })

export const transferAccount = (cpt, lines) =>
    new Promise((resolve, reject) => {
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        INSTANCE({
            method: 'post',
            url: `/vehicules/transfer`,
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
            data: { IMMAT: lines, CODECLI: cpt },
        })
            .then(resolve)
            .catch(reject)
    })

// const deleteVehicules = (id) =>
//     new Promise((resolve, reject) => {
//         const token = localStorage.getItem('token')
//         const impersonatedToken = localStorage.getItem('impersonatedToken')

//         INSTANCE({
//             method: 'delete',
//             url: `/vehicules/${id}`,
//             headers: {
//                 Authorization: `Bearer ${impersonatedToken || token}`,
//             },
//         })
//             .then(resolve)
//             .catch(reject)
//     })

// export const deleteMultiple = (listUser) =>
//     new Promise(async (resolve, reject) => {
//         for (const user of listUser) {
//             await deleteVehicules(user).then().catch(reject)
//         }

//         resolve()
//     })

export const deleteMultiple = (listUser) =>
    new Promise((resolve, reject) => {
        const token = localStorage.getItem('token')
        const impersonatedToken = localStorage.getItem('impersonatedToken')

        INSTANCE({
            method: 'post',
            url: `/vehicules/remove`,
            headers: {
                Authorization: `Bearer ${impersonatedToken || token}`,
            },
            data: { IMMAT: listUser },
        })
            .then(resolve)
            .catch(reject)
    })

export const downloadVehicleFile = (CODECLI) => {
    const token = localStorage.getItem('token')
    const impersonatedToken = localStorage.getItem('impersonatedToken')

    let filename = 'immatriculations_export.xlsx'
    let xmlHttpRequest = new XMLHttpRequest()
    xmlHttpRequest.onreadystatechange = function () {
        var a
        if (xmlHttpRequest.readyState === 4 && xmlHttpRequest.status === 200) {
            a = document.createElement('a')
            a.href = window.URL.createObjectURL(xmlHttpRequest.response)
            a.download = filename
            a.style.display = 'none'
            document.body.appendChild(a)
            a.click()
        }
    }
    xmlHttpRequest.open(
        'GET',
        `${process.env.REACT_APP_API_URL}/vehicules/export${
            CODECLI ? `?CODECLI=${CODECLI}` : ''
        }`
    )
    xmlHttpRequest.setRequestHeader('Content-Type', 'application/json')
    xmlHttpRequest.setRequestHeader(
        'Authorization',
        `Bearer ${impersonatedToken || token}`
    )

    xmlHttpRequest.responseType = 'blob'
    xmlHttpRequest.send(JSON.stringify())
}
