import { React } from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'
import './userInfo.scss'
import { useAuth } from '../../hooks/useAuth'

const UserInfo = ({ vertical, photoUpload }) => {
    const { user, impersonatedUser, signOut } = useAuth()

    const roles = {
        'super-admin': 'Administrateur',
        admin: 'Administrateur',
        manager: 'Commercial',
        group: 'Compte',
        agency: 'Agence',
    }

    return (
        <div
            className={cn('user-info', {
                'user-info-vertical': !!vertical,
            })}
        >
            {/* <div className="user-info-logo">
                <div
                    className="user-info-logo-image"
                    style={{ backgroundImage: `url(${logo})` }}
                />
            </div> */}

            {/* {photoUpload ? (
                <div className="user-info-avatar">
                    <div
                        className="user-info-avatar-image"
                        style={{ backgroundImage: `url(${avatarDefault})` }}
                    >
                        <div className="user-info-file">
                            <InputFile icon="camera" />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="user-info-avatar">
                    <div className="user-info-avatar">
                        <div
                            className="user-info-avatar-image"
                            style={{ backgroundImage: `url(${avatarDefault})` }}
                        />
                    </div>
                </div>
            )} */}
            <div
                style={{
                    gap: 24,
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
            >
                <NavLink to="/account" className="user-info-wrap" exact>
                    <div className="user-info-title">
                        <span className="user-info-title-link" exact>
                            {`${user?.firstName} ${user?.lastName}`}
                        </span>
                        {/* <button className="user-info-logout">
                        <span className="icon-power"></span>
                        <span className="user-info-logout-text">
                            Déconnexion
                        </span>
                    </button> */}
                    </div>
                    <div className="user-info-subtitle">
                        {roles[user?.role]}
                    </div>
                    {impersonatedUser && (
                        <div className="user-info-subtitle">{`en tant que ${
                            impersonatedUser.firstName || ''
                        } ${impersonatedUser.lastName || ''}${
                            impersonatedUser.role === 'group'
                                ? ` (${impersonatedUser.Raicpt} Groupe)`
                                : impersonatedUser.role === 'agency'
                                ? ` (${impersonatedUser.Raiage} Agence)`
                                : impersonatedUser.role === 'manager'
                                ? ` (${impersonatedUser.Matric} Manager)`
                                : impersonatedUser.role === 'admin' ||
                                  impersonatedUser.role === 'super-admin'
                                ? ` (Admin)`
                                : ` (${impersonatedUser.role})`
                        }`}</div>
                    )}
                </NavLink>

                <div
                    style={{
                        height: 34,
                        width: 34,
                        border: '1px solid rgba(255, 255, 255, 0.5)',
                        borderRadius: 4,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={signOut}
                >
                    <img src="/logout.svg" alt="" />
                </div>
            </div>
        </div>
    )
}

export default UserInfo
